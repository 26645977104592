import { type Event, type Property } from '@kalos/kalos-rpc';

import { type DeletedEventsFilterForm } from '../../modules/ComponentsLibrary/DeletedServiceCallsReport';
import { type applyFilters } from '../../modules/Transactions/components/TransactionAdmin';
import { type InternalDocumentClientService, type PerDiemClientService } from '../../tools/helpers';
import {
  type EmployeeItemCategoryFilter,
  type EmployeeItemFilter,
  type EmployeeItemOptionFilter,
} from './employeeItems';
import { type EventDetailsFilters, type JobAdminFilter } from './eventDetails';
import { type ApiKeyFilter } from './useApiKeyClientServiceQuery';
import { type ClassCodeFilters } from './useClassCodeQuery';
import { type ContractFrequencyFilter } from './useContractFrequencyQuery';
import { type ContractFilter } from './useContractsQuery';
import { type CreditCardFilter } from './useCreditCardsQuery';
import { type DocumentFilter } from './useDocumentsQuery';
import { type EmployeeFunctionFilter } from './useEmployeeFunction';
import { type EventQuotesFilter } from './useEventQuotes';
import { type EventFilter, type MostLikelyJobRequestFilter } from './useEventsQuery';
import { type FileTagsFilters } from './useFileTagsQuery';
import { type GroupFilter } from './useGroupsQuery';
import { type InternalLinkFilter } from './useInternalLinkQuery';
import { type InvoiceFilter } from './useInvoiceQuery';
import { type JobSubTypesFilter } from './useJobSubTypesQuery';
import { type LodgingRequestAssignmentFilter, type LodgingRequestFilter } from './useLodging';
import { type MemoCommentFilter, type MemoCommentLogFilter, type MemoFilter } from './useMemoQuery';
import { type NewHireFilter, type NewHireOptionFilter } from './useNewHireQuery';
import { type OCRFilter } from './useOCR';
import { type PendingBillingFilter } from './usePendingBillingQuery';
import { type PerDiemFilter } from './usePerDiemQuery';
import {
  type InspectionFilter,
  type PermitFilter,
  type PermitGroupFilter,
  type PermitStatusFilter,
  type PermitTradeFilter,
} from './usePermits';
import { type ProjectEventFilter } from './useProjectEventsQuery';
import { type PropertyFilter } from './usePropertiesQuery';
import { type QuoteLineFilters } from './useQuoteLineQuery';
import { type MaintenanceFilter, type ReadingFilter } from './useReadingQuery';
import {
  type ReimbursementDocumentFilter,
  type ReimbursementFilter,
} from './useReimbursementQuery';
import { type EmployeeKPIQueryFilter, type ReportFilter } from './useReportQuery';
import { type ServiceFilter } from './useServiceItemQuery';
import {
  type SpiffOptionFilter,
  type SpiffTypeFilter,
  type TaskFilter,
} from './useTaskClientServiceQuery';
import { type TimeOffFilter } from './useTimeOffQuery';
import { type TimesheetDepartmentFilters } from './useTimesheetDepartmentQuery';
import { type TimeSheetFilters, type TimesheetLineFilter } from './useTimesheetQuery';
import { type TransactionAccountFilters } from './useTransactionAccountsQuery';
import { type TransactionActivityFilter } from './useTransactionActivityQuery';
import { type TransactionDocumentFilter } from './useTransactionDocumentQuery';
import { type TransactionLineItemFilter } from './useTransactionLineItem';
import { type TransactionQueryFilters } from './useTransactionQuery';
import { type TransactionStatusFilters } from './useTransactionsStatusQuery';
import { type useTripDistanceQueryArgs } from './useTripDistanceQuery';
import { type TripFilter } from './useTripts';
import { type UserGroupLinkFilter } from './useUserGroupLinksQuery';
import { type UserAttributesFilter, type UserFilters } from './useUserQuery';
import {
  type VehicleAssetFilter,
  type VehicleFilter,
  type VehicleLogFilter,
  type VehiclePhotoFilter,
  type VehicleTrailerFilter,
} from './useVehicles';
import { type VendorQueryFilters } from './useVendorQuery';
import { sortObjectKeys, trimEmptyValues } from './utils';

export const queryKeys = {
  lodgingStatuses: {
    root: 'lodgingStatuses',
    all: 'lodgingStatuses-all',
  },
  s3: {
    root: 's3',
    file: 's3-file',
  },
  lodgingOverlap: {
    root: 'lodgingOverlap',
  },
  lodgingRequests: {
    root: 'lodgingRequests',
    list: 'lodgingRequestsList',
    getHash: (filter: LodgingRequestFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  lodgingAssignments: {
    root: 'lodgingAssignments',
    list: 'lodgingAssignmentsList',
    getHash: (filter: LodgingRequestAssignmentFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  permitGroups: {
    root: 'permitGroups',
    list: 'permitGroupsList',
    getHash: (filter: PermitGroupFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  permits: {
    root: 'permits',
    list: 'permitsList',
    getHash: (filter: PermitFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  permitStatuses: {
    root: 'permitStatuses',
    list: 'permitStatusesList',
    getHash: (filter: PermitStatusFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  permitTrade: {
    root: 'permitTrade',
    list: 'permitTradeList',
    getHash: (filter: PermitTradeFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  inspections: {
    root: 'inspections',
    list: 'inspectionsList',
    getHash: (filter: InspectionFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  inspectionStatus: {
    root: 'inspectionStatus',
    list: 'inspectionStatusList',
  },
  properties: {
    root: 'properties',
    list: 'propertiesList',
    getHash: (filter: PropertyFilter) => JSON.stringify(sortObjectKeys(filter)),
    getRequestHash: (request: Property) => JSON.stringify(sortObjectKeys({ ...request })),
  },
  tripDistance: {
    root: 'tripDistance',
    getTripDistanceHash: (request: useTripDistanceQueryArgs) =>
      `${request.originAddress}-${request.destinationAddress}`,
  },
  employeeItems: {
    root: 'employeeItems',
    list: 'employeeItemsList',
    getHash: (filter: EmployeeItemFilter) => JSON.stringify(sortObjectKeys(filter)),
    employeeItemsOptions: 'employeeItemsOptions',
    employeeItemsOptionsList: 'employeeItemsOptionsList',
    getEmployeeItemOptionsHash: (filter: EmployeeItemOptionFilter) =>
      JSON.stringify(sortObjectKeys(filter)),
  },
  employeeItemCategories: {
    root: 'employeeItemCategories',
    list: 'employeeItemCategoriesList',
    getHash: (filter: EmployeeItemCategoryFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  employeeFunctions: {
    root: 'employeeFunctions',
    list: 'employeeFunctionsList',
    getHasH: (filter: EmployeeFunctionFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  eventDetails: {
    root: 'eventDetails',
    jobAdmins: 'jobAdmins',
    jobAdminsList: 'jobAdminsList',
    getJobAdminHash: (filter: JobAdminFilter) => JSON.stringify(sortObjectKeys(filter)),
    getEventDetailsHash: (filters: EventDetailsFilters) => {
      return JSON.stringify(sortObjectKeys(filters));
    },
  },
  jobHits: {
    root: 'jobHits',
    hash: (filter: MostLikelyJobRequestFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  events: {
    root: 'events',
    getEventHash: (filter: EventFilter) => JSON.stringify(sortObjectKeys({ ...filter })),
    list: 'eventsList',
    deletedEvents: 'deletedEvents',
    getDeletedEventsHash: ({ filter, page }: { filter: DeletedEventsFilterForm; page: number }) =>
      `${JSON.stringify(sortObjectKeys(filter))}-${page}`,
    getEventsListHash: (request: Event) => {
      const reqProperties = trimEmptyValues(request);
      return JSON.stringify(sortObjectKeys(reqProperties));
    },
    getEventsBatchGetHash: (request: EventFilter) => {
      return JSON.stringify(sortObjectKeys(request));
    },
    getEventFiltersHash: (filters: EventFilter) => {
      return JSON.stringify(sortObjectKeys(filters));
    },
    details: 'eventDetails',
  },
  eventQuotes: {
    root: 'eventQuotes',
    list: 'eventQuotesList',
    getHash: (filter: EventQuotesFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  invoice: {
    root: 'invoice',
    getHash: (filter: InvoiceFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  memoList: {
    root: 'memoList',
    getMemoListHash: (filters: MemoFilter) => JSON.stringify(sortObjectKeys({ ...filters })),
  },
  memo: {
    root: 'memo',
    getMemoHash: (filters: MemoFilter) => JSON.stringify(sortObjectKeys({ ...filters })),
  },
  memoCommentList: {
    root: 'memoCommentList',
    getMemoCommentListHash: (filters: MemoCommentFilter) =>
      JSON.stringify(sortObjectKeys({ ...filters })),
  },
  memoCommentLogList: {
    root: 'memoCommentLogList',
    getMemoCommentLogListHash: (filters: MemoCommentLogFilter) =>
      JSON.stringify(sortObjectKeys({ ...filters })),
  },
  memoStatusList: {
    root: 'memoStatusList',
    getMemoStatusListHash: (filters: MemoFilter) => JSON.stringify(sortObjectKeys({ ...filters })),
  },
  memoClassificationList: {
    root: 'memoClassificationList',
    getMemoClassificationListHash: (filters: MemoFilter) =>
      JSON.stringify(sortObjectKeys({ ...filters })),
  },
  reminder: {
    root: 'reminder',
  },
  usersManager: {
    root: 'usersManager',
    getUserManagerHash: (req: UserFilters) => JSON.stringify(sortObjectKeys({ ...req })),
  },
  user: {
    root: 'user',
    list: 'userList',
    byPermissionGroup: 'userByPermissionGroup',
    getHash: (filters: UserFilters) => JSON.stringify(sortObjectKeys(filters)),
    userAttributes: 'userAttributes',
    getUserAttributesHash: (filters: UserAttributesFilter) =>
      JSON.stringify(sortObjectKeys(filters)),
  },
  userGroupLinks: {
    root: 'userGroupLinks',
    list: 'userGroupLinksList',
    getHash: (filters: UserGroupLinkFilter) => JSON.stringify(sortObjectKeys(filters)),
  },
  group: {
    root: 'group',
    list: 'groupList',
    getHash: (filters: GroupFilter) => JSON.stringify(sortObjectKeys(filters)),
  },
  userBatchGet: {
    root: 'userBatchGet',
    getUserBatchGetHash: (filters: UserFilters) => JSON.stringify(sortObjectKeys(filters)),
  },
  technicians: {
    root: 'technicians',
  },
  contracts: {
    root: 'contracts',
    list: 'contractsList',
    getHash: (filter: ContractFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  contractsFrequency: {
    root: 'contractsFrequency',
    list: 'contractsFrequencyList',
    getHash: (filter: ContractFrequencyFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  jobSubTypes: {
    root: 'jobSubTypes',
    getJobSubTypesHash: (filters: JobSubTypesFilter) => JSON.stringify(sortObjectKeys(filters)),
  },
  jobTypes: {
    root: 'jobTypes',
  },
  pto: {
    root: 'pto',
    inquiry: 'inquiry',
    list: 'list',
  },
  pendingBilling: {
    root: 'pendingBilling',
    list: 'list',
    getHash: (filter: PendingBillingFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  metrics: {
    root: 'metrics',
    billable: 'billable',
    callbacks: 'callbacks',
    avgTicket: 'avgTicket',
    revenue: 'revenue',
  },
  OCR: {
    root: 'OCR',
    list: 'OCR-list',
    getHash: (filter: OCRFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  transaction: {
    root: 'transaction',
    list: 'transaction-list',
    getTransactionHash: (req: TransactionQueryFilters) => JSON.stringify(sortObjectKeys(req)),
    getTransactionAdminHash: (
      data: Pick<Parameters<typeof applyFilters>[0], 'filters' | 'isSU' | 'departmentIDList'> & {
        page: number;
      },
    ) => JSON.stringify(sortObjectKeys(data)),
  },
  transactionLineItem: {
    root: 'transaction-line-item',
    list: 'transaction-line-item-list',
    getHash: (filter: TransactionLineItemFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  transactionDocument: {
    root: 'transaction-document',
    data: 'transaction-document-data',
    list: 'transaction-document-list',
    getFilterHash: (req: TransactionDocumentFilter) => JSON.stringify(sortObjectKeys(req)),
  },
  transactionGet: {
    root: 'transaction-get',
    getFiltersHash: (req: TransactionQueryFilters) => JSON.stringify(sortObjectKeys(req)),
  },
  transactionActivity: {
    root: 'transaction-activity',
    list: 'transaction-activity-list',
    getFiltersHash: (req: TransactionActivityFilter) => JSON.stringify(sortObjectKeys(req)),
  },
  transactionAccountBatch: {
    root: 'transaction-accountBatchGet',
  },
  transactionAccountGet: {
    root: 'transaction-account-get',
    getFiltersHash: (req: TransactionAccountFilters) => JSON.stringify(sortObjectKeys(req)),
  },
  transactionAccountBatchGet: {
    root: 'transaction-account-batch-get',
  },
  quoteLine: {
    root: 'quoteLine',
    list: 'quoteLine-list',
    getQuoteLineHash: (filters: QuoteLineFilters) => JSON.stringify(sortObjectKeys(filters)),
  },
  vendor: {
    root: 'vendor',
    list: 'vendor-list',
    getVendorHash: (params: VendorQueryFilters) => JSON.stringify(sortObjectKeys(params)),
  },
  timesheetDepartments: {
    root: 'timesheetDepartments',
    list: 'timesheetDepartments-list',
    getFiltersHash: (params: TimesheetDepartmentFilters) => JSON.stringify(sortObjectKeys(params)),
  },
  timesheetDepartment: {
    root: 'timesheetDepartment',
    getFiltersHash: (params: TimesheetDepartmentFilters) => JSON.stringify(sortObjectKeys(params)),
  },
  serviceCalendar: {
    root: 'service-calendar',
    employees: 'service-calendar-employees',
    getServiceCalendarHash: (req: Event) => JSON.stringify(sortObjectKeys({ ...req })),
  },
  transactionStatus: {
    root: 'transactionStatus',
    list: 'transactionStatus-list',
    getHash: (filters: TransactionStatusFilters) => JSON.stringify(sortObjectKeys(filters)),
  },
  classcode: {
    root: 'classCode',
    list: 'classCode-list',
    getClassCodeHash: (filters: ClassCodeFilters) => JSON.stringify(sortObjectKeys(filters)),
  },
  timeOff: {
    root: 'timeOff',
    batchGet: 'timeOff-batchGet',
    requestTypes: 'timeOff-requestTypes',
    getTimeOffReqHash: (req: TimeOffFilter) => JSON.stringify(sortObjectKeys(req)),
  },
  timeOffRequestTypes: {
    root: 'timeOffRequestTypes',
    list: 'timeOffRequestTypes-list',
  },
  transactionAccount: {
    root: 'transactionAccount',
    getTransactionAccountHash: (filters: TransactionAccountFilters) =>
      JSON.stringify(sortObjectKeys(filters)),
  },
  fileTags: {
    root: 'fileTags',
    getFileTagsHash: (filter: FileTagsFilters) => JSON.stringify(sortObjectKeys(filter)),
  },
  trip: {
    root: 'trip',
    list: 'tripList',
    getHash: (filter: TripFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  perDiem: {
    root: 'perDiem',
    list: 'perDiemList',
    getPerDiemHash: (filter: PerDiemFilter) => JSON.stringify(sortObjectKeys(filter)),
    byDepartmentIdsAndDateStartedQuery: {
      key: 'perDiemByDepartmentIdsAndDateStartedQuery',
      hash: (
        ...args: Parameters<typeof PerDiemClientService.loadPerDiemByDepartmentIdsAndDateStarted>
      ) => JSON.stringify(sortObjectKeys({ depIds: args[0], dateStarted: args[1] })),
    },
    loadGovPerDiemQuery: {
      key: 'loadGovPerDiemQuery',
      hash: (...args: Parameters<typeof PerDiemClientService.loadGovPerDiem>) =>
        JSON.stringify(sortObjectKeys({ zipCodes: args[0], year: args[1], month: args[2] })),
    },
  },
  govPerDiem: {
    root: 'govPerDiem',
    key: 'loadGovPerDiemV2Query',
    hash: (args: Parameters<typeof PerDiemClientService.loadGovPerDiemV2>['0']) =>
      JSON.stringify(sortObjectKeys(args)),
  },
  apiKey: {
    root: 'apiKey',
    getApiKeyHash: (apiKey: ApiKeyFilter) => JSON.stringify(sortObjectKeys(apiKey)),
  },
  timeSheet: {
    root: 'timeSheet',
    list: 'timeSheetList',
    manager: 'timeSheetManager',
    payroll: 'timeSheetPayroll',
    getHash: (filter: TimesheetLineFilter) => JSON.stringify(sortObjectKeys(filter)),
    timeSheetCheck: 'timeSheetCheck',
    getTimeSheetHash: (filter: TimeSheetFilters) => JSON.stringify(sortObjectKeys(filter)),
  },
  reimbursement: {
    root: 'reimbursement',
    list: 'reimbursementList',
    getReimbursementHash: (filter: ReimbursementFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  reimbursementDocument: {
    root: 'reimbursementDocument',
    list: 'reimbursementDocumentList',
    getHash: (filter: ReimbursementDocumentFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  task: {
    root: 'task',
    list: 'taskList',
    getHash: (filter: TaskFilter) => JSON.stringify(sortObjectKeys(filter)),
    toolBudget: 'task-budget',
    appliedSpiffs: 'task-appliedSpiffs',
    spiffOptions: 'task-spiffOptions',
  },
  spiffTypes: {
    root: 'spiffTypes',
    list: 'spiffTypesList',
    getHash: (filter: SpiffTypeFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  spiffOptions: {
    root: 'spiffOptions',
    list: 'spiffOptionsList',
    getHash: (filter: SpiffOptionFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  documents: {
    root: 'documents',
    list: 'documentsList',
    getHash: (filter: DocumentFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  internalDocuments: {
    root: 'internalDocuments',
    list: 'internalDocumentsList',
    getListHash: (
      filters: Parameters<typeof InternalDocumentClientService.loadInternalDocuments>[0],
    ) => JSON.stringify(sortObjectKeys(filters)),
  },
  creditCards: {
    root: 'creditCards',
    list: 'creditCardsList',
    getHash: (filter: CreditCardFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  geolocation: {
    root: 'geolocation',
    geocode: 'geocode',
    distanceMatrix: 'distanceMatrix',
    byAddress: 'loadGeoLocationByAddress',
    tripDistance: 'getTripDistance',
  },
  readings: {
    root: 'readings',
    list: 'readingsList',
    getHash: (filter: ReadingFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  serviceItem: {
    root: 'serviceItemClientService',
    list: 'serviceItemClientServiceList',
    getHash: (filter: ServiceFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  serviceItemImage: {
    root: 'serviceItemClientService',
    list: 'serviceItemClientServiceList',
    getHash: (filter: ServiceFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  maintenanceQuestion: {
    root: 'maintenanceQuestion',
    list: 'maintenanceQuestionList',
    getHash: (filter: MaintenanceFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  projectsEvents: {
    root: 'projectEvents',
    list: 'projectEventsList',
    getHash: (filter: ProjectEventFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  internalLink: {
    root: 'internalLink',
    list: 'InternalLinkList',
    getHash: (filter: InternalLinkFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  reportList: {
    root: 'report',
    list: 'reportList',
    getHash: (filter: ReportFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  newHire: {
    root: 'newHire',
    list: 'newHireList',
    optionsList: 'newHireOptionsList',
    getHash: (filter: NewHireFilter | NewHireOptionFilter) =>
      JSON.stringify(sortObjectKeys(filter)),
  },
  projectEstimate: {
    project: 'project',
    projectList: 'projectList',
    estimate: 'estimate',
    estimateList: 'estimateList',
    changeOrder: 'changeOrder',
    changeOrderList: 'changeOrderList',
    expended: 'expended',
    expendedList: 'expendedList',
    relatedDepartmentBudgets: 'relatedDepartmentBudgets',
    getProjectHash: (jobNumber: number) => `project-${jobNumber}`,
    getEstimateHash: (jobNumber: number) => `estimate-${jobNumber}`,
    getChangeOrderHash: (jobNumber: number) => `changeOrder-${jobNumber}`,
    getExpendedHash: (jobNumber: number) => `expended-${jobNumber}`,
  },
  invoicePayment: {
    root: 'invoicePayment',
    list: 'invoicePaymentList',
    getHash: (filter: InvoiceFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  vehicles: {
    root: 'vehicles',
    list: 'vehiclesList',
    getHash: (filter: VehicleFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  vehiclePhoto: {
    root: 'vehiclePhoto',
    list: 'vehiclePhotoList',
    getHash: (filter: VehiclePhotoFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  vehicleTrailer: {
    root: 'vehicleTrailer',
    list: 'vehicleTrailerList',
    getHash: (filter: VehicleTrailerFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  vehicleAsset: {
    root: 'vehicleAsset',
    list: 'vehicleAssetList',
    getHash: (filter: VehicleAssetFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  vehicleLog: {
    root: 'vehicleLogs',
    list: 'vehicleLogsList',
    getHash: (filter: VehicleLogFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
  aiAssistant: {
    root: 'aiAssistant',
    list: 'aiAssistantList',
  },
  employeeWeeklyReport: {
    root: 'employeeWeeklyReport',
  },
  kpiReport: {
    root: 'kpiReport',
    getHash: (filter: EmployeeKPIQueryFilter) => JSON.stringify(sortObjectKeys(filter)),
  },
} as const;
