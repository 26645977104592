/*

  Design Specification: converting this to React from Coldfusion
  https://app.kalosflorida.com/index.cfm?action=admin:contracts.edit&contract_id=3365&p=1

*/

import { type Contract, Invoice } from '@kalos/kalos-rpc';
import { type FC, useCallback, useEffect, useReducer } from 'react';

import { InvoiceClientService } from '../../../tools/helpers';
import { Form, type Schema } from '../Form';
import { ACTIONS, reducer } from './reducer';

interface props {
  contract: Contract;
  userId: number;
  onClose: () => any;
  onSave: (savedInvoice: Invoice) => any;
  onLoad?: (loadedInvoice: Invoice) => any;
  onChange?: (currentData: Invoice) => any;
}

export const EditInvoiceData: FC<props> = ({
  onClose,
  onSave,
  onLoad,
  onChange,
  contract,
  userId,
}) => {
  const INVOICE_SCHEMA: Schema<Invoice> = [
    [
      {
        label: 'Terms',
        name: 'terms',
        multiline: true,
      },
    ],
    [
      {
        label: 'Service Performed (1)',
        name: 'servicesperformedrow1',
        multiline: true,
        // minRows: 3,
        // maxRows: 3,
      },
      {
        label: 'Total Cost (1)',
        name: 'totalamountrow1',
        type: 'number',
      },
    ],
    [
      {
        label: 'Service Performed (2)',
        name: 'servicesperformedrow2',
        multiline: true,
        // minRows: 3,
        // maxRows: 3,
      },
      {
        label: 'Total Cost (2)',
        name: 'totalamountrow2',
        type: 'number',
      },
    ],
    [
      {
        label: 'Service Performed (3)',
        name: 'servicesperformedrow3',
        multiline: true,
        // minRows: 3,
        // maxRows: 3,
      },
      {
        label: 'Total Cost (3)',
        name: 'totalamountrow3',
        type: 'number',
      },
    ],
    [
      {
        label: 'Service Performed (4)',
        name: 'servicesperformedrow4',
        multiline: true,
        // minRows: 3,
        // maxRows: 3,
      },
      {
        label: 'Total Cost (4)',
        name: 'totalamountrow4',
        type: 'number',
      },
    ],
  ];
  const [state, dispatch] = useReducer(reducer, {
    isLoaded: false,
    invoiceData: Invoice.create(),
  });

  const getInvoice = useCallback(async () => {
    try {
      let res = Invoice.create();
      if (contract.id > 0) {
        res =
          (await InvoiceClientService.Get(
            Invoice.create({ contractId: contract.id, userId: userId }),
          )) || Invoice.create();
      }
      dispatch({ type: ACTIONS.SET_INVOICE_DATA, data: res! });
      return res;
    } catch (err) {
      console.error(`An error occurred while getting an invoice: ${err}`);
    }
  }, [contract, userId]);

  const load = useCallback(async () => {
    const invoice = await getInvoice();
    if (invoice && onLoad) onLoad(invoice);
    dispatch({ type: ACTIONS.SET_LOADED, data: true });
  }, [getInvoice, onLoad]);

  const cleanup = useCallback(() => {}, []);
  const calculateTotal = useCallback((...amounts: number[]) => {
    return amounts.reduce((previous, current) => previous + current);
  }, []);

  useEffect(() => {
    if (!state.isLoaded) load();

    return () => {
      cleanup();
    };
  }, [load, cleanup, state.isLoaded]);

  return (
    <>
      <Form<Invoice>
        key={state.isLoaded.toString()}
        data={state.invoiceData}
        schema={INVOICE_SCHEMA}
        onClose={() => onClose()}
        onSave={(saved) => onSave(saved)}
        onChange={(currentData) => {
          const currentDataSafe = { ...currentData };
          const total = calculateTotal(
            ...[
              Number(currentDataSafe.totalamountrow1),
              Number(currentDataSafe.totalamountrow2),
              Number(currentDataSafe.totalamountrow3),
              Number(currentDataSafe.totalamountrow4),
            ],
          );

          currentDataSafe.totalamounttotal = total.toString();
          dispatch({ type: ACTIONS.SET_INVOICE_DATA, data: currentDataSafe });
          if (onChange) onChange(currentDataSafe);
        }}
      />
      <Form<Invoice>
        key={
          state.invoiceData.totalamountrow1.toString() +
          state.invoiceData.totalamountrow2.toString() +
          state.invoiceData.totalamountrow3.toString() +
          state.invoiceData.totalamountrow4.toString()
        }
        data={state.invoiceData}
        schema={[
          [
            {
              label: 'Total Cost (Overall)',
              name: 'totalamounttotal',
              type: 'number',
              disabled: true,
            },
          ],
        ]}
        onClose={() => onClose()}
        onSave={(saved) => onSave(saved)}
        onChange={(currentData) => {
          const currentDataState = state.invoiceData;
          const currentDataSafe = { ...currentData };
          currentDataState.totalamounttotal = currentDataSafe.totalamounttotal;
          dispatch({ type: ACTIONS.SET_INVOICE_DATA, data: currentDataState });
          if (onChange) onChange(currentDataState);
        }}
      />
    </>
  );
};
