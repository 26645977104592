import { Transaction } from '@kalos/kalos-rpc';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  useAlert,
  useConfirm,
} from '@kalos/ui';
import {
  CheckCircledIcon,
  CheckIcon,
  CopyIcon,
  CrossCircledIcon,
  ExclamationTriangleIcon,
  FileMinusIcon,
  LightningBoltIcon,
  Pencil1Icon,
  StackIcon,
  TextAlignRightIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { useCallback, useMemo, useRef, useState } from 'react';
import { z } from 'zod';

import { Prompt } from '../../../components/Prompt';
import { useAuth } from '../../../context/AuthContext';
import {
  LineItemValidator,
  useTransactionLineItemsStore,
} from '../../../context/TransactionLIneItems';
import { queryKeys } from '../../../hooks/react-query/constants';
import { useUploadTransactionDocumentMutation } from '../../../hooks/react-query/useTransactionDocumentQuery';
import { useTransactionUpdateMutation } from '../../../hooks/react-query/useTransactionQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { timestamp } from '../../../tools/helpers';
import { PDFMakerV2 } from '../../ComponentsLibrary/PDFMaker';
import {
  TransactionGalleryAction,
  useMakeTransactionActivityLog,
  useMakeUpdateTransactionStatus,
  ViewTransactionLogsActions,
} from '../../ComponentsLibrary/TransactionTable/components/TransactionActions';
import {
  TransactionLineItemsTable,
  wasTransactionLineItemUpdated,
} from '../../TransactionUser/components/TransactionLineItems/TransactionLineItems';
import { TRANSACTION_STATUSES_MAP } from './TransactionAdmin';

const CopyAction = ({ transaction }: { transaction: Transaction }) => {
  const [, copy] = useCopyToClipboard();
  const [isSuccess, setIsSuccess] = useState(false);

  const onCopyClick = async () => {
    const timeString = parseISO(transaction.timestamp.split(' ').join('T')).toLocaleDateString();
    const owner = transaction.ownerName;
    const costCenter = transaction.costCenter
      ? `${transaction.costCenter.description} (${transaction.costCenter.id})`
      : '';
    const department = transaction.department ? transaction.department.description : '';
    const job = transaction.jobId ? `#${transaction.jobId}` : '';
    const artificialId = transaction.artificialId;
    const amount = `$${transaction.amount}`;
    const vendor = transaction.vendor;
    const stateTax = transaction.stateTaxApplied ? 'Applied' : 'Not Applied';
    try {
      const isSuccess = await copy(
        [
          timeString,
          owner,
          costCenter,
          department,
          job,
          artificialId,
          amount,
          vendor,
          stateTax,
        ].join(','),
      );
      if (isSuccess) {
        setIsSuccess(isSuccess);
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      }
    } catch {
      console.error('Failed to copy transaction data');
    }
  };

  return (
    <Tooltip>
      <TooltipContent>Copy data to clipboard</TooltipContent>
      <TooltipTrigger asChild>
        <Button onClick={onCopyClick} disabled={isSuccess} variant="outline" size="icon">
          <span className="sr-only">
            {isSuccess ? 'Successfully copied transaction data' : 'Copy data to clipboard'}
          </span>
          {isSuccess ? (
            <CheckIcon className="size-4 text-green-500" />
          ) : (
            <CopyIcon className="size-4" />
          )}
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};

const useAuditTransaction = () => {
  const transactionUpdateMutation = useTransactionUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();
  const queryClient = useQueryClient();

  const audit = useCallback(
    async ({ transactionId }: { transactionId: number }) => {
      await transactionUpdateMutation.mutateAsync(
        Transaction.create({
          id: transactionId,
          isAudited: true,
          fieldMask: ['IsAudited'],
        }),
      );
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.root] }),
        makeLog('Transaction audited', transactionId),
      ]);
    },
    [makeLog, queryClient, transactionUpdateMutation],
  );

  return useMemo(() => ({ audit }), [audit]);
};

const UploadFileAction = ({
  transaction,
  accountingAdmin,
  deleteAfterUpload = false,
}: {
  transaction: Transaction;
  accountingAdmin: boolean;
  deleteAfterUpload?: boolean;
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadDocumentMutation = useUploadTransactionDocumentMutation();
  const openFileInput = () => {
    fileInputRef.current && fileInputRef.current.click();
  };
  const [loading, setLoading] = useState(false);

  const { makeUpdateStatus } = useMakeUpdateTransactionStatus({
    shouldDeleteAfterUpdate: deleteAfterUpload,
  });
  const { audit } = useAuditTransaction();
  const { makeLog } = useMakeTransactionActivityLog();
  const alert = useAlert();

  const handleFile = () => {
    const fr = new FileReader();
    fr.onload = async () => {
      setLoading(true);

      try {
        if (!fileInputRef.current) {
          alert('Failed to upload file');
          return;
        }

        const u8 = new Uint8Array(fr.result as ArrayBuffer);
        await uploadDocumentMutation.mutateAsync({
          fileData: u8,
          fileName: Array.from(fileInputRef.current.files || [])?.at(0)?.name ?? '',
          transactionId: transaction.id,
        });
      } catch (err) {
        alert('File could not be uploaded');
        console.log(err);
      }
      if (accountingAdmin) {
        await makeUpdateStatus({
          id: transaction.id,
          description: 'accepted',
          statusID: TRANSACTION_STATUSES_MAP.accept,
        });
        await audit({ transactionId: transaction.id });
      }
      setLoading(false);
      alert(accountingAdmin ? 'Upload complete and transaction was accepted' : 'Upload complete!');
    };

    if (fileInputRef.current && fileInputRef.current.files?.length) {
      fr.readAsArrayBuffer(fileInputRef.current.files[0]);
      makeLog(`New File Added, File: ${fileInputRef.current.files[0].name}`, transaction.id);
    }
  };

  return (
    <Tooltip>
      <input type="file" className="hidden" onChange={handleFile} ref={fileInputRef} />
      <TooltipContent>Upload File</TooltipContent>
      <TooltipTrigger asChild>
        <Button
          size="icon"
          variant="outline"
          isLoading={loading}
          disabled={loading}
          onClick={openFileInput}
        >
          <UploadIcon />
          <span className="sr-only">Upload file</span>
        </Button>
      </TooltipTrigger>
    </Tooltip>
  );
};
export const editNotesSchema = z
  .string()
  .min(1, 'Notes is required')
  .max(500, 'Notes is too long! Please make it less then 500 characters');

const UpdateJobNotesAction = ({ transaction }: { transaction: Transaction }) => {
  const updateTransactionMutation = useTransactionUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();
  const [isOpened, setIsOpened] = useState(false);

  const onSubmit = useCallback(
    async (data: { prompt: string }) => {
      if (transaction.notes === data.prompt) {
        setIsOpened(false);
        return;
      }
      try {
        await updateTransactionMutation.mutateAsync(
          Transaction.create({
            id: transaction.id,
            notes: data.prompt,
            fieldMask: ['Notes'],
          }),
        );
        setIsOpened(false);
        await makeLog('Job notes updated', transaction.id);
      } catch (err) {
        console.error('Failed to update job notes', err);
      }
    },
    [makeLog, transaction.id, transaction.notes, updateTransactionMutation],
  );

  return (
    <Tooltip>
      <TooltipContent>Edit Notes</TooltipContent>
      <Prompt
        onSubmit={onSubmit}
        onOpenChange={setIsOpened}
        open={isOpened}
        contentClassName="w-96"
        label="Update Txn Notes"
        defaultValue={transaction.notes}
        isLoading={updateTransactionMutation.isPending}
        title="Edit notes"
        promptSchema={editNotesSchema}
        trigger={
          <TooltipTrigger asChild>
            <Button size="icon" variant="outline">
              <Pencil1Icon />
              <span className="sr-only">Edit Notes</span>
            </Button>
          </TooltipTrigger>
        }
      />
    </Tooltip>
  );
};

export const reasonSchema = z
  .string()
  .min(1, 'Reason is required')
  .max(500, 'Reason is too long! Please make it less than 500 characters');
const MarkAsDuplicateAction = ({ transaction }: { transaction: Transaction }) => {
  const updateTransactionMutation = useTransactionUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();
  const [isOpened, setIsOpened] = useState(false);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: { prompt: string }) => {
      try {
        setLoading(true);
        await updateTransactionMutation.mutateAsync(
          Transaction.create({
            id: transaction.id,
            isActive: 0,
            fieldMask: ['IsActive'],
          }),
        );
        await queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.root] });
        await makeLog(`Transaction marked as duplicate, Reason: ${data.prompt}`, transaction.id);
        setIsOpened(false);
      } catch {
        console.error('Failed to mark as duplicate');
      } finally {
        setLoading(false);
      }
    },
    [makeLog, queryClient, transaction.id, updateTransactionMutation],
  );

  return (
    <Tooltip>
      <TooltipContent>Mark as Duplicate</TooltipContent>
      <Prompt
        onSubmit={handleSubmit}
        onOpenChange={setIsOpened}
        promptSchema={reasonSchema}
        isLoading={loading}
        open={isOpened}
        title="Mark as Duplicate"
        label="Reason for marking as duplicate"
        trigger={
          <TooltipTrigger asChild>
            <Button isLoading={loading} disabled={loading} size="icon" variant="outline">
              <ExclamationTriangleIcon />
              <span className="sr-only">Mark as Duplicate</span>
            </Button>
          </TooltipTrigger>
        }
      />
    </Tooltip>
  );
};

const CollapsibleText = ({ text }: { text: string }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div>
      <span className="text-gray-500">{isCollapsed ? text.slice(0, 100) + '...' : text}</span>
      <Button variant="link" className="ml-1" onClick={toggle}>
        {isCollapsed ? 'Show more' : 'Show less'}
      </Button>
    </div>
  );
};

const ViewNotesAction = ({ transaction }: { transaction: Transaction }) => {
  return (
    <Tooltip>
      <TooltipContent>View Notes</TooltipContent>
      <Popover>
        <PopoverContent className="max-h-[40vh] overflow-auto">{transaction.notes}</PopoverContent>
        <TooltipTrigger asChild>
          <PopoverTrigger asChild>
            <Button size="icon" variant="outline">
              <TextAlignRightIcon />
              <span className="sr-only">View Notes</span>
            </Button>
          </PopoverTrigger>
        </TooltipTrigger>
      </Popover>
    </Tooltip>
  );
};

const GenerateMissingPdfAction = ({
  transaction,
  accountingAdmin,
}: {
  transaction: Transaction;
  accountingAdmin?: boolean;
}) => {
  const [isMissingReceiptPdfMakerOpen, setIsMissingReceiptPdfMakerOpen] = useState(false);
  const uploadTransactionDocumentMutation = useUploadTransactionDocumentMutation();
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  const updateMutation = useTransactionUpdateMutation();
  const { makeLog } = useMakeTransactionActivityLog();

  const onPDFGenerate = useCallback(
    async (fileData: Uint8Array) => {
      console.log('We are toggleing loading');
      try {
        setLoading(true);
        await uploadTransactionDocumentMutation.mutateAsync({
          transactionId: transaction.id,
          fileData,
          fileName: `${timestamp()}-generated.pdf`,
        });
        if (accountingAdmin) {
          await updateMutation.mutateAsync(
            Transaction.create({
              id: transaction.id,
              description: 'accepted',
              statusId: TRANSACTION_STATUSES_MAP.accept,
              isAudited: true,
              isRecorded: false,
              fieldMask: ['StatusID', 'IsAudited', 'IsRecorded'],
            }),
          );
          await makeLog('accepted', transaction.id);
        }
      } catch {
        alert('Failed to upload PDF');
      } finally {
        setLoading(false);
      }
      alert(
        accountingAdmin
          ? 'A PDF has been generated and transaction was accepted'
          : 'A PDF has been generated and uploaded for you, it is recommended you review this document before proceeding',
      );
      setIsMissingReceiptPdfMakerOpen(false);
    },
    [
      alert,
      accountingAdmin,
      uploadTransactionDocumentMutation,
      transaction.id,
      updateMutation,
      makeLog,
    ],
  );

  const pDFData = useMemo(
    () => ({
      amount: transaction.amount,
      dateStr: transaction.timestamp,
      jobNumber: transaction.jobId > 0 ? transaction.jobId.toString() : undefined,
      name: transaction.ownerName,
    }),
    [transaction.amount, transaction.jobId, transaction.ownerName, transaction.timestamp],
  );

  return (
    <Dialog open={isMissingReceiptPdfMakerOpen} onOpenChange={setIsMissingReceiptPdfMakerOpen}>
      <Tooltip>
        <TooltipContent>Generate Missing PDF</TooltipContent>
        <DialogTrigger asChild>
          <TooltipTrigger asChild>
            <Button size="icon" isLoading={loading} disabled={loading} variant="outline">
              <FileMinusIcon />
              <span className="sr-only">Generate Missing PDF</span>{' '}
            </Button>
          </TooltipTrigger>
        </DialogTrigger>
      </Tooltip>

      <DialogContent showClose={false} className="w-80 p-0">
        <PDFMakerV2
          title="Missing"
          onClose={() => {
            setIsMissingReceiptPdfMakerOpen(false);
          }}
          onCreate={onPDFGenerate}
          isLoading={loading}
          vendor={transaction.vendor}
          pdfType="Missing Receipt"
          pdfData={pDFData}
        />
      </DialogContent>
    </Dialog>
  );
};

const ReactivateTransactionAction = ({ transaction }: { transaction: Transaction }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { makeLog } = useMakeTransactionActivityLog();
  const updateTransactionMutation = useTransactionUpdateMutation();

  const onSubmit = useCallback(
    async (data: { prompt: string }) => {
      try {
        await updateTransactionMutation.mutateAsync(
          Transaction.create({
            id: transaction.id,
            isActive: 1,
            fieldMask: ['IsActive'],
          }),
        );
        setIsOpened(false);
        await makeLog(`Transaction reactivated. Reason:${data.prompt}`, transaction.id);
      } catch {
        console.error('Failed to reactivate transaction');
      }
    },
    [makeLog, transaction.id, updateTransactionMutation],
  );

  return (
    <Tooltip>
      <TooltipContent>Reactivate</TooltipContent>
      <Prompt
        onSubmit={onSubmit}
        onOpenChange={setIsOpened}
        isLoading={updateTransactionMutation.isPending}
        promptSchema={reasonSchema}
        open={isOpened}
        title="Reactivate Transaction"
        label="Reason for reactivation"
        trigger={
          <TooltipTrigger asChild>
            <Button size="icon" variant="outline">
              <LightningBoltIcon />
              <span className="sr-only">Reactivate</span>
            </Button>
          </TooltipTrigger>
        }
      />
    </Tooltip>
  );
};

const useAcceptTransaction = () => {
  const transactionUpdateMutation = useTransactionUpdateMutation();
  const queryClient = useQueryClient();
  const { makeLog } = useMakeTransactionActivityLog();

  const accept = useCallback(
    async ({ transactionId }: { transactionId: number }) => {
      try {
        await transactionUpdateMutation.mutateAsync(
          Transaction.create({
            id: transactionId,
            statusId: TRANSACTION_STATUSES_MAP.accept,
            fieldMask: ['StatusID'],
          }),
        );
        queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.root] });
        await makeLog('Transaction accepted', transactionId);
      } catch (err) {
        alert('Failed to accept transaction');
        console.error('Failed to accept transaction', err);
      }
    },
    [makeLog, queryClient, transactionUpdateMutation],
  );

  return useMemo(() => ({ accept }), [accept]);
};

const SubmitAction = ({
  transaction,
  acceptOverride,
  // setLoading,
}: {
  transaction: Transaction;
  acceptOverride: boolean;
  setLoading?: (value: boolean) => void;
}) => {
  const { accept } = useAcceptTransaction();
  const { makeLog } = useMakeTransactionActivityLog();
  const updateTransactionMutation = useTransactionUpdateMutation();
  const currentUserId = useAuth().user.id;
  const [isLoading, setIsLoading] = useState(false);

  const areTransactionLineItemsValid = useMemo(() => {
    return transaction.transactionLineItems.length > 0
      ? LineItemValidator.isValidForSave({
          lineItems: transaction.transactionLineItems,
          parentTransaction: transaction,
        })
      : true;
  }, [transaction]);

  const record = useCallback(
    async ({ transactionId }: { transactionId: number }) => {
      try {
        await updateTransactionMutation.mutateAsync(
          Transaction.create({
            id: transactionId,
            isRecorded: true,
            fieldMask: ['IsRecorded'],
          }),
        );
        await makeLog('Transaction recorded', transactionId);
      } catch (err) {
        alert('Failed to record transaction');
        console.error('Failed to record transaction', err);
      }
    },
    [makeLog, updateTransactionMutation],
  );

  const updateStatus = useCallback(async () => {
    const ok = confirm(
      `Are you sure you want to mark this transaction as ${
        acceptOverride ? 'entered' : 'accepted'
      }?`,
    );
    if (ok) {
      // if (setLoading)
      setIsLoading(true);

      const fn = acceptOverride ? record : accept;
      await fn({ transactionId: transaction.id });
      // if (setLoading)
      setIsLoading(false);
    }
  }, [accept, acceptOverride, record, transaction.id]);

  const allowToApprove =
    acceptOverride || (currentUserId !== transaction.ownerId && !acceptOverride);
  const isSubmitDisabled = !areTransactionLineItemsValid || !allowToApprove;

  const message = useMemo(() => {
    if (!allowToApprove) {
      return 'You cannot approve your own transaction';
    }

    if (!areTransactionLineItemsValid) {
      return 'Please correct the line items before submit';
    }

    return acceptOverride ? 'Mark as entered' : 'Mark as accepted';
  }, [acceptOverride, allowToApprove, areTransactionLineItemsValid]);

  return (
    <Tooltip>
      <TooltipContent>{message}</TooltipContent>
      <TooltipTrigger asChild>
        <span>
          <Button
            disabled={isSubmitDisabled}
            isLoading={isLoading}
            onClick={updateStatus}
            size="icon"
            variant="outline"
          >
            <CheckCircledIcon />
            <span className="sr-only">Submit</span>
          </Button>
        </span>
      </TooltipTrigger>
    </Tooltip>
  );
};

export const RejectTransactionAction = ({ transaction }: { transaction: Transaction }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { makeLog } = useMakeTransactionActivityLog();
  const updateTransactionMutation = useTransactionUpdateMutation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(
    async (data: { prompt: string }) => {
      try {
        setIsLoading(true);
        await updateTransactionMutation.mutateAsync(
          Transaction.create({
            id: transaction.id,
            statusId: TRANSACTION_STATUSES_MAP.reject,
            notes: '',
            fieldMask: ['StatusID', 'Notes'],
          }),
        );
        await Promise.allSettled([
          queryClient.invalidateQueries({ queryKey: [queryKeys.transaction.root] }),
          makeLog(`rejected ${data.prompt}`, transaction.id),
        ]);
        setIsOpened(false);
      } catch {
        console.error('Failed to reject transaction');
      } finally {
        setIsLoading(false);
      }
    },
    [makeLog, queryClient, transaction.id, updateTransactionMutation],
  );

  return (
    <Tooltip>
      <TooltipContent>Reject</TooltipContent>
      <Prompt
        onSubmit={onSubmit}
        onOpenChange={setIsOpened}
        promptSchema={reasonSchema}
        isLoading={isLoading}
        open={isOpened}
        title="Reject Transaction"
        label="Reason for rejection"
        trigger={
          <TooltipTrigger asChild>
            <Button isLoading={isLoading} size="icon" variant="outline">
              <CrossCircledIcon />
              <span className="sr-only">Reject</span>
            </Button>
          </TooltipTrigger>
        }
      />
    </Tooltip>
  );
};

const VERY_COOL_USER_ID = 1734;
const SOME_COOL_USER_IDS = [9928, 9646, 103323, 9809, VERY_COOL_USER_ID, 103285];

const AuditAction = ({ transaction }: { transaction: Transaction }) => {
  const { audit } = useAuditTransaction();
  const [isLoading, setIsLoading] = useState(false);
  const { accept } = useAcceptTransaction();
  const currentUserId = useAuth().user.id;

  const confirm = useConfirm();

  const auditTxn = useCallback(async () => {
    try {
      setIsLoading(true);
      await audit({ transactionId: transaction.id });
    } finally {
      setIsLoading(false);
    }
  }, [audit, transaction.id]);

  const acceptTxn = useCallback(async () => {
    try {
      const ok = await confirm(`Are you sure you want to mark this transaction as accepted?`);
      if (ok) {
        setIsLoading(true);
        await accept({ transactionId: transaction.id });
      }
    } finally {
      setIsLoading(false);
    }
  }, [accept, confirm, transaction.id]);

  const isButtonDisabled =
    (transaction.isAudited && currentUserId !== VERY_COOL_USER_ID) || isLoading;

  return (
    <Tooltip>
      <TooltipContent>
        {transaction.isAudited && currentUserId !== VERY_COOL_USER_ID
          ? 'This transaction has already been audited'
          : 'Mark as correct'}
      </TooltipContent>
      <TooltipTrigger asChild>
        <span>
          <Button
            variant="outline"
            size="icon"
            isLoading={isLoading}
            className={isButtonDisabled ? 'pointer-events-none' : ''}
            onClick={currentUserId === VERY_COOL_USER_ID ? acceptTxn : auditTxn}
            disabled={isButtonDisabled}
          >
            <CheckIcon />
          </Button>
        </span>
      </TooltipTrigger>
    </Tooltip>
  );
};

export const TransactionLineItemsAction = ({
  transaction,
  readonly,
}: { transaction: Transaction } & Pick<
  React.ComponentProps<typeof TransactionLineItemsTable>,
  'readonly'
>) => {
  const [isOpened, setIsOpened] = useState(false);
  const store = useTransactionLineItemsStore();

  const areLineItemsValid = useMemo(() => {
    return LineItemValidator.isValidForSave({
      lineItems: transaction.transactionLineItems,
      parentTransaction: transaction,
    });
  }, [transaction]);

  const onLineItemsDialogClose = (open: boolean) => {
    const originalLineItems = transaction.transactionLineItems;
    const lineItems = store.getState().lineItems;

    if (!open) {
      if (
        lineItems.some((li) => {
          return wasTransactionLineItemUpdated({ original: originalLineItems, updated: li });
        }) &&
        !confirm('Are you sure you want to cancel? You have unsaved changes!')
      ) {
        return;
      }
      const setEditing = store.getState().setIsEditing;
      const setLineItems = store.getState().setLineItems;

      setEditing(false);
      setLineItems(originalLineItems);
      setIsOpened(false);
    }

    setIsOpened(open);
  };

  return (
    <>
      <Tooltip>
        <TooltipContent>Line Items</TooltipContent>
        <TooltipTrigger asChild>
          <span>
            <Button
              onClick={() => setIsOpened(true)}
              variant={
                transaction.transactionLineItems.length
                  ? areLineItemsValid
                    ? 'success'
                    : 'destructive'
                  : 'outline'
              }
              className="flex items-center gap-0.5 px-0.5"
            >
              <StackIcon className="size-4" />
              <div className="rounded-full border p-0.5 text-xs shadow-md">
                {transaction.transactionLineItems.length}
              </div>
            </Button>
          </span>
        </TooltipTrigger>
      </Tooltip>
      <Dialog open={isOpened} onOpenChange={onLineItemsDialogClose}>
        <DialogContent className="w-screen max-w-none">
          <DialogTitle>Line Items</DialogTitle>
          <div className="h-[80vh] overflow-auto">
            <TransactionLineItemsTable
              readonly={readonly}
              onSave={() => {
                setIsOpened(false);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export const TransactionRowAdminActions = ({
  transaction,
  setLoading,
  statusFilter,
}: {
  transaction: Transaction;
  setLoading: (value: boolean) => void;
  statusFilter: number;
}) => {
  const currentUserId = useAuth().user.id;
  const userQuery = useUserQuery({
    filters: {
      id: currentUserId,
    },
    select(data) {
      return {
        ...data,
        isUserAccountingAdmin: !!data.permissionGroups.find(
          (item) => item.name === 'AccountingAdmin',
        ),
        acceptOverride: !!data.permissionGroups.find(
          (item) => item.name === 'AccountingAcceptOverride',
        ),
      };
    },
  });

  const galleryAction = (
    <TransactionGalleryAction
      subTitle={<CollapsibleText text={transaction.notes} />}
      canDelete={false}
      title="Transaction Photos"
      transactionId={transaction.id}
    />
  );

  const notesAction = <ViewNotesAction transaction={transaction} />;
  const transactionLogsAction = <ViewTransactionLogsActions transactionId={transaction.id} />;

  const shouldShowShowAuditAction = useMemo(() => {
    return SOME_COOL_USER_IDS.includes(currentUserId) || userQuery.data?.isUserAccountingAdmin;
  }, [currentUserId, userQuery.data]);

  const isAcceptedStatusInFilter = useMemo(() => {
    return [0, 3, 10, 11].includes(statusFilter);
  }, [statusFilter]);

  if (transaction.isActive)
    return (
      <>
        <CopyAction transaction={transaction} />
        <UploadFileAction
          transaction={transaction}
          accountingAdmin={!!userQuery.data?.isUserAccountingAdmin}
          deleteAfterUpload={!isAcceptedStatusInFilter}
        />
        <UpdateJobNotesAction transaction={transaction} />
        {userQuery.data?.acceptOverride && <MarkAsDuplicateAction transaction={transaction} />}
        {galleryAction}
        {transactionLogsAction}
        <GenerateMissingPdfAction
          transaction={transaction}
          accountingAdmin={userQuery.data?.isUserAccountingAdmin}
        />
        {notesAction}
        {shouldShowShowAuditAction && <AuditAction transaction={transaction} />}
        <SubmitAction
          transaction={transaction}
          acceptOverride={!!userQuery.data?.acceptOverride}
          setLoading={setLoading}
        />
        <RejectTransactionAction transaction={transaction} />

        <TransactionLineItemsAction transaction={transaction} />
      </>
    );

  if (!transaction.isActive && userQuery.data?.acceptOverride) {
    return (
      <>
        {notesAction}
        {transactionLogsAction}
        {galleryAction}
        <ReactivateTransactionAction transaction={transaction} />
      </>
    );
  }

  return null;
};
