import {
  Reimbursement,
  ReimbursementDocument,
  type ReimbursementDocumentList,
  ReimbursementList,
} from '@kalos/kalos-rpc';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ReimbursementClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ReimbursementFilter = EntityFilter<Reimbursement>;

export const useReimbursementListQuery = ({
  filter = {},
  enabled = true,
}: { filter?: ReimbursementFilter; enabled?: boolean } = {}) => {
  return useQuery({
    queryFn: () => {
      return ReimbursementClientService.BatchGet(Reimbursement.create(filter));
    },
    queryKey: [
      queryKeys.reimbursement.root,
      queryKeys.reimbursement.list,
      queryKeys.reimbursement.getReimbursementHash(filter),
    ],
    enabled,
  });
};

export const useReimbursementCreateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (newReimbursement: Reimbursement) => {
      return ReimbursementClientService.Create(newReimbursement);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.reimbursement.root],
      });
    },
  });
};

export const useReimbursementUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (ReimbursementToUpdate: Reimbursement) => {
      return ReimbursementClientService.Update(ReimbursementToUpdate);
    },

    async onSuccess(data, variables, context) {
      queryClient.setQueriesData<ReimbursementList>(
        {
          queryKey: [queryKeys.reimbursement.root, queryKeys.reimbursement.list],
        },
        (cache) => {
          if (!cache || !data || !cache.results.find((r) => r.id === data.id)) return;

          return ReimbursementList.create({
            results: cache.results.map((r) => (r.id === data.id ? data : r)),
            totalCount: cache.totalCount,
          });
        },
      );

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.reimbursement.root],
      });
    },
  });
};

export const useReimbursementDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (deleteReimbursement: Reimbursement) => {
      return ReimbursementClientService.Delete(deleteReimbursement);
    },
    onSuccess(_, variables, context) {
      queryClient.setQueriesData<ReimbursementList>(
        {
          queryKey: [queryKeys.reimbursement.root, queryKeys.reimbursement.list],
        },
        (cache) => {
          if (!cache || !cache.results.find((r) => r.id !== variables.id)) return;

          return ReimbursementList.create({
            results: cache.results.filter((r) => r.id !== variables.id),
            totalCount: cache.totalCount,
          });
        },
      );

      queryClient.invalidateQueries({
        queryKey: [queryKeys.reimbursement.root],
      });
    },
  });
};

type CreateReimbursementDocumentArguments = Parameters<
  typeof ReimbursementClientService.CreateDocument
>['0'];
type CreateReimbursementDocumentResponse = Awaited<
  ReturnType<typeof ReimbursementClientService.CreateDocument>
>;

export const useCreateReimbursementDocumentMutation = (
  opts?: Parameters<
    typeof useMutation<
      CreateReimbursementDocumentResponse,
      unknown,
      CreateReimbursementDocumentArguments,
      unknown
    >
  >['0'],
) => {
  return useMutation({
    mutationFn: (newReimbursementDocument: CreateReimbursementDocumentArguments) =>
      ReimbursementClientService.CreateDocument(newReimbursementDocument),
    ...opts,
  });
};

type DeleteReimbursementDocumentArguments = Parameters<
  typeof ReimbursementClientService.DeleteDocument
>['0'];
type DeleteReimbursementDocumentResponse = Awaited<
  ReturnType<typeof ReimbursementClientService.DeleteDocument>
>;

export const useDeleteReimbursementDocumentMutation = (
  opts?: Parameters<
    typeof useMutation<
      DeleteReimbursementDocumentResponse,
      unknown,
      DeleteReimbursementDocumentArguments,
      unknown
    >
  >['0'],
) => {
  return useMutation({
    mutationFn: (deleteReimbursementDocument: DeleteReimbursementDocumentArguments) =>
      ReimbursementClientService.DeleteDocument(deleteReimbursementDocument),
    ...opts,
  });
};
type UpdateReimbursementDocumentResponse = Awaited<
  ReturnType<typeof ReimbursementClientService.UpdateDocument>
>;
type UpdateReimbursementDocumentArgs = Parameters<
  typeof ReimbursementClientService.UpdateDocument
>['0'];
export const useReimbursementDocumentUpdateMutation = (
  opts?: Parameters<
    typeof useMutation<
      UpdateReimbursementDocumentResponse,
      unknown,
      UpdateReimbursementDocumentArgs,
      unknown
    >
  >['0'],
) => {
  return useMutation({
    mutationFn: (ReimbursementDocumentToUpdate: UpdateReimbursementDocumentArgs) => {
      if (ReimbursementDocumentToUpdate.id === 0)
        throw new Error(
          'Invalid reimbursement update request - existing Reimbursement must have an id',
        );
      if (ReimbursementDocumentToUpdate.fieldMask.length === 0)
        throw new Error('Reimbursement update: Field mask must be provided for update operations');
      return ReimbursementClientService.UpdateDocument(ReimbursementDocumentToUpdate);
    },
    ...opts,
  });
};

export type ReimbursementDocumentFilter = EntityFilter<ReimbursementDocument>;
export const useReimbursementDocumentBatchGetQuery = <TSelectData = ReimbursementDocumentList,>({
  enabled,
  filter = {},
  select,
}: {
  filter?: ReimbursementDocumentFilter;
  enabled?: boolean;
  select?: (data: ReimbursementDocumentList) => TSelectData;
}) => {
  return useQuery({
    queryFn: () => {
      return ReimbursementClientService.BatchGetDocument(ReimbursementDocument.create(filter));
    },
    queryKey: [
      queryKeys.reimbursementDocument.root,
      queryKeys.reimbursementDocument.list,
      queryKeys.reimbursementDocument.getHash(filter),
    ],
    enabled,
    select,
  });
};
