/*

  Design Specification: converting this to React from Coldfusion
  https://app.kalosflorida.com/index.cfm?action=admin:contracts.edit&contract_id=3365&p=1

*/
import {
  ActivityLog,
  Contract,
  Document,
  Event,
  EventList,
  Invoice,
  Property,
  SQSEmail,
  SQSEmailAndDocument,
} from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import AddCircleOutlineTwoTone from '@mui/icons-material/AddCircleOutlineTwoTone';
import EditSharp from '@mui/icons-material/EditSharp';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { addYears, format, isBefore, parseISO } from 'date-fns';
import { type FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';

import { useAuth } from '../../../context/AuthContext';
import { queryKeys } from '../../../hooks/react-query/constants';
import { useContractFrequencyBatchGetQuery } from '../../../hooks/react-query/useContractFrequencyQuery';
import { useContractQuery } from '../../../hooks/react-query/useContractsQuery';
import { useEventBatchGetQuery } from '../../../hooks/react-query/useEventsQuery';
import { useInvoiceQuery } from '../../../hooks/react-query/useInvoiceQuery';
import { usePropertiesBatchGetQuery } from '../../../hooks/react-query/usePropertiesQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import {
  ActivityLogClientService,
  ContractClientService,
  debounce,
  EventClientService,
  formatDate,
  InvoiceClientService,
  timestamp,
} from '../../../tools/helpers';
import {
  PAYMENT_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS,
} from '../../CustomerDetails/components/ContractInfo';
import { Loader } from '../../Loader/main';
import { Alert } from '../Alert';
import { Confirm } from '../Confirm';
import { EditInvoiceData } from '../EditInvoiceData';
import { type Schema } from '../Form';
import { Modal } from '../Modal';
import { PlainForm } from '../PlainForm';
import { SectionBar } from '../SectionBar';
import { ServiceCall } from '../ServiceCall';
import { ServiceRequest } from '../ServiceCall/requestIndex';
import {
  CANCEL_LABEL,
  CONFIRM_CLOSE_WITHOUT_SAVE_DIALOG,
  CONFIRM_SAVE_DIALOG,
  CONFIRM_SAVE_LABEL,
  ERROR_LABEL,
  INVOICE_SECTION_NAME,
  SAVE_LABEL,
  SERVICE_CALL_SUBTITLE,
  WARNING_LABEL,
} from './constants';
import { ACTIONS, type ContractErrors, reducer, type State } from './reducer';

export interface Output {
  contractData: Contract;
  propertiesSelected: Property[];
  invoiceData: Invoice;
}
interface props {
  userID: number;
  contractID: number;
  onSaveFinished?: (savedContract: Output) => any; // Used to Close Modal
  onClose?: () => any;
  onChange?: (currentData: Output) => any;
}

const initialState: State = {
  isLoaded: false,
  save: false,
  filteredProperties: [],
  propertiesSelected: [],
  isValidating: false,
  invoiceData: Invoice.create(),
  isSaving: false,
  error: undefined,
  fatalError: false,
  contractEvents: [],
  eventPage: 0,
  initiatedSchema: [],
  hasBeenChanged: false,
  isClosingWithoutSave: false,
  isAddingNewPM: false,
  selectedProperty: Property.create(),
  selectedEvent: Event.create(),
  createdEvent: Event.create(),
  isEditing: false,
  isDeleting: false,
  pendingSave: Contract.create(),
  sendInvoiceToCustomer: false,
  loadingEvents: false,
  sendInvoice: false,
  pendingSend: false,
  savingEvent: false,
  contractErrors: {},
};

const loadingContractEvents = Array.from({ length: 5 }, (_) => Event.create());

const checkContractErrors = (data: Contract, fields?: (keyof Contract)[]): ContractErrors => {
  const errors: ContractErrors = {};
  if (!fields || fields?.includes('dateEnded') || fields?.includes('dateStarted')) {
    if (isBefore(new Date(data.dateEnded), new Date(data.dateStarted))) {
      errors.dateStarted = 'Start Date cannot be earlier than End Date';
    }
  }
  if ((!fields || fields?.includes('paymentType')) && !data.paymentType) {
    errors.paymentType = 'This field is required';
  }
  if ((!fields || fields?.includes('departmentId')) && !data.departmentId) {
    errors.departmentId = 'This field is required';
  }
  if (!data.paymentStatus && fields?.includes('paymentStatus')) {
    errors.paymentStatus = 'This field is required';
  }
  if (data.notes.length > 255) {
    errors.notes = 'This field cannot exceed 255 characters.';
  }
  if (data.paymentTerms.length > 255) {
    errors.paymentTerms = 'This field cannot exceed 255 characters.';
  }
  return errors;
};

export const EditContractInfo: FC<props> = ({
  userID,
  contractID,
  onSaveFinished,
  onClose,
  onChange,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [deletedJob, setDeletedJob] = useState<number | undefined>();
  const currentUserId = useAuth().user.id;
  const currentUserQuery = useUserQuery({
    filters: {
      id: currentUserId,
    },
  });

  useEffect(() => {
    if (!contractID) {
      dispatch({ type: ACTIONS.SET_PROPERTIES_SELECTED, data: [] });
    }
  }, [contractID]);

  const contractFrequenciesQuery = useContractFrequencyBatchGetQuery({
    filter: {
      isActive: 1,
    },
  });

  const CONTRACT_SCHEMA: Schema<Contract> = useMemo(
    () => [
      [
        {
          label: 'Start Date',
          name: 'dateStarted',
          type: 'mui-date',
          required: true,
        },
        {
          label: 'End Date',
          name: 'dateEnded',
          type: 'mui-date',
          required: true,
        },
      ],
      [
        {
          label: 'Frequency',
          options:
            contractFrequenciesQuery.data?.results.map((item) => ({
              key: item.name + item.id,
              label: item.name,
              value: item.interval,
            })) || [],
          name: 'frequency',
          required: true,
        },
        {
          label: 'Group Billing',
          options: [
            { name: 'Group', value: 1 },
            { name: 'Site', value: 0 },
          ].map((item) => ({
            key: item.name + item.value,
            label: item.name,
            value: item.value,
          })),
          name: 'groupBilling',
          required: true,
        },
        {
          label: 'Payment Type',
          options: PAYMENT_TYPE_OPTIONS,
          name: 'paymentType',
          required: true,
        },
        {
          label: 'Payment Status',
          options: PAYMENT_STATUS_OPTIONS,
          name: 'paymentStatus',
          required: true,
        },
      ],
      [
        {
          label: 'Department',
          name: 'departmentId',
          type: 'department',
          required: true,
        },
        {},
      ],
      [
        {
          label: 'Service Start Time',
          name: 'timeStarted',
          type: 'mui-time',
          required: true,
        },
        {
          label: 'Service End Time',
          name: 'timeEnded',
          type: 'mui-time',
          required: true,
        },
        {},
      ],
      [
        {
          label: 'Notes',
          type: 'text',
          name: 'notes',
          multiline: true,
          minRows: 3,
          maxRows: 3,
        },
        {
          label: 'Payment Terms',
          type: 'text',
          multiline: true,
          name: 'paymentTerms',
          minRows: 3,
          maxRows: 3,
        },
      ],
    ],
    [contractFrequenciesQuery.data?.results],
  );

  const [isContractJobsSectionShown, setIsContractJobsSectionShown] = useState(false);
  const contractEventsQuery = useEventBatchGetQuery({
    filters: {
      contractId: contractID,
      isActive: 1,
      pageNumber: state.eventPage,
      notEquals: ['PropertyId'],
      orderBy: 'date_started',
      orderDir: 'desc',
      withoutLimit: false,
    },
    select(data) {
      const updated = structuredClone(data) || EventList.create();
      updated.results = [...(data?.results || []).filter((item) => item.id !== deletedJob)];
      if ((data?.results || []).length > updated.results.length) {
        updated.totalCount = updated.totalCount - 1;
      }
      return updated;
    },
    enabled: isContractJobsSectionShown && contractID !== 0,
  });
  const contractAllEventsQuery = useEventBatchGetQuery({
    filters: {
      contractId: contractID,
      isActive: 1,
      pageNumber: state.eventPage,
      notEquals: ['PropertyId'],
      orderBy: 'date_started',
      orderDir: 'desc',
      withoutLimit: true,
      onlyEvents: true,
    },
    select(data) {
      const updated = structuredClone(data) || EventList.create();
      updated.results = [...(data?.results || []).filter((item) => item.id !== deletedJob)];
      if ((data?.results || []).length > updated.results.length) {
        updated.totalCount = updated.totalCount - 1;
      }
      return updated;
    },
    enabled: contractID !== 0,
  });

  const customerQuery = useUserQuery({
    filters: {
      id: userID,
    },
  });

  const createVerboseContractLogging = useCallback(
    (entry: Contract) => {
      if (entry.fieldMask.length > 0) {
        let logMessage = `User ${currentUserQuery.data?.firstname ?? ''} ${
          currentUserQuery.data?.lastname ?? ''
        } changed the following \n`;
        for (let i = 0; i < entry.fieldMask.length; i++) {
          // @ts-ignore
          const messageString = `Changed ${entry.fieldMask[i]} to ${entry[entry.fieldMask[i]]} \n`;
          logMessage += messageString;
        }
        return logMessage;
      } else {
        return '';
      }
    },
    [currentUserQuery.data?.firstname, currentUserQuery.data?.lastname],
  );

  const handleDelete = async () => {
    const req = Event.create();
    req.id = state.selectedEvent.id;
    await EventClientService.Delete(req).then((res) => {
      if (res?.id) {
        setDeletedJob(res.id);
      }
      queryClient.refetchQueries({
        queryKey: [queryKeys.events.root, queryKeys.events.list],
      });
    });
    dispatch({
      type: ACTIONS.SET_DELETING,
      data: { isDeleting: false, selectedEvent: Event.create() },
    });
  };

  const contractQueryEnabled = useMemo(() => contractID !== 0, [contractID]);
  const contractQuery = useContractQuery({
    filter: {
      id: contractID,
    },
    enabled: contractQueryEnabled,
  });

  const invoiceQuery = useInvoiceQuery({
    filter: {
      contractId: contractID,
    },
    enabled: contractQueryEnabled,
  });

  const invoiceId = useMemo(() => {
    if (invoiceQuery.isSuccess) {
      if (invoiceQuery.data?.id) {
        return invoiceQuery.data.id;
      }
      return -1;
    }
    return -1;
  }, [invoiceQuery.data?.id, invoiceQuery.isSuccess]);

  const contractData = useMemo(() => {
    if (contractID === 0 || !contractQuery.data) {
      const data = Contract.create({
        userId: userID,
        dateCreated: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        dateStarted: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        dateEnded: format(addYears(new Date(), 1), 'yyyy-MM-dd HH:mm:ss'),
        timeStarted: '0001-01-01 00:00',
        timeEnded: '0001-01-01 00:00',
        frequency: 182,
        isActive: 1,
        paymentStatus: 'Pending',
      });
      dispatch({ type: ACTIONS.SET_PENDING_SAVE, data });
      return data;
    } else {
      dispatch({ type: ACTIONS.SET_PENDING_SAVE, data: contractQuery.data ?? Contract.create() });
      return contractQuery.data ?? Contract.create();
    }
  }, [contractID, contractQuery.data, userID]);

  const propertiesQuery = usePropertiesBatchGetQuery({
    filter: {
      userId: userID,
      isActive: 1,
      withoutLimit: true,
    },
    enabled: !!contractData,
  });

  useEffect(() => {
    if (propertiesQuery.isSuccess && contractData) {
      if (contractID !== 0) {
        const propertiesSorted: Property[] = [];

        const propertiesSelected: Property[] = [];
        contractData.properties.split(',').forEach(async (result) => {
          const filtered = propertiesQuery.data.results.filter((property) => {
            return property.id === Number(result);
          });
          filtered.forEach((f) => {
            propertiesSelected.push(f);
            propertiesSorted.push(f);
          });
        });
        propertiesQuery.data.results.forEach((prop) => {
          if (!propertiesSorted.includes(prop)) propertiesSorted.push(prop);
        });
        dispatch({
          type: ACTIONS.SET_PROPERTIES_SELECTED,
          data: propertiesSelected,
        });
        dispatch({
          type: ACTIONS.SET_FILTERED_PROPERTIES,
          data: propertiesSorted,
        });
      } else {
        dispatch({
          type: ACTIONS.SET_FILTERED_PROPERTIES,
          data: propertiesQuery.data.results,
        });
      }

      dispatch({ type: ACTIONS.SET_LOADED, data: true });
    }
  }, [contractData, contractID, propertiesQuery.data?.results, propertiesQuery.isSuccess]);

  const saveContract = useCallback(async () => {
    let update = false;
    let contractRes: Contract | undefined;
    let error: string = '';
    let reqContract = Contract.create();
    reqContract = state.pendingSave;
    if (contractID) {
      reqContract.id = contractID;
      update = true;
    }
    try {
      if (state.propertiesSelected !== undefined) {
        reqContract.properties = state.propertiesSelected
          .map((property) => `${property.id}`)
          .join(',');
      } else {
        reqContract.properties = '';
      }
      if (!reqContract.fieldMask.includes('Properties')) {
        reqContract.fieldMask.push('Properties');
      }

      if (update) {
        contractRes = await ContractClientService.Update(reqContract);

        if (!contractRes) throw new Error('Contract failed to update.');

        const activityMessage = createVerboseContractLogging(reqContract);
        if (activityMessage != '') {
          const activity = ActivityLog.create();
          activity.contractId = reqContract.id;
          activity.activityName = activityMessage;
          activity.activityDate = timestamp();
          activity.userId = currentUserId;
          await ActivityLogClientService.Create(activity);
        }
      } else {
        reqContract.dateCreated = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

        contractRes = await ContractClientService.Create(reqContract);

        if (!contractRes) throw new Error('Contract failed to create.');

        const contractNumber =
          String(contractRes.id).length < 5 ? `0${contractRes.id}` : `${contractRes.id}`;
        contractRes.number = `C${format(new Date(), 'yy')}-${contractNumber}`;
        await ContractClientService.Update(contractRes);
      }
    } catch (err) {
      console.error(`An error occurred while upserting a contract: ${err}`);
      error = `${err}`;
      try {
        // const devlog = Devlog.create();
        // devlog.userId = userID;
        // devlog.timestamp = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
        // devlog.isError = 1;
        // devlog.description = `Failed to upsert a contract with error: ${err}`;
        // await DevlogClientService.Create(devlog);
      } catch (err) {
        console.error(`Failed to upload a devlog: ${err}`);
      }
    }

    if (!contractRes || contractRes.id === 0 || error) {
      dispatch({
        type: ACTIONS.SET_ERROR,
        data: `An error occurred while trying to save the contract: \n${error}.\n The contract or the created jobs have failed to save properly.  The invoice will not be saved. Please contact the webtech team if this issue persists.`,
      });
      return;
    }
    return contractRes;
  }, [
    state.pendingSave,
    state.propertiesSelected,
    contractID,
    createVerboseContractLogging,
    currentUserId,
  ]);

  const saveInvoice = useCallback(
    async (contract: Contract) => {
      if (!customerQuery.isSuccess) {
        console.error("Customer query isn't successful on invoice save, aborting.");
        return;
      }
      let _invoiceId = invoiceId;
      try {
        const reqInvoice = Invoice.clone(state.invoiceData);

        reqInvoice.contractId = contract.id;
        reqInvoice.logPaymentType = contract.paymentType;
        reqInvoice.logPaymentStatus = contract.paymentStatus;
        reqInvoice.servicesperformedrow1 = state.invoiceData.servicesperformedrow1;
        reqInvoice.totalamountrow1 = state.invoiceData.totalamountrow1;
        reqInvoice.servicesperformedrow2 = state.invoiceData.servicesperformedrow2;
        reqInvoice.totalamountrow2 = state.invoiceData.totalamountrow2;
        reqInvoice.servicesperformedrow3 = state.invoiceData.servicesperformedrow3;
        reqInvoice.totalamountrow3 = state.invoiceData.totalamountrow3;
        reqInvoice.servicesperformedrow4 = state.invoiceData.servicesperformedrow4;
        reqInvoice.totalamountrow4 = state.invoiceData.totalamountrow4;
        reqInvoice.totalamounttotal = `${state.invoiceData.totalamounttotal}`;
        reqInvoice.terms = state.invoiceData.terms;
        reqInvoice.userId = userID;

        if (_invoiceId === -1) {
          reqInvoice.startDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
          const invoice = await InvoiceClientService.Create(reqInvoice);

          if (!invoice) throw new Error('Invoice failed to create.');

          _invoiceId = invoice.id;
        } else {
          reqInvoice.id = _invoiceId;
          const currentInv = await InvoiceClientService.Get(Invoice.create({ id: _invoiceId }));

          if (!currentInv) throw new Error('Invoice failed to load.');

          if (contract.paymentType !== currentInv.logPaymentType) {
            reqInvoice.fieldMask.push('LogPaymentType');
          }
          if (contract.paymentStatus !== currentInv.logPaymentStatus) {
            reqInvoice.fieldMask.push('LogPaymentStatus');
          }
          if (state.invoiceData.terms !== currentInv.terms) {
            reqInvoice.fieldMask.push('Terms');
          }
          const containsTotalamount = reqInvoice.fieldMask.some((field) =>
            field.includes('Totalamount'),
          );
          if (containsTotalamount) {
            reqInvoice.fieldMask.push('Totalamounttotal');
          }

          // if (state.servicesperformedrow1 !== currentInv.servicesperformedrow1) {
          //   reqInvoice.fieldMask.push('Servicesperformedrow1');
          // }
          // if (state.totalamountrow1 !== currentInv.totalamountrow1) {
          //   reqInvoice.fieldMask.push('Totalamountrow1');
          //   totalChanged = true;
          // }
          // if (state.servicesperformedrow2 !== currentInv.servicesperformedrow2) {
          //   reqInvoice.fieldMask.push('Servicesperformedrow2');
          // }
          // if (state.totalamountrow2 !== currentInv.totalamountrow2) {
          //   reqInvoice.fieldMask.push('Totalamountrow2');
          //   totalChanged = true;
          // }
          // if (state.servicesperformedrow3 !== currentInv.servicesperformedrow3) {
          //   reqInvoice.fieldMask.push('Servicesperformedrow3');
          // }
          // if (state.totalamountrow3 !== currentInv.totalamountrow3) {
          //   reqInvoice.fieldMask.push('Totalamountrow3');
          //   totalChanged = true;
          // }
          // if (state.servicesperformedrow4 !== currentInv.servicesperformedrow4) {
          //   reqInvoice.fieldMask.push('Servicesperformedrow4');
          // }
          // if (state.totalamountrow4 !== currentInv.totalamountrow4) {
          //   reqInvoice.fieldMask.push('Totalamountrow4');
          //   totalChanged = true;
          // }
          if (reqInvoice.fieldMask.length > 0) {
            await InvoiceClientService.Update(reqInvoice);
          }
          // createVerboseInvoiceLogging(reqInvoice);
        }
        if (state.sendInvoice) {
          const sqsInvoiceEmail = SQSEmailAndDocument.create();
          const email = SQSEmail.create();
          const document = Document.create();
          document.invoiceId = _invoiceId;
          document.contractId = contractID;
          document.userId = userID;
          // email.to = 'gary.lofrese@kalosflorida.com';
          email.to = '';
          if (state.sendInvoiceToCustomer === true) {
            // email.to = 'austin.kempa@kalosflorida.com';
            email.to = customerQuery.data?.email;
          }
          sqsInvoiceEmail.document = document;
          sqsInvoiceEmail.email = email;
          try {
            await InvoiceClientService.SendSQSInvoiceEmail(sqsInvoiceEmail);
          } catch (err) {
            console.error('we had an error updating the invoice', err);
          }
        }
      } catch (err) {
        console.error(`An error occurred while upserting an invoice: ${err}`);
        try {
          // const devlog = Devlog.create();
          // devlog.userId = userID;
          // devlog.timestamp = format(new Date(), 'yyyy-MM-dd hh:mm:ss');
          // devlog.isError = 1;
          // devlog.description = `Failed to upsert an invoice with error: ${err}`;
          // await DevlogClientService.Create(devlog);
        } catch (err) {
          console.error(`Failed to upload a devlog: ${err}`);
        }
      }
    },
    [
      customerQuery.isSuccess,
      customerQuery.data?.email,
      invoiceId,
      state.sendInvoice,
      state.invoiceData,
      state.sendInvoiceToCustomer,
      userID,
      contractID,
    ],
  );

  const save = useCallback(async () => {
    dispatch({ type: ACTIONS.SET_SAVING, data: true });

    const contractResult = await saveContract();

    if (!contractResult) return;
    await saveInvoice(contractResult);

    // saveEvents();

    dispatch({ type: ACTIONS.SET_SAVING, data: false });
    if (onSaveFinished)
      onSaveFinished({
        contractData: contractData,
        propertiesSelected: state.propertiesSelected ? state.propertiesSelected : [],
        invoiceData: state.invoiceData,
      });
  }, [
    contractData,
    onSaveFinished,
    saveContract,
    saveInvoice,
    state.invoiceData,
    state.propertiesSelected,
  ]);

  const validateForSave = useCallback(() => {
    dispatch({ type: ACTIONS.SET_SAVE, data: false });
    if (state.propertiesSelected !== undefined) {
      if (state.propertiesSelected.length <= 0) {
        dispatch({ type: ACTIONS.SET_VALIDATING, data: true });
        return;
      }
    }
    save();
  }, [save, state.propertiesSelected]);

  const validateForClose = () => {
    if (state.hasBeenChanged) {
      dispatch({ type: ACTIONS.SET_CLOSING_WITHOUT_SAVE, data: true });
      return;
    }
    if (onClose) onClose();
  };

  const handlePropertyFilter = (data: any) => {
    dispatch({
      type: ACTIONS.SET_FILTERED_PROPERTIES,
      data:
        propertiesQuery.data?.results.filter((prop) =>
          prop.address.toLowerCase().includes(data.target.value.toLowerCase()),
        ) ?? [],
    });
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    // if (!state.isLoaded) load();
    if (state.save) validateForSave();
  }, [state.isLoaded, state.save, validateForSave]);

  const contractEventsColumnDefs: ColumnDef<Event>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        enableSorting: false,
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="#" column={column} />
        ),
        cell({ row }) {
          return <div className="w-16">{row.original.id}</div>;
        },
      },
      {
        accessorKey: 'dateStarted',
        enableSorting: false,
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Start Date" column={column} />
        ),
        cell({ row }) {
          return <div className="w-32">{formatDate(row.original.dateStarted)}</div>;
        },
      },
      {
        id: 'address',
        enableSorting: false,
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Address" column={column} />
        ),
        cell({ row }) {
          const address = row.original.property?.address;
          return address ? <div className="w-32">{address}</div> : null;
        },
      },
      {
        id: 'typeAndSubtype',
        enableSorting: false,
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Job Type/SubType" column={column} />
        ),
        cell({ row }) {
          const event = row.original;
          return (
            <div className="w-32">{`${event.jobType ? event.jobType : '-'}/${
              event.jobSubtype ? event.jobSubtype : '-'
            }`}</div>
          );
        },
      },
      {
        accessorKey: 'logJobStatus',
        enableSorting: false,
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Job Status" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('logJobStatus');
          return <div className="w-32">{String(value)}</div>;
        },
      },
      {
        id: 'actions',
        enableSorting: false,
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Actions" column={column} />
        ),
        cell({ row }) {
          return (
            <div className="flex w-32 gap-2">
              <TooltipProvider delayDuration={150}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className="px-2"
                      variant="outline"
                      onClick={() =>
                        dispatch({
                          type: ACTIONS.SET_EDITING,
                          data: { isEditing: true, selectedEvent: row.original },
                        })
                      }
                    >
                      <Pencil1Icon />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="px-2 py-1">Edit service call</TooltipContent>
                </Tooltip>

                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className="px-2"
                      variant="outline"
                      onClick={() =>
                        dispatch({
                          type: ACTIONS.SET_DELETING,
                          data: {
                            isDeleting: true,
                            selectedEvent: row.original,
                          },
                        })
                      }
                    >
                      <TrashIcon />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent className="px-2 py-1">Delete service call</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        },
      },
    ],
    [],
  );

  const skeletonColumns = useMemo<ColumnDef<Event>[]>(() => {
    return contractEventsColumnDefs.map((column) => ({
      ...column,
      cell: () => <Skeleton className="bg-foreground/30 h-4 w-32" />,
    }));
  }, [contractEventsColumnDefs]);

  const quickJobCreate = async (event: Event) => {
    dispatch({
      type: ACTIONS.SET_LOADED,
      data: false,
    });
    await EventClientService.Create(event);
    contractAllEventsQuery.refetch();
    contractEventsQuery.refetch();
    dispatch({
      type: ACTIONS.SET_LOADED,
      data: true,
    });
  };
  const contractEventsTable = useReactTable({
    data: contractEventsQuery.isFetching
      ? loadingContractEvents
      : contractEventsQuery.data?.results ?? [],
    columns: contractEventsQuery.isFetching ? skeletonColumns : contractEventsColumnDefs,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <>
      {state.isSaving || !state.isLoaded ? <Loader /> : undefined}
      <Modal
        open={state.pendingSend}
        onClose={() => dispatch({ type: ACTIONS.SET_PENDING_SEND, data: false })}
      >
        <Confirm
          title={'Send Invoice to Customer'}
          open={state.pendingSend}
          disabled={state.isSaving}
          submitLabel={'Save'}
          cancelLabel={'Cancel'}
          onConfirm={() => {
            dispatch({ type: ACTIONS.SET_SEND_INVOICE, data: true });
            if (!onClose) dispatch({ type: ACTIONS.SET_PENDING_SEND, data: false });
            dispatch({ type: ACTIONS.SET_SAVE, data: true });
          }}
          onClose={() => {
            dispatch({ type: ACTIONS.SET_PENDING_SEND, data: false });
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.sendInvoiceToCustomer}
                disabled={state.isSaving}
                onChange={() =>
                  dispatch({
                    type: ACTIONS.SET_SEND_INVOICE_TO_CUSTOMER,
                    data: !state.sendInvoiceToCustomer,
                  })
                }
              />
            }
            label={`Send Invoice To Customer?`}
          />
        </Confirm>
      </Modal>
      <Modal
        open={state.isAddingNewPM}
        onClose={() => {
          dispatch({
            type: ACTIONS.SET_ADDING_NEW_PM,
            data: {
              isAdding: false,
              selectedProperty: Property.create(),
              selectedEvent: Event.create(),
            },
          });
        }}
      >
        <ServiceRequest
          propertyId={state.selectedProperty.id}
          serviceCallId={0}
          serviceEvent={state.selectedEvent}
          onSave={() => {
            queryClient.invalidateQueries({
              queryKey: [queryKeys.contracts.root],
            });
            queryClient.invalidateQueries({
              queryKey: [queryKeys.events.root],
            });
            dispatch({
              type: ACTIONS.SET_ADDING_NEW_PM,
              data: {
                isAdding: false,
                selectedProperty: Property.create(),
                selectedEvent: Event.create(),
              },
            });
          }}
          onClose={() => {
            contractAllEventsQuery.refetch();
            dispatch({
              type: ACTIONS.SET_ADDING_NEW_PM,
              data: {
                isAdding: false,
                selectedProperty: Property.create(),
                selectedEvent: Event.create(),
              },
            });
          }}
        />
      </Modal>
      <Modal
        open={state.isEditing}
        onClose={() =>
          dispatch({
            type: ACTIONS.SET_EDITING,
            data: { isEditing: false, selectedEvent: Event.create() },
          })
        }
        fullScreen
      >
        <ServiceCall
          userID={userID}
          propertyId={state.selectedEvent.propertyId}
          serviceCallId={state.selectedEvent.id}
          onSave={() => {
            queryClient.invalidateQueries({
              queryKey: [queryKeys.events.root],
            });
            dispatch({
              type: ACTIONS.SET_EDITING,
              data: { isEditing: false, selectedEvent: Event.create() },
            });
          }}
          onClose={() =>
            dispatch({
              type: ACTIONS.SET_EDITING,
              data: { isEditing: false, selectedEvent: Event.create() },
            })
          }
        />
      </Modal>
      {state.isDeleting && (
        <Confirm
          title={`Delete Job ${state.selectedEvent.id}`}
          open={state.isDeleting}
          onClose={() => {
            dispatch({
              type: ACTIONS.SET_DELETING,
              data: { isDeleting: false, selectedEvent: Event.create() },
            });
          }}
          onConfirm={handleDelete}
        >
          Are you sure you want to delete this job?
        </Confirm>
      )}
      {state.error && (
        <Alert
          title={ERROR_LABEL}
          open={state.error !== undefined}
          onClose={() => {
            dispatch({ type: ACTIONS.SET_ERROR, data: undefined });
          }}
        >
          {state.error}
        </Alert>
      )}
      {state.isClosingWithoutSave && (
        <Confirm
          title={WARNING_LABEL}
          open={state.isClosingWithoutSave}
          onClose={() => {
            dispatch({ type: ACTIONS.SET_CLOSING_WITHOUT_SAVE, data: false });
          }}
          onConfirm={() => {
            dispatch({ type: ACTIONS.SET_CLOSING_WITHOUT_SAVE, data: false });
            if (onClose) onClose();
          }}
        >
          {CONFIRM_CLOSE_WITHOUT_SAVE_DIALOG}
        </Confirm>
      )}
      {state.isValidating && (
        <Confirm
          title={CONFIRM_SAVE_LABEL}
          open={true}
          onClose={() => dispatch({ type: ACTIONS.SET_VALIDATING, data: false })}
          onConfirm={() => {
            save();
            dispatch({ type: ACTIONS.SET_VALIDATING, data: false });
          }}
        >
          {CONFIRM_SAVE_DIALOG}
        </Confirm>
      )}
      <SectionBar
        title={`Customer: ${customerQuery.data?.firstname || ''} ${
          customerQuery.data?.lastname || ''
        }`}
        actions={[
          {
            label: contractID ? SAVE_LABEL : 'Create',
            disabled: !state.propertiesSelected?.length,
            onClick: () => {
              const errors: ContractErrors = checkContractErrors(state.pendingSave);
              dispatch({
                type: ACTIONS.SET_CONTRACT_ERRORS,
                data: errors,
              });
              if (!Object.keys(errors).length) {
                dispatch({ type: ACTIONS.SET_PENDING_SEND, data: true });
              }
            },
          },
          { label: CANCEL_LABEL, onClick: () => validateForClose() },
        ]}
        sticky
        styles={{ zIndex: 5 }}
      >
        {!state.propertiesSelected?.length && (
          <div style={{ color: 'red', margin: '10px 0', textAlign: 'center' }}>
            Please select a property to proceed.
          </div>
        )}
        {state.isLoaded && (
          <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
              <SectionBar
                title={contractID > 0 ? `Edit Contract: ${contractData.number}` : 'New Contract'}
                uncollapsable
              >
                <div
                  style={{
                    height: '435px',
                    overflow: 'scroll',
                    borderRight: 1,
                    borderRightStyle: 'solid',
                    borderRightWidth: '15px',
                    borderRightColor: 'lightgray',
                  }}
                >
                  <PlainForm<Contract>
                    error={state.fatalError}
                    key={JSON.stringify(contractData)}
                    schema={CONTRACT_SCHEMA}
                    data={contractData}
                    checkForDataPropChanges={true}
                    className="EditContractInfo"
                    onSubmit={validateForSave}
                    attachFieldMask={true}
                    // onClose={() => onClose()}
                    onChange={(contractData) => {
                      const fields: (keyof Contract)[] = Object.keys(contractData).filter(
                        (key: string) =>
                          contractData[key as keyof Contract] !==
                            state.pendingSave[key as keyof Contract] ||
                          Object.keys(state.contractErrors).includes(key),
                      ) as (keyof Contract)[];
                      const errors: ContractErrors = checkContractErrors(contractData, fields);
                      dispatch({
                        type: ACTIONS.SET_CONTRACT_ERRORS,
                        data: errors,
                      });
                      dispatch({
                        type: ACTIONS.SET_HAS_BEEN_CHANGED,
                        data: true,
                      });
                      const req = Contract.clone(contractData);
                      // let req = contractData;
                      req.isActive = 1;
                      req.userId = userID;
                      if (
                        req.fieldMask.includes('DepartmentId') &&
                        state.pendingSave.departmentId !== req.departmentId
                      ) {
                        switch (String(req.departmentId)) {
                          case '19': {
                            req.timeStarted = '0001-01-01 23:30';
                            req.timeEnded = '0001-01-01 23:45';
                            contractData.timeStarted = '0001-01-01 23:30';
                            contractData.timeEnded = '0001-01-01 23:45';
                            req.fieldMask = req.fieldMask.concat('TimeStarted', 'TimeEnded');
                            break;
                          }
                          case '20': {
                            req.timeStarted = '0001-01-01 23:30';
                            req.timeEnded = '0001-01-01 23:45';
                            contractData.timeStarted = '0001-01-01 23:30';
                            contractData.timeEnded = '0001-01-01 23:45';
                            req.fieldMask = req.fieldMask.concat('TimeStarted', 'TimeEnded');
                            break;
                          }
                          case '17': {
                            req.timeStarted = '0001-01-01 22:00';
                            req.timeEnded = '0001-01-01 22:45';
                            contractData.timeStarted = '0001-01-01 22:00';
                            contractData.timeEnded = '0001-01-01 22:45';
                            req.fieldMask = req.fieldMask.concat('TimeStarted', 'TimeEnded');
                            break;
                          }
                          case '18': {
                            req.timeStarted = '0001-01-01 23:00';
                            req.timeEnded = '0001-01-01 23:45';
                            contractData.timeStarted = '0001-01-01 23:00';
                            contractData.timeEnded = '0001-01-01 23:45';
                            req.fieldMask = req.fieldMask.concat('TimeStarted', 'TimeEnded');
                            break;
                          }
                        }
                      }
                      if (
                        req.fieldMask.includes('DateStarted') &&
                        state.pendingSave.dateStarted !== req.dateStarted
                      ) {
                        req.dateEnded = format(
                          addYears(parseISO(req.dateStarted), 1),
                          'yyyy-MM-dd HH:mm:ss',
                        );
                        contractData.dateEnded = format(
                          addYears(parseISO(req.dateStarted), 1),
                          'yyyy-MM-dd HH:mm:ss',
                        );
                        if (!req.fieldMask.includes('DateEnded')) {
                          req.fieldMask = req.fieldMask.concat('DateEnded');
                        }
                      }

                      dispatch({
                        type: ACTIONS.SET_PENDING_SAVE,
                        data: req,
                      });
                      if (onChange)
                        onChange({
                          contractData: req,
                          propertiesSelected: state.propertiesSelected,
                          invoiceData: state.invoiceData,
                        } as Output);
                    }}
                    validations={state.contractErrors}
                  />
                </div>
              </SectionBar>
            </Grid>
            <Grid item xs={12} md={4}>
              <SectionBar title={'Properties'} uncollapsable>
                <FormControl
                  component="fieldset"
                  variant="outlined"
                  fullWidth
                  sx={{ paddingBottom: '20px', paddingLeft: '5px' }}
                >
                  <div>
                    <TextField
                      label="Address"
                      variant="standard"
                      sx={{ width: '98%', alignContent: 'center' }}
                      onChange={debounce(handlePropertyFilter, 300)}
                    />
                  </div>
                  <TableContainer sx={{ maxHeight: '365px', overflowY: 'scroll' }}>
                    <Table sx={{ minWidth: '100%' }} padding="checkbox">
                      <TableHead></TableHead>
                      <TableBody>
                        {state.filteredProperties.map((property) => {
                          let pmCount = 0;
                          const displayPMCount =
                            contractData.properties &&
                            contractData.properties.split(',').includes(String(property.id));
                          if (displayPMCount) {
                            pmCount =
                              contractAllEventsQuery.data?.results.filter(
                                (event) => event.propertyId === property.id,
                              ).length ?? 0;
                          }
                          return (
                            <TableRow key={property.id} hover>
                              <TableCell sx={{ width: '5%' }}>
                                <Checkbox
                                  checked={state.propertiesSelected!.includes(property)}
                                  name={property.address}
                                  onClick={() => {
                                    const newSelectedPropertiesArray = state.propertiesSelected
                                      ? state.propertiesSelected
                                      : [];
                                    if (newSelectedPropertiesArray.includes(property)) {
                                      const index = newSelectedPropertiesArray.findIndex(
                                        (prop) => prop.id === property.id,
                                      );
                                      newSelectedPropertiesArray.splice(index, 1);
                                    } else {
                                      newSelectedPropertiesArray.push(property);
                                    }
                                    dispatch({
                                      type: ACTIONS.SET_PROPERTIES_SELECTED,
                                      data: newSelectedPropertiesArray,
                                    });
                                  }}
                                />
                              </TableCell>
                              <TableCell sx={{ width: '70%' }}>
                                <Typography sx={{ textAlign: 'left' }}>
                                  {property.address}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{ width: '20%' }}>
                                <Typography
                                  sx={{
                                    textAlign: 'right',
                                    display: displayPMCount ? '' : 'none',
                                  }}
                                >{`PM's ${pmCount}`}</Typography>
                              </TableCell>
                              <TableCell sx={{ width: '5%' }}>
                                <TooltipProvider delayDuration={100}>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <IconButton
                                        sx={{ display: displayPMCount ? '' : 'none' }}
                                        onClick={() => {
                                          let insert_description = `${property.address}, `;
                                          switch (property.zip) {
                                            case '34714': {
                                              insert_description =
                                                insert_description.concat(`S. Clermont`);
                                              break;
                                            }
                                            case '34711': {
                                              insert_description =
                                                insert_description.concat('Clermont');
                                              break;
                                            }
                                            case '34715': {
                                              insert_description =
                                                insert_description.concat('Clermont');
                                              break;
                                            }
                                            default: {
                                              insert_description = insert_description.concat(
                                                property.city,
                                              );
                                            }
                                          }
                                          const materials = '';
                                          const filters = '';
                                          let event_description = '';
                                          if (materials !== '') {
                                            event_description = event_description.concat(materials);
                                          }
                                          if (filters !== '') {
                                            event_description = event_description.concat(filters);
                                          }
                                          event_description = event_description.concat(
                                            contractData.notes,
                                          );
                                          if (event_description === '') {
                                            event_description = 'PM';
                                          }
                                          const event = Event.create();
                                          event.name = `${insert_description} ${property.zip}`;
                                          event.description = 'PM';
                                          event.propertyId = property.id;
                                          event.contractId = contractData.id;
                                          event.contractNumber = contractData.number;
                                          event.dateStarted = `${format(
                                            new Date(),
                                            'yyyy-MM-dd',
                                          )} ${format(
                                            parseISO(contractData.timeStarted),
                                            'HH:mm:ss',
                                          )}`;
                                          event.dateEnded = `${format(
                                            new Date(),
                                            'yyyy-MM-dd',
                                          )} ${format(
                                            parseISO(contractData.timeEnded),
                                            'HH:mm:ss',
                                          )}`;
                                          event.timeStarted = format(
                                            parseISO(contractData.timeStarted),
                                            'HH:mm',
                                          );
                                          event.timeEnded = format(
                                            parseISO(contractData.timeEnded),
                                            'HH:mm',
                                          );
                                          event.color = 'fd9834';
                                          event.dateCreated = format(
                                            new Date(),
                                            'yyyy-MM-dd HH:mm:ss',
                                          );
                                          event.dateUpdated = format(
                                            new Date(),
                                            'yyyy-MM-dd HH:mm:ss',
                                          );
                                          event.logJobStatus = 'Pend Sched';
                                          event.jobTypeId = 3;
                                          event.logVersion = 1;
                                          event.logNotes = event_description;
                                          event.logPaymentType = contractData.paymentType;
                                          event.logPaymentStatus = contractData.paymentStatus;
                                          event.departmentId = contractData.departmentId;
                                          if (
                                            contractData.departmentId === 19 ||
                                            contractData.departmentId === 20
                                          ) {
                                            event.isResidential = 1;
                                          } else {
                                            event.isResidential = 0;
                                          }
                                          event.amountQuoted = contractData.paymentTerms;
                                          quickJobCreate(event);
                                        }}
                                      >
                                        <AddCircleOutlineTwoTone sx={{ fontSize: 20 }} />
                                      </IconButton>
                                    </TooltipTrigger>
                                    <TooltipContent>Quick create job </TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </TableCell>
                              <TableCell sx={{ width: '5%' }}>
                                <TooltipProvider delayDuration={100}>
                                  <Tooltip>
                                    <TooltipTrigger>
                                      <IconButton
                                        sx={{ display: displayPMCount ? '' : 'none' }}
                                        onClick={() => {
                                          let insert_description = `${property.address}, `;
                                          switch (property.zip) {
                                            case '34714': {
                                              insert_description =
                                                insert_description.concat(`S. Clermont`);
                                              break;
                                            }
                                            case '34711': {
                                              insert_description =
                                                insert_description.concat('Clermont');
                                              break;
                                            }
                                            case '34715': {
                                              insert_description =
                                                insert_description.concat('Clermont');
                                              break;
                                            }
                                            default: {
                                              insert_description = insert_description.concat(
                                                property.city,
                                              );
                                            }
                                          }
                                          const materials = '';
                                          const filters = '';
                                          let event_description = '';
                                          if (materials !== '') {
                                            event_description = event_description.concat(materials);
                                          }
                                          if (filters !== '') {
                                            event_description = event_description.concat(filters);
                                          }
                                          event_description = event_description.concat(
                                            contractData.notes,
                                          );
                                          if (event_description === '') {
                                            event_description = 'PM';
                                          }
                                          const event = Event.create();
                                          event.name = `${insert_description} ${property.zip}`;
                                          event.description = 'PM';
                                          event.propertyId = property.id;
                                          event.contractId = contractData.id;
                                          event.contractNumber = contractData.number;
                                          event.dateStarted = `${format(
                                            new Date(),
                                            'yyyy-MM-dd',
                                          )} ${format(
                                            parseISO(contractData.timeStarted),
                                            'HH:mm:ss',
                                          )}`;
                                          event.dateEnded = `${format(
                                            new Date(),
                                            'yyyy-MM-dd',
                                          )} ${format(
                                            parseISO(contractData.timeEnded),
                                            'HH:mm:ss',
                                          )}`;
                                          event.timeStarted = format(
                                            parseISO(contractData.timeStarted),
                                            'HH:mm',
                                          );
                                          event.timeEnded = format(
                                            parseISO(contractData.timeEnded),
                                            'HH:mm',
                                          );
                                          event.color = 'fd9834';
                                          event.dateCreated = format(
                                            new Date(),
                                            'yyyy-MM-dd HH:mm:ss',
                                          );
                                          event.dateUpdated = format(
                                            new Date(),
                                            'yyyy-MM-dd HH:mm:ss',
                                          );
                                          event.logJobStatus = 'Pend Sched';
                                          event.jobTypeId = 3;
                                          event.logVersion = 1;
                                          event.logNotes = event_description;
                                          event.logPaymentType = contractData.paymentType;
                                          event.logPaymentStatus = contractData.paymentStatus;
                                          event.departmentId = contractData.departmentId;
                                          event.isResidential = 0;
                                          event.amountQuoted = contractData.paymentTerms;
                                          dispatch({
                                            type: ACTIONS.SET_ADDING_NEW_PM,
                                            data: {
                                              isAdding: true,
                                              selectedProperty: property,
                                              selectedEvent: event,
                                            },
                                          });
                                        }}
                                      >
                                        <EditSharp sx={{ fontSize: 20 }} />
                                      </IconButton>
                                    </TooltipTrigger>
                                    <TooltipContent>Edit property</TooltipContent>
                                  </Tooltip>
                                </TooltipProvider>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </FormControl>
              </SectionBar>
            </Grid>
          </Grid>
        )}
      </SectionBar>
      {state.isLoaded && !contractQuery.isFetching && (
        <SectionBar
          title={INVOICE_SECTION_NAME}
          uncollapsable
          // actions={[{ label: CANCEL_LABEL, onClick: () => validateForClose() }]}
        >
          <EditInvoiceData
            userId={userID}
            contract={contractData}
            onClose={() => onClose?.()}
            onSave={(savedInvoice) => {
              dispatch({
                type: ACTIONS.SET_INVOICE_DATA,
                data: savedInvoice,
              });
              validateForSave();
            }}
            onLoad={(loadedData) => {
              dispatch({
                type: ACTIONS.SET_INVOICE_DATA,
                data: loadedData,
              });
              if (onChange)
                onChange({
                  contractData: contractData,
                  propertiesSelected:
                    state.propertiesSelected !== undefined ? state.propertiesSelected : [],
                  invoiceData: state.invoiceData,
                });
            }}
            onChange={(currentData) => {
              dispatch({ type: ACTIONS.SET_HAS_BEEN_CHANGED, data: true });
              dispatch({
                type: ACTIONS.SET_INVOICE_DATA,
                data: currentData,
              });
              if (onChange)
                onChange({
                  contractData: contractData,
                  propertiesSelected:
                    state.propertiesSelected !== undefined ? state.propertiesSelected : [],
                  invoiceData: state.invoiceData,
                });
            }}
          />
        </SectionBar>
      )}
      {state.isLoaded && contractID !== 0 && (
        <SectionBar
          title={'Service Calls'}
          sticky={false}
          subtitle={SERVICE_CALL_SUBTITLE}
          pagination={{
            count: contractEventsQuery.data?.totalCount ?? 0,
            rowsPerPage: 25,
            page: state.eventPage,
            onPageChange: (page) => {
              dispatch({ type: ACTIONS.SET_EVENT_PAGE, data: page });
            },
          }}
          uncollapsable
          defaultCollapsed={!isContractJobsSectionShown}
          onToggleCollapse={() => {
            setIsContractJobsSectionShown((value) => !value);
          }}
        >
          {state.loadingEvents && <Loader />}
          <DataTable table={contractEventsTable} />
        </SectionBar>
      )}
    </>
  );
};
