import { ActivityLog, getPropertyAddress, Property } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Form,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@kalos/ui';
import { MagnifyingGlassIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  type OnChangeFn,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME, ROWS_PER_PAGE } from '../../../../constants';
import { useAuth } from '../../../../context/AuthContext';
import { queryKeys } from '../../../../hooks/react-query/constants';
import { usePropertiesQueryByRequest } from '../../../../hooks/react-query/usePropertiesQuery';
import {
  ActivityLogClientService,
  debounce,
  type LoadPropertiesByFilter,
  type PropertiesSort,
  PropertyClientService,
} from '../../../../tools/helpers';
import { PropertyInfo } from '../../../PropertyInformation/components/PropertyInfo';
import { ConfirmDelete } from '../../ConfirmDelete';
import { CustomerInformation } from '../../CustomerInformation';
import { Modal } from '../../Modal';
import { PropertyEdit } from '../../PropertyEdit';
import { type AdvancedSearchProps } from '..';
import { useAdvancedSearchPaginationStore } from '../paginationStore';
import PropertySearchForm from './PropertySearchForm';
import { type PropertiesSearchScheme, usePropertySearchForm } from './utils';

type PropertiesSearchProps = Pick<
  AdvancedSearchProps,
  'editableProperties' | 'deletableProperties' | 'onSelectProperty'
> & { exceptionId?: number; disableParamsSync?: boolean };

const loadingProperties: Property[] = Array.from({ length: 5 }, (_, i) => Property.create());

export const PropertiesSearch = ({
  exceptionId,
  deletableProperties,
  editableProperties,
  onSelectProperty,
  disableParamsSync,
}: PropertiesSearchProps) => {
  const page = useAdvancedSearchPaginationStore((state) => state.page);
  const setPage = useAdvancedSearchPaginationStore((state) => state.setPage);
  const setTotalPageCount = useAdvancedSearchPaginationStore((state) => state.setTotalEntriesCount);

  const propertiesSearchForm = usePropertySearchForm({ disableParamsSync });

  const [propertiesFilter, setPropertiesFilter] = useState<PropertiesSearchScheme>(() =>
    propertiesSearchForm.getValues(),
  );
  useEffect(() => {
    const debouncedUpdate = debounce((data: PropertiesSearchScheme) => {
      setPropertiesFilter(data);
      setPage(0);
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);
    const updateSubscription = propertiesSearchForm.watch(debouncedUpdate);
    return () => updateSubscription.unsubscribe();
  }, [propertiesSearchForm, setPage]);

  const loggedUserId = useAuth().user.id;

  const [propertiesSort, setPropertiesSort] = useState<PropertiesSort>({
    orderByField: 'address',
    orderBy: 'property_address',
    orderDir: 'ASC',
  });

  const propertiesRequest = useMemo<Property>(() => {
    const criteria: LoadPropertiesByFilter = {
      page,
      filter: propertiesFilter,
      sort: propertiesSort,
      req: Property.create(),
    };

    const request = Property.create();
    if (criteria.filter.address) {
      request.address = `%${criteria.filter.address}%`;
    }
    if (criteria.filter.subdivision) {
      request.subdivision = `%${criteria.filter.subdivision}%`;
    }
    if (criteria.filter.zip) {
      request.zip = `%${criteria.filter.zip}%`;
    }
    if (criteria.filter.state) {
      request.state = `%${criteria.filter.state}%`;
    }
    if (criteria.filter.city) {
      request.city = `%${criteria.filter.city}%`;
    }
    request.pageNumber = criteria.page;
    if (criteria.sort.orderByField == 'address') {
      request.orderBy = 'property_address';
    }
    if (criteria.sort.orderByField == 'subdivision') {
      request.orderBy = 'property_subdivision';
    }
    if (criteria.sort.orderByField == 'city') {
      request.orderBy = 'property_city';
    }
    if (criteria.sort.orderByField == 'zip') {
      request.orderBy = 'property_zip';
    }
    if (criteria.sort.orderDir) {
      request.orderDir = criteria.sort.orderDir;
    }
    request.isActive = 1;
    return request;
  }, [page, propertiesFilter, propertiesSort]);

  const propertiesQuery = usePropertiesQueryByRequest({
    request: propertiesRequest,
  });

  useEffect(() => {
    if (propertiesQuery.isSuccess) {
      setTotalPageCount(propertiesQuery.data?.totalCount || 0);
    }
  }, [propertiesQuery.data?.totalCount, propertiesQuery.isSuccess, setTotalPageCount]);
  const queryClient = useQueryClient();

  const [pendingPropertyViewing, setPendingPropertyViewing] = useState<Property | null>(null);
  const handlePendingPropertyViewingToggle = useCallback(
    (pendingPropertyViewing: Property | null) => () =>
      setPendingPropertyViewing(pendingPropertyViewing),
    [setPendingPropertyViewing],
  );

  const [pendingPropertyDeleting, setPendingPropertyDeleting] = useState<Property>();
  const [pendingPropertyEditing, setPendingPropertyEditing] = useState<Property>();
  const handlePendingPropertyEditingToggle = useCallback(
    (pendingPropertyEditing?: Property) => () => setPendingPropertyEditing(pendingPropertyEditing),
    [setPendingPropertyEditing],
  );
  const onSaveProperty = useCallback(() => {
    setPendingPropertyEditing(undefined);
    queryClient.refetchQueries({ queryKey: [queryKeys.properties.root] });
  }, [queryClient]);
  const onSelectPropertyCallback = useCallback(
    (property: Property, nativeEvent?: React.MouseEvent<HTMLTableRowElement>) => {
      if (nativeEvent && property.id !== exceptionId) {
        if (!onSelectProperty) {
          setPendingPropertyViewing(property);
        }
        if (onSelectProperty) {
          onSelectProperty(property);
        }
      }
    },
    [onSelectProperty, exceptionId],
  );

  const handleDeleteProperty = useCallback(async () => {
    if (pendingPropertyDeleting) {
      const id = pendingPropertyDeleting.id;
      const actLog = ActivityLog.create();
      actLog.userId = loggedUserId || 0;
      actLog.propertyId = id;
      actLog.activityName = `Deleting Property : ${pendingPropertyDeleting.address}`;
      actLog.activityDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
      setPendingPropertyDeleting(undefined);
      try {
        await PropertyClientService.deletePropertyById(id);
        await ActivityLogClientService.Create(actLog);
      } catch (err) {
        console.error(err);
      }
      queryClient.refetchQueries({ queryKey: [queryKeys.properties.root] });
    }
  }, [loggedUserId, pendingPropertyDeleting, queryClient]);
  const handlePendingPropertyDeletingToggle = useCallback(
    (pendingPropertyDeleting?: Property) => () =>
      setPendingPropertyDeleting(pendingPropertyDeleting),
    [setPendingPropertyDeleting],
  );

  const propertiesTableColumns = useMemo<ColumnDef<Property>[]>(() => {
    const columns: ColumnDef<Property>[] = [
      {
        accessorKey: 'address',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Address" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('address');
          return <div className="w-32">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'subdivision',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Subdivision" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('subdivision');
          return <div className="w-32">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'city',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="City" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('city');
          return <div className="w-32">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
      {
        accessorKey: 'zip',
        header: ({ column }) => (
          <DataTableColumnHeader hideVisibilityToggle title="Zip Code" column={column} />
        ),
        cell({ row }) {
          const value = row.getValue('zip');
          return <div className="w-32">{String(value)}</div>;
        },
        sortingFn: () => 0,
      },
    ];
    return columns;
  }, []);

  const selectColumns = useMemo<ColumnDef<Property>[]>(() => {
    return onSelectProperty
      ? [
          {
            id: 'select',
            cell: ({ row }) => {
              return (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger disabled={row.original.id === exceptionId}>
                      <Button disabled={row.original.id === exceptionId}>
                        {row.original.id === exceptionId ? 'Current Property' : `${'Select'}`}
                      </Button>
                    </TooltipTrigger>
                    {row.original.id === exceptionId ? (
                      <TooltipContent>We are currently viewing</TooltipContent>
                    ) : (
                      ''
                    )}
                  </Tooltip>
                </TooltipProvider>
              );
            },
          },
        ]
      : [];
  }, [onSelectProperty, exceptionId]);

  const actionsColumn = useMemo<ColumnDef<Property>[]>(() => {
    return !onSelectProperty
      ? [
          {
            id: 'actions',
            header: () => <div className="ml-auto text-center">Actions</div>,
            cell: ({ row }) => {
              const entry = row.original;
              return (
                <div className="mx-auto flex w-32 justify-center gap-2">
                  <TooltipProvider delayDuration={150}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          title="View"
                          variant="outline"
                          className="px-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            onSelectPropertyCallback(entry);
                          }}
                        >
                          <MagnifyingGlassIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent className="px-2 py-1">View Property</TooltipContent>
                    </Tooltip>
                    {editableProperties && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            title="Edit"
                            variant="outline"
                            className="px-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              setPendingPropertyEditing(entry);
                            }}
                          >
                            <Pencil1Icon />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="px-2 py-1">Edit Property</TooltipContent>
                      </Tooltip>
                    )}
                    {deletableProperties && (
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            title="Delete"
                            variant="outline"
                            className="px-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              setPendingPropertyDeleting(entry);
                            }}
                          >
                            <TrashIcon />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent className="px-2 py-1">Delete Property</TooltipContent>
                      </Tooltip>
                    )}
                  </TooltipProvider>
                </div>
              );
            },
          },
        ]
      : [];
  }, [onSelectProperty, editableProperties, deletableProperties, onSelectPropertyCallback]); // Add dependencies related to the properties if they affect re-calculation.

  const columns = useMemo(() => {
    return [...selectColumns, ...propertiesTableColumns, ...actionsColumn];
  }, [selectColumns, propertiesTableColumns, actionsColumn]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const onSortChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      setSorting((previousState) => {
        const newState = typeof updater === 'function' ? updater(previousState) : updater;
        setPropertiesSort({
          orderByField: newState[0].id as keyof Property,
          orderDir: newState[0].desc ? 'DESC' : 'ASC',
          orderBy: ``,
        });
        return newState;
      });
      setPage(0);
    },
    [setPage],
  );

  const skeletonColumns = useMemo<ColumnDef<Property>[]>(() => {
    return columns.map((column) => ({
      ...column,
      cell: () => <Skeleton className="bg-foreground/30 h-4 w-32" />,
    }));
  }, [columns]);

  const table = useReactTable({
    data: propertiesQuery.isPending ? loadingProperties : propertiesQuery.data?.results ?? [],
    columns: propertiesQuery.isPending ? skeletonColumns : columns,
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 25,
      },
    },
    enableRowSelection: true,
    onSortingChange: onSortChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <>
      <div className="p-4">
        <Form {...propertiesSearchForm}>
          <PropertySearchForm />
        </Form>
      </div>
      <div className="xs:flex-nowrap flex w-full flex-wrap items-center justify-between px-2">
        <div className="flex items-center">
          <DataTablePagination
            pageCount={Math.ceil(propertiesQuery.data?.totalCount || 0 / ROWS_PER_PAGE)}
            currentPage={page}
            setPage={setPage}
          />
        </div>
        <p className="text-sm">Total Entries count: {propertiesQuery.data?.totalCount || 0}</p>
      </div>
      <DataTable table={table} onRowClick={onSelectPropertyCallback} />

      {pendingPropertyViewing && (
        <Modal open onClose={handlePendingPropertyViewingToggle(null)} fullScreen>
          <CustomerInformation
            userID={pendingPropertyViewing.userId}
            propertyId={pendingPropertyViewing.id}
            onClose={handlePendingPropertyViewingToggle(null)}
          />
          <PropertyInfo
            propertyId={pendingPropertyViewing.id}
            onClose={handlePendingPropertyViewingToggle(null)}
          />
        </Modal>
      )}

      {pendingPropertyEditing && (
        <Modal open onClose={handlePendingPropertyEditingToggle(undefined)}>
          <PropertyEdit
            userId={pendingPropertyEditing.userId}
            propertyId={pendingPropertyEditing.id}
            property={pendingPropertyEditing}
            onSave={onSaveProperty}
            onClose={handlePendingPropertyEditingToggle(undefined)}
          />
        </Modal>
      )}
      {pendingPropertyDeleting && (
        <ConfirmDelete
          open
          onClose={handlePendingPropertyDeletingToggle(undefined)}
          onConfirm={handleDeleteProperty}
          kind="Property"
          name={`with address ${getPropertyAddress(pendingPropertyDeleting)}`}
        />
      )}
    </>
  );
};
