import { ContractFrequency } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { ContractFrequencyClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type ContractFrequencyFilter = EntityFilter<ContractFrequency>;
export const useContractFrequencyBatchGetQuery = ({
  filter = {},
}: { filter?: ContractFrequencyFilter } = {}) => {
  return useQuery({
    queryFn: () => {
      const req = ContractFrequency.create(filter);
      return ContractFrequencyClientService.BatchGet(req);
    },
    queryKey: [
      queryKeys.contractsFrequency.root,
      queryKeys.contractsFrequency.list,
      queryKeys.contractsFrequency.getHash(filter),
    ],
  });
};

export const useContractFrequencyGetQuery = ({
  filter = {},
  enabled = true,
}: { filter?: ContractFrequencyFilter; enabled?: boolean } = {}) => {
  return useQuery({
    queryFn: () => {
      const req = ContractFrequency.create(filter);
      return ContractFrequencyClientService.Get(req);
    },
    queryKey: [queryKeys.contractsFrequency.root, queryKeys.contractsFrequency.getHash(filter)],
    enabled,
  });
};
