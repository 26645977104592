import { EmployeeItemOption } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { useCallback, useMemo, useState } from 'react';

import { useEmployeeItemOptionCreateMutation } from '../../../../hooks/react-query/employeeItems';
import {
  EmployeeItemOptionForm,
  getDefaultEmployeeItemOptionFormValues,
} from './EmployeeItemOptionForm';

export const EmployeeItemOptionFormCreateDialog = ({ trigger }: { trigger: React.ReactNode }) => {
  const createEmployeeItemOptionMutation = useEmployeeItemOptionCreateMutation();
  const isLoading = createEmployeeItemOptionMutation.isPending;
  const [isOpen, setIsOpen] = useState(false);

  const handleCreate = useCallback<React.ComponentProps<typeof EmployeeItemOptionForm>['onSave']>(
    async ({ data }) => {
      try {
        await createEmployeeItemOptionMutation.mutateAsync(EmployeeItemOption.create(data));
        toast({
          variant: 'success',
          title: 'Successfully created item option',
        });
        setIsOpen(false);
      } catch {
        toast({
          variant: 'destructive',
          title: 'Failed to create item option',
        });
      }
    },
    [createEmployeeItemOptionMutation],
  );

  const defaultValues = useMemo(() => getDefaultEmployeeItemOptionFormValues(), []);

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>

      <DialogContent className="overflow-auto">
        <DialogTitle>Create Employee Item Option</DialogTitle>
        <EmployeeItemOptionForm
          isLoading={isLoading}
          disabled={isLoading}
          onSave={handleCreate}
          defaultValues={defaultValues}
        />
      </DialogContent>
    </Dialog>
  );
};
