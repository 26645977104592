import { Contract, type ContractMaterial } from '@kalos/kalos-rpc';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { type FC, useCallback, useEffect, useReducer } from 'react';

import { ContractClientService } from '../../../tools/helpers';
import { SectionBar } from '../../ComponentsLibrary/SectionBar';

interface Props {
  contractID: number;
  onClose?: () => any;
}

interface State {
  isLoaded: boolean;
  materialList: ContractMaterial[];
  propertyList: number[];
}

type Action =
  | {
      type: 'setMaterials';
      data: {
        contractMaterials: ContractMaterial[];
        propertyList: number[];
      };
    }
  | {
      type: 'setLoaded';
      data: boolean;
    };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setMaterials':
      return {
        ...state,
        materialList: action.data.contractMaterials,
        propertyList: action.data.propertyList,
        isLoaded: true,
      };
    case 'setLoaded':
      return {
        ...state,
        isLoaded: action.data,
      };
    default:
      return state;
  }
};

const initialState: State = {
  isLoaded: false,
  materialList: [],
  propertyList: [],
};

export const ContractMaterials: FC<Props> = ({ contractID, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const load = useCallback(async () => {
    const materialReq = Contract.create();
    materialReq.id = contractID;
    try {
      const materials = await ContractClientService.GetMaterialsByContractID(materialReq);
      console.log(materials!.results);
      const properties = materials!.results.map((mat) => mat.propertyId);
      const propertyList: number[] = [];
      properties.forEach((prop) => {
        if (!propertyList.includes(prop)) propertyList.push(prop);
      });
      dispatch({
        type: 'setMaterials',
        data: {
          contractMaterials: materials!.results,
          propertyList: propertyList,
        },
      });
    } catch (err) {
      console.error(`Failed to get materials for Contract: ${contractID}`);
      dispatch({ type: 'setLoaded', data: true });
    }
  }, [contractID]);

  useEffect(() => {
    if (!state.isLoaded) {
      load();
    }
  }, [state.isLoaded, load]);

  return (
    <SectionBar
      title="Contract Materials"
      uncollapsable
      actions={[
        {
          label: 'Exit',
          onClick: () => {
            if (onClose) {
              onClose();
            }
          },
        },
      ]}
    >
      {state.isLoaded && (
        <div>
          {state.propertyList.length > 0 &&
            state.propertyList.map((prop) => (
              <div key={`${prop}`}>
                <Typography
                  sx={{
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '20px',
                  }}
                >
                  {state.materialList.filter((mat) => mat.propertyId === prop)[0].propertyAddress}
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '35%' }}>Item</TableCell>
                        <TableCell sx={{ width: '10%' }}>Quantity</TableCell>
                        <TableCell sx={{ width: '35%' }}>Part #</TableCell>
                        <TableCell sx={{ width: '20%' }}>Vendor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.materialList.filter((mat) => mat.propertyId === prop).length > 0 &&
                        state.materialList
                          .filter((mat) => mat.propertyId === prop)
                          .map((mat) => (
                            <TableRow key={mat.materialId} hover>
                              <TableCell>{mat.materialName}</TableCell>
                              <TableCell>{mat.materialQuantity}</TableCell>
                              <TableCell>{mat.materialPartNumber}</TableCell>
                              <TableCell>{mat.materialVendor}</TableCell>
                            </TableRow>
                          ))}
                      {state.materialList.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={4}>No Records</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ))}
        </div>
      )}
    </SectionBar>
  );
};
