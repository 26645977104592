import { Invoice } from '@kalos/kalos-rpc';
import { useQuery } from '@tanstack/react-query';

import { InvoiceClientService } from '../../tools/helpers';
import { queryKeys } from './constants';
import { type EntityFilter } from './utils';

export type InvoiceFilter = EntityFilter<Invoice>;
export const useInvoiceQuery = ({
  filter = {},
  enabled = true,
}: { filter?: InvoiceFilter; enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: [queryKeys.invoice.root, queryKeys.invoice.getHash(filter)],
    queryFn: () => {
      const req = Invoice.create(filter);
      return InvoiceClientService.Get(req);
    },
    enabled,
  });
};
