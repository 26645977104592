import { EmployeeItem } from '@kalos/kalos-rpc';
import { Dialog, DialogContent, DialogTitle, DialogTrigger, toast } from '@kalos/ui';
import { parseISO } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { useEmployeeItemUpdateMutation } from '../../hooks/react-query/employeeItems';
import { timestamp } from '../../tools/helpers';
import { getFieldMaskFromDirtyField } from '../ComponentsLibrary/EmployeeDepartments/EditEmployeeFunction';
import { EmployeeItemForm, getDefaultEmployeeItemFormValues } from './EmployeeItemForm';

export const EmployeeItemsEditDialog = ({
  trigger,
  original,
}: {
  trigger: React.ReactNode;
  original: EmployeeItem;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultValues = useMemo(
    () =>
      getDefaultEmployeeItemFormValues({
        name: original.name,
        date: parseISO(original.date),
        notes: original.notes,
      }),
    [original],
  );
  const updateMutation = useEmployeeItemUpdateMutation();

  const onSave = useCallback<React.ComponentProps<typeof EmployeeItemForm>['onSave']>(
    async ({ data, dirtyFields }) => {
      const fieldMask = getFieldMaskFromDirtyField(dirtyFields);
      if (!fieldMask.length) return;
      try {
        await updateMutation.mutateAsync(
          EmployeeItem.create({
            ...original,
            ...data,
            date: timestamp(false, data.date),
            fieldMask,
          }),
        );
        toast({
          variant: 'success',
          title: 'Employee Item Created',
        });
        setIsOpen(false);
      } catch (err) {
        toast({
          variant: 'destructive',
          title: 'Employee Item Edit failed',
        });
      }
    },
    [original, updateMutation],
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="@container max-w-xl">
        <DialogTitle>Edit Employee Item</DialogTitle>
        <EmployeeItemForm
          defaultValues={defaultValues}
          onSave={onSave}
          disabled={updateMutation.isPending}
          isLoading={updateMutation.isPending}
        />
      </DialogContent>
    </Dialog>
  );
};
