import './PropertyInfo.module.less';

import { ActivityLog, Property, User } from '@kalos/kalos-rpc';
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@kalos/ui';
import { DotsHorizontalIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import format from 'date-fns/esm/format';
import { Activity, Bell, GitMerge, UserPen, UserRoundSearch } from 'lucide-react';
import { type FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../context/AuthContext';
import { useNavigate, useParams } from '../../../react-router';
import {
  ActivityLogClientService,
  makeFakeRows,
  PropertyClientService,
  UserClientService,
} from '../../../tools/helpers';
import { Confirm } from '../../ComponentsLibrary/Confirm';
import { ConfirmDelete } from '../../ComponentsLibrary/ConfirmDelete';
import { Form, type Schema } from '../../ComponentsLibrary/Form';
import { type Data, InfoTable } from '../../ComponentsLibrary/InfoTable';
import { Modal } from '../../ComponentsLibrary/Modal';
import { PropertyEdit } from '../../ComponentsLibrary/PropertyEdit';
import { Search } from '../../ComponentsLibrary/Search';
import { SectionBar } from '../../ComponentsLibrary/SectionBar';
import { ServiceItems } from '../../ComponentsLibrary/ServiceItems';
import { PropertyActivity } from './PropertyActivity';
import { PropertyDocuments } from './PropertyDocuments';
import { ServiceCalls } from './ServiceCalls';

export interface ProptertyNotification {
  notification: string;
  id: number;
}
const SCHEMA_PROPERTY_NOTIFICATION: Schema<ProptertyNotification> = [
  [
    {
      label: 'Notification',
      name: 'notification',
      required: true,
      multiline: true,
      type: 'text',
      validationOnSave(value) {
        if (!value || (typeof value === 'string' && !value.trim().replace(' ', ''))) {
          return 'Notification is required';
        }

        if (value.length > 1000) {
          return 'Notification is too long (max 1000 characters)';
        }

        return '';
      },
    },
  ],
  [
    {
      name: 'id',
      type: 'hidden',
    },
  ],
];

interface Props {
  propertyId?: number;
  viewedAsCustomer?: boolean;
  onClose?: () => void;
}

export const PropertyInfo: FC<Props> = ({ onClose, propertyId, viewedAsCustomer = false }) => {
  const [entry, setEntry] = useState<Property>(Property.create());
  const [user, setUser] = useState<User>();
  const [userID, setUserID] = useState<number | undefined>();
  const [loggedUser, setLoggedUser] = useState<User>(User.create());
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [notificationEditing, setNotificationEditing] = useState<boolean>(false);
  const [notificationViewing, setNotificationViewing] = useState<boolean>(false);
  //const [, setLinksViewing] = useState<boolean>(false);
  const [changingOwner, setChangingOwner] = useState<boolean>(false);
  const [pendingChangeOwner, setPendingChangeOwner] = useState<User>();
  const [merging, setMerging] = useState<boolean>(false);
  const [docsRefresh, setDocsRefresh] = useState<number>(0);
  const [pendingMerge, setPendingMerge] = useState<Property & { __user: User }>();
  const [isActivityOpen, setIsActivityOpen] = useState<boolean>(false);

  const auth = useAuth();
  const loggedUserId = auth.user.id;

  const params = useParams();
  if (params.userId) {
    setUserID(parseInt(params.userId));
  }
  if (params.propertyId) {
    propertyId = parseInt(params.propertyId);
  }

  const handleSetEditing = useCallback(
    (editing: boolean) => () => setEditing(editing),
    [setEditing],
  );

  const handleSetDeleting = useCallback(
    (deleting: boolean) => () => setDeleting(deleting),
    [setDeleting],
  );

  const handleSetNotificationEditing = useCallback(
    (notificationEditing: boolean) => () => setNotificationEditing(notificationEditing),
    [setNotificationEditing],
  );

  const handleSetNotificationViewing = useCallback(
    (notificationViewing: boolean) => () => setNotificationViewing(notificationViewing),
    [setNotificationViewing],
  );
  /*
  const handleSetLinksViewing = useCallback(
    (linksViewing: boolean) => () => setLinksViewing(linksViewing),
    [setLinksViewing],
  );
*/
  const handleSetChangingOwner = useCallback(
    (changingOwner: boolean) => () => setChangingOwner(changingOwner),
    [setChangingOwner],
  );

  const handleSetPendingChangeOwner = useCallback(
    (pendingChangeOwner: User) => {
      setPendingChangeOwner(pendingChangeOwner);
    },
    [setPendingChangeOwner],
  );

  const handleSetMerging = useCallback(
    (merging: boolean) => () => setMerging(merging),
    [setMerging],
  );

  const load = useCallback(async () => {
    if (propertyId && loggedUserId) {
      setLoading(true);
      const loggedUser = await UserClientService.loadUserById(loggedUserId);
      setLoggedUser(loggedUser);
      const req = Property.create();
      req.id = propertyId;
      req.isActive = 1;
      try {
        const results = await PropertyClientService.Get(req);

        if (!userID) {
          const user = await UserClientService.loadUserById(results?.userId || 0);
          setUser(user);
          // userID = user.id;
        } else {
          const user = await UserClientService.loadUserById(userID);
          setUser(user);
        }
        if (results) {
          // results.firstname = user!.firstname;
          // results.lastname = user!.lastname;
          // results.email = user!.email;
          // results.phone = user!.phone;
          // results.businessname = user!.businessname;
          const entry = results;
          setEntry(entry);
          setLoading(false);
          return entry;
        }
        setLoading(false);
        return null;
      } catch (e) {
        console.log({ e });
        setEntry(Property.create({ id: propertyId, userId: userID }));
        setLoading(false);
        setError(true);
      }
      setLoading(false);
      return null;
    }
  }, [setLoading, userID, propertyId, loggedUserId, setEntry, setError, setUser]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!entry.id && !entry.userId) {
      load();
    }
    if (!viewedAsCustomer && entry.notification !== '') {
      setNotificationViewing(true);
    }
  }, [entry, load, setNotificationViewing, viewedAsCustomer]);

  const handleCreateActivity = useCallback(
    async (message: string) => {
      const actLog = ActivityLog.create();
      actLog.userId = loggedUserId || 0;
      actLog.propertyId = propertyId || 0;
      actLog.activityName = message;
      actLog.activityDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
      await ActivityLogClientService.Create(actLog);
    },
    [propertyId, loggedUserId],
  );

  const handleSaveNotification = useCallback(
    async (data: ProptertyNotification) => {
      setSaving(true);
      const isNew = !!entry.notification;
      const temp = entry;
      temp.notification = data.notification;
      temp.fieldMask = ['Notification'];
      temp.id = data.id;
      if (temp) {
        const entry = await PropertyClientService.Update(temp);
        setEntry(entry!);
        setSaving(false);
        setEditing(false);
        setNotificationEditing(false);
        const message = !data
          ? 'Delete notification'
          : !isNew
            ? 'Added notification'
            : 'Edited notification';
        handleCreateActivity(message);
      } else {
        setSaving(false);
        setEditing(false);
        setNotificationEditing(false);
      }
    },
    [setSaving, entry, handleCreateActivity, setEntry, setEditing, setNotificationEditing],
  );
  const handleDelete = useCallback(async () => {
    // TODO: delete customer related data + redirect somewhere?
    const entry = Property.create();
    entry.id = propertyId || 0;
    const actLog = ActivityLog.create();
    actLog.userId = userID || 0;
    actLog.propertyId = propertyId || 0;
    actLog.activityName = `Deleted Property : ${propertyId}`;
    actLog.activityDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    try {
      await PropertyClientService.Delete(entry);
      await ActivityLogClientService.Create(actLog);
      setEntry(Property.create());
    } catch (err) {
      console.error(err);
    }
    setDeleting(false);
  }, [propertyId, setDeleting, userID]);

  const handleChangeOwner = useCallback(async () => {
    if (pendingChangeOwner) {
      const id = pendingChangeOwner.id;
      setPendingChangeOwner(undefined);
      setError(false);
      const entry = Property.create();
      entry.id = propertyId || 0;
      entry.userId = id;
      entry.fieldMask = ['UserId'];
      try {
        // TODO:
        await PropertyClientService.Update(entry); // FIXME: for some reason this call fails
        navigate(`/customers/${id}`);
      } catch (e) {
        setError(true);
      }
    }
  }, [pendingChangeOwner, propertyId, navigate]);

  const handleMerge = useCallback(async () => {
    if (pendingMerge) {
      setPendingMerge(undefined);
      // TODO: merge property modal
      window.open(
        [
          '/index.cfm?action=admin:properties.mergeproperty',
          `oldPropertyId=${propertyId}`,
          `newPropertyId=${pendingMerge.id}`,
          `newOwnerId=${pendingMerge.__user.id}`,
        ].join('&'),
      );
    }
  }, [pendingMerge, setPendingMerge, propertyId]);
  if (entry.id === 0 && entry.userId == 0)
    return (
      <SectionBar title="Property Information">
        <InfoTable loading={loading} data={loading ? makeFakeRows() : []} />
      </SectionBar>
    );

  const data: Data = [
    ...(viewedAsCustomer
      ? []
      : ([
          [
            {
              label: 'Name',
              value: `${entry.firstname} ${entry.lastname}`,
            },
            { label: 'Business Name', value: entry.businessname },
          ],
          [
            { label: 'Primary Phone', value: entry.phone, href: 'tel' },
            {
              label: 'Alternate Phone',
              value: entry.altphone,
              href: 'tel',
            },
          ],
          [{ label: 'Email', value: entry.email, href: 'mailto' }],
        ] as Data)),
    [
      {
        label: 'Address',
        value: `${entry.address}, ${entry.city}, ${entry.state} ${entry.zip}`,
      },
    ],
    [
      { label: 'Directions', value: entry.directions },
      { label: 'Subdivision', value: entry.subdivision },
    ],
    [{ label: 'Notes', value: entry.notes }],
  ];

  return (
    <>
      <div className="grid grid-cols-1 gap-2 lg:grid-cols-[1fr_470px] lg:gap-4">
        <div>
          <div className="flex flex-wrap items-center justify-between gap-2 bg-gray-200 p-2">
            <h2 className="text-2xl">Property Information</h2>
            <div className="flex flex-wrap items-center gap-2">
              {viewedAsCustomer ? (
                <>
                  <Button size="sm" onClick={handleSetEditing(true)}>
                    Edit Property
                  </Button>
                  {onClose && (
                    <Button size="sm" onClick={onClose}>
                      Close
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    size="sm"
                    className="flex items-center gap-1"
                    onClick={
                      entry.notification
                        ? handleSetNotificationViewing(true)
                        : handleSetNotificationEditing(true)
                    }
                  >
                    <Bell size={15} />
                    <span>{entry.notification ? 'Notification' : 'Add Notification'}</span>
                  </Button>

                  <Button className="flex items-center gap-1" asChild size="sm">
                    <Link to={`/customers/${entry.userId}`} target="_blank">
                      <UserRoundSearch size={15} />
                      <span>Owner Details</span>
                    </Link>
                  </Button>

                  <DropdownMenu modal={false}>
                    <DropdownMenuTrigger asChild>
                      <Button size="icon">
                        <DotsHorizontalIcon className="size-4" />
                        <span className="sr-only">Open actions menu</span>
                      </Button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent>
                      <DropdownMenuItem
                        onClick={handleSetEditing(true)}
                        className="flex items-center gap-2"
                      >
                        <Pencil1Icon />
                        <span>Edit Property</span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        onClick={handleSetDeleting(true)}
                        className="flex items-center gap-2"
                      >
                        <TrashIcon />
                        <span>Delete Property</span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        onClick={handleSetMerging(true)}
                        className="flex items-center gap-2"
                      >
                        <GitMerge size={15} />
                        <span>Merge Property</span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        onClick={handleSetChangingOwner(true)}
                        className="flex items-center gap-2"
                      >
                        <UserPen size={15} />
                        <span>Change Owner</span>
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        onClick={() => setIsActivityOpen(true)}
                        className="flex items-center gap-2"
                      >
                        <Activity size={15} />
                        <span>Activity</span>
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </>
              )}
            </div>
          </div>
          <InfoTable data={data} loading={loading} error={error} />
        </div>

        <PropertyDocuments
          loggedUserId={loggedUser.id}
          propertyId={entry.id}
          userID={user!.id}
          docsRefresh={docsRefresh}
          viewedAsCustomer={false}
        />

        <div className="lg:col-span-2">
          <ServiceItems propertyId={propertyId || 0} />
        </div>
      </div>
      <div className="mb-32">
        <ServiceCalls
          propertyId={propertyId || 0}
          userID={user?.id || 0}
          onCreateDoc={() => setDocsRefresh((prev) => prev + 1)}
        />
      </div>
      <Modal
        open={isActivityOpen}
        className="!h-svh !w-screen lg:!h-[80vh] lg:!w-[60vw]"
        onClose={() => {
          setIsActivityOpen(false);
        }}
      >
        <PropertyActivity propertyId={propertyId} onClose={() => setIsActivityOpen(false)} />
      </Modal>
      <Modal open={editing} onClose={handleSetEditing(false)}>
        <PropertyEdit
          userId={userID || 0}
          propertyId={propertyId}
          onClose={handleSetEditing(false)}
          onSave={(entry) => {
            setEntry(entry);
            setEditing(false);
            setNotificationEditing(false);
          }}
          property={entry}
        />
      </Modal>
      <Modal
        open={notificationEditing || notificationViewing}
        onClose={() => {
          handleSetNotificationViewing(false)();
          handleSetNotificationEditing(false)();
        }}
      >
        <Form<ProptertyNotification>
          title={
            notificationViewing
              ? 'Property Notification'
              : `${entry.notification === '' ? 'Add' : 'Edit'} Property Notification`
          }
          schema={SCHEMA_PROPERTY_NOTIFICATION}
          data={{ notification: entry.notification, id: propertyId || 0 }}
          onSave={handleSaveNotification}
          onClose={() => {
            handleSetNotificationViewing(false)();
            handleSetNotificationEditing(false)();
          }}
          disabled={saving}
          readOnly={notificationViewing}
          actions={
            notificationViewing
              ? [
                  {
                    label: 'Edit',
                    variant: 'outlined',
                    onClick: () => {
                      handleSetNotificationViewing(false)();
                      handleSetNotificationEditing(true)();
                    },
                  },
                ]
              : entry.notification != ''
                ? [
                    {
                      label: 'Delete',
                      variant: 'outlined',
                      onClick: () => {
                        handleSetNotificationViewing(false)();
                        const newProp = { notification: '', id: propertyId || 0 };
                        handleSaveNotification(newProp);
                      },
                    },
                  ]
                : []
          }
        />
      </Modal>

      {/*<Modal open={linksViewing} onClose={handleSetLinksViewing(false)}>
        <ServiceItemLinks
          kind="Property Information Link"
          serviceItemId={propertyId || 0}
          onClose={handleSetLinksViewing(false)}
        />
        </Modal>*/}
      <ConfirmDelete
        open={deleting}
        onClose={handleSetDeleting(false)}
        onConfirm={handleDelete}
        kind="Property Information"
        name={`${entry.firstname} ${entry.lastname}`}
      />
      <Search
        kinds={['Customers']}
        kind="Customers"
        open={changingOwner}
        onClose={handleSetChangingOwner(false)}
        onSelect={(data) => handleSetPendingChangeOwner(data as User)}
        excludeId={userID}
      />
      <Search
        kinds={['Properties']}
        open={merging}
        kind="Properties"
        onClose={handleSetMerging(false)}
        onSelect={(handle) => console.log(handle)}
        excludeId={userID}
      />
      {pendingChangeOwner && (
        <Confirm
          open
          title="Confirm"
          onClose={() => setPendingChangeOwner(undefined)}
          onConfirm={handleChangeOwner}
        >
          Are you sure you want to move this property to{' '}
          <strong>
            {pendingChangeOwner.firstname} {pendingChangeOwner.lastname}
          </strong>
          ?
        </Confirm>
      )}
      {pendingMerge && user && (
        <Confirm
          open
          title="Confirm"
          onClose={() => setPendingMerge(undefined)}
          onConfirm={handleMerge}
        >
          Are you sure you want to remove all information from{' '}
          <strong>
            {entry.address}, {entry.city}, {entry.state} {entry.zip}
          </strong>
          , under <strong>{user.businessname || `${user.firstname} ${user.lastname}`}</strong>, and
          merge it into{' '}
          <strong>
            {pendingMerge.address}, {pendingMerge.city}, {pendingMerge.state} {pendingMerge.zip}
          </strong>
          , under{' '}
          <strong>
            {pendingMerge.__user.businessname ||
              `${pendingMerge.__user.firstname} ${pendingMerge.__user.lastname}`}
          </strong>
          ?
        </Confirm>
      )}
    </>
  );
};
