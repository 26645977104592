import { Button, toast } from '@kalos/ui';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { CheckIcon, CopyIcon } from 'lucide-react';
import { useState } from 'react';

import { outsideAuthSignout, outsideAuthUpdateToken } from '../App';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';

const handleAuthIssue = async () => {
  try {
    await outsideAuthUpdateToken?.();
    return true;
  } catch (err) {
    outsideAuthSignout?.();
    // then in RequireAuth component user will be redirected to login page
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      gcTime: 20 * 1000, // 20 sec
      retry(failureCount) {
        const shouldRetry = failureCount <= 1;
        return shouldRetry;
      },
    },
    mutations: {
      async onError(error) {
        if (error instanceof RpcError && error.code === 'UNAUTHENTICATED') {
          console.log('Handling unauthenticated error - signing off', error);
          await handleAuthIssue();
        } else {
          console.log('Unexpected Error from mutation', { error });
        }
      },
    },
  },
  queryCache: new QueryCache({
    async onError(error, query) {
      console.log('onError queryCache', { error, query });
      if (error instanceof RpcError && error.code === 'UNAUTHENTICATED') {
        if (await handleAuthIssue()) {
          query.fetch().catch(console.log);
        }
      } else {
        console.log('Unexpected Error from query', { error, query });
        if (error instanceof RpcError) {
          const location = [error.serviceName, error.methodName].filter(Boolean).join('.');
          const fullError = [location, error.message, error.code, error.cause, error.stack]
            .filter(Boolean)
            .join(' - ');
          toast({
            title: 'Unexpected request error',
            description: (
              <div className="space-y-2 text-xs">
                {location && <p className="font-bold ">{location}</p>}
                <p className="text-sm font-bold">{error.message}</p>
                <p className="text-sm font-bold">Code: {error.code}</p>
                <p>PLease contact engineering support with this message</p>
                <CopyButton text={fullError} />
              </div>
            ),
            variant: 'destructive',
            duration: 15_000,
          });
        }
      }
    },
  }),
});

const CopyButton = ({ text }: { text: string }) => {
  const [, copy] = useCopyToClipboard();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleCopy = async () => {
    if (await copy(text)) {
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 2000);
    } else {
      toast({ variant: 'destructive', title: 'Failed to copy' });
    }
  };

  return (
    <Button
      onClick={handleCopy}
      variant="secondary"
      size="sm"
      className="flex items-center gap-0.5"
    >
      {isSuccess ? (
        <CheckIcon size={15} className="text-green-500" />
      ) : (
        <>
          <div>Copy error message</div> <CopyIcon size={15} />
        </>
      )}
    </Button>
  );
};
