import './ContractInfo.module.less';

import { getCFAppUrl } from '../../../tools/helpers';
import { Documents } from '../../ComponentsLibrary/Documents';

interface Props {
  className?: string;
  userID: number;
  contractID?: number;
  docsRefresh?: number;
}

export const ContractDocuments = (props: Props) => {
  const { className, userID, contractID } = props;
  return (
    <div
      className={`${className} flex flex-col overflow-hidden rounded-lg border-2 border-gray-300`}
    >
      <div className="border-b-2 border-gray-300 bg-gray-100 p-2 font-bold">Documents</div>
      <div className="relative flex max-h-[600px]">
        <div className="scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 hover:scrollbar-thumb-gray-500 mr-2 w-2 overflow-y-auto">
          <div className="h-[1200px] w-full"></div>
        </div>
        <div className="flex-1 overflow-y-auto">
          <Documents
            userId={userID}
            docsRefresh={props.docsRefresh}
            fieldMask={['PropertyId']}
            contractId={contractID || 0}
            title="Documents"
            addUrl={[
              getCFAppUrl('admin:contracts.docaddS3'), //TODO: Replace with modal
              `user_id=${userID}`,
              ...(contractID ? [`contract_id=${contractID}`] : []),
              'p=1',
            ].join('&')}
          />
        </div>
        <div className="pointer-events-none absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-gray-200 to-transparent"></div>
      </div>
    </div>
  );
};
