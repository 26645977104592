import { Contract } from '@kalos/kalos-rpc';
import { Button } from '@kalos/ui';
import { useQueryClient } from '@tanstack/react-query';
import { type FC, useCallback, useMemo, useState } from 'react';

import { useAuth } from '../../../context/AuthContext';
import { queryKeys } from '../../../hooks/react-query/constants';
import { useContractFrequencyGetQuery } from '../../../hooks/react-query/useContractFrequencyQuery';
import {
  useContractQuery,
  useDeleteContractByIdMutation,
} from '../../../hooks/react-query/useContractsQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import { ContractClientService, formatDate, getCFAppUrl } from '../../../tools/helpers';
import { Confirm } from '../../ComponentsLibrary/Confirm';
import { ConfirmDelete } from '../../ComponentsLibrary/ConfirmDelete';
import { EditContractInfo } from '../../ComponentsLibrary/EditContractInfo';
import { type Options } from '../../ComponentsLibrary/Form';
import { type Data, InfoTable } from '../../ComponentsLibrary/InfoTable';
import { Modal } from '../../ComponentsLibrary/Modal';
import { ContractDocuments } from './ContractDocuments';
import { ContractMaterials } from './ContractMaterial';
export const PAYMENT_TYPE_OPTIONS: Options = [
  'Cash',
  'Check',
  'Credit Card',
  'Paypal',
  'Stripe',
  'Billing',
  'Financing',
  'AOR Warranty',
  'Service Warranty',
  'Extended Warranty',
  'Pre-Paid',
  'No Charge',
  'Account Transfer',
  'Charity',
];

export const PAYMENT_STATUS_OPTIONS = ['Pending', 'Billed', 'Canceled', 'Paid'];

interface Props {
  userID: number;
  children?: React.ReactNode;
}

export const ContractInfo: FC<Props> = ({ children, userID }) => {
  const currentUserID = useAuth().user.id;
  const currentUserQuery = useUserQuery({
    filters: {
      id: currentUserID,
    },
    select(data) {
      return {
        ...data,
        isNewContractUser: !!data.permissionGroups.find((perm) => perm.name == 'NewContractUser'),
      };
    },
  });

  const [editing, setEditing] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [confirmNew, setConfirmNew] = useState<boolean>(false);
  const [confirmNewNew, setConfirmNewNew] = useState<boolean>(false);
  const [materialOpen, setMaterialOpen] = useState<boolean>(false);
  const [docsRefresh, setDocsRefresh] = useState<number>(0);

  const contractQueryFilter = useMemo(() => {
    return {
      userId: userID,
      isActive: 1,
      orderBy: 'contract_date_created',
      orderDir: 'desc',
    };
  }, [userID]);
  const { mutateAsync: deleteContractAsync } = useDeleteContractByIdMutation();

  const contractQuery = useContractQuery({
    enabled: true,
    filter: contractQueryFilter,
  });

  const queryClient = useQueryClient();

  const contractFrequencyQuery = useContractFrequencyGetQuery({
    filter: {
      id: contractQuery.data?.frequency ?? 0,
    },
    enabled: contractQuery.isSuccess && !!contractQuery.data?.frequency,
  });
  const handleToggleEditing = useCallback(() => {
    setEditing((editing) => !editing);
  }, []);

  const handleToggleMaterial = useCallback(() => {
    setMaterialOpen((isOpen) => !isOpen);
  }, [setMaterialOpen]);

  const handleSetDeleting = useCallback(
    (deleting: boolean) => () => setDeleting(deleting),
    [setDeleting],
  );
  const handleDelete = useCallback(async () => {
    if (!contractQuery.data) {
      return;
    }

    try {
      await deleteContractAsync(contractQuery.data.id);
    } catch {
      console.log('Failed to Delete contract');
      return;
    }

    // Check Database for other Contracts that were never deactivated
    const oldContractsQuery = Contract.create();
    oldContractsQuery.isActive = 1;
    oldContractsQuery.userId = contractQuery.data.userId;
    try {
      const contracts = await ContractClientService.BatchGet(oldContractsQuery);
      if (contracts?.results.length) {
        const deleteStatuses = await Promise.allSettled([
          contracts.results.map((contract) => deleteContractAsync(contract.id)),
        ]);
        deleteStatuses.forEach((deleteResult) => {
          if (deleteResult.status === 'rejected') {
            console.log('Failed to delete previous contract');
          }
        });
      }
    } catch (err) {
      console.error(`Failed to delete old records: ${err}`);
    }

    setDeleting(false);

    queryClient.invalidateQueries({ queryKey: [queryKeys.contracts.root] });
    queryClient.invalidateQueries({ queryKey: [queryKeys.invoice.root] });
  }, [contractQuery.data, deleteContractAsync, queryClient]);

  const handleSetConfirmNew = useCallback(
    (confirmNew: boolean) => () => setConfirmNew(confirmNew),
    [setConfirmNew],
  );

  const handleSetConfirmNewNew = useCallback(
    (confirmNew: boolean) => () => setConfirmNewNew(confirmNew),
    [setConfirmNewNew],
  );

  const handleNewContract = useCallback(async () => {
    document.location.href = [
      getCFAppUrl('admin:contracts.contractnew'),
      `contract_id=${contractQuery.data?.id}`,
    ].join('&');
  }, [contractQuery.data?.id]);

  const handleNewContractNew = useCallback(async () => {
    await handleDelete();
    setConfirmNewNew(false);
    handleToggleEditing();
  }, [handleDelete, handleToggleEditing]);

  const data: Data = [
    [
      { label: 'Contract Number', value: contractQuery.data?.number },
      {
        label: 'Billing',
        value: contractQuery.data?.groupBilling === 1 ? 'Group' : 'Site',
      },
    ],
    [
      {
        label: 'Start Date',
        value: contractQuery.data ? formatDate(contractQuery.data?.dateStarted) : '',
      },
      { label: 'Payment Type', value: contractQuery.data?.paymentType },
    ],
    [
      {
        label: 'End Date',
        value: contractQuery.data ? formatDate(contractQuery.data?.dateEnded) : '',
      },
      { label: 'Payment Status', value: contractQuery.data?.paymentStatus },
    ],
    [
      { label: 'Frequency', value: contractFrequencyQuery.data?.name ?? '' },
      { label: 'Payment Terms', value: contractQuery.data?.paymentTerms },
    ],
    [{ label: 'Notes', value: contractQuery.data?.notes }],
  ];

  return (
    <>
      {editing && contractQuery.data && (
        <Modal
          open={true}
          onClose={() => handleToggleEditing()}
          fullScreen
          className="Modal-Remove-Scroll"
        >
          <EditContractInfo
            userID={userID}
            contractID={contractQuery.data.id}
            onSaveFinished={() => {
              Promise.allSettled([
                queryClient.invalidateQueries({ queryKey: [queryKeys.contracts.root] }),
                queryClient.invalidateQueries({ queryKey: [queryKeys.invoice.root] }),
              ]).then(() => {
                setDocsRefresh((prev) => prev + 1);
              });
              handleToggleEditing();
            }}
            onClose={() => handleToggleEditing()}
          />
        </Modal>
      )}
      <Modal
        open={materialOpen}
        className="Modal-Remove-Scroll"
        onClose={handleToggleMaterial}
        styles={{ width: window.innerWidth * 0.65 }}
      >
        <ContractMaterials
          contractID={contractQuery.data?.id ?? 0}
          onClose={handleToggleMaterial}
        />
      </Modal>
      <div className="flex flex-col lg:flex-row lg:gap-4">
        <div className="mb-4 flex-1 rounded-md border border-gray-300 lg:mb-0">
          <div className="flex items-center justify-between bg-gray-200 p-2">
            <h2 className="text-2xl">Contract Info</h2>
            <div className="flex flex-wrap gap-2">
              {!currentUserQuery.isSuccess ? null : currentUserQuery.data.isNewContractUser ? (
                contractQuery.data?.id === 0 ? (
                  <Button
                    onClick={handleToggleEditing}
                    disabled={!currentUserQuery.data?.isNewContractUser}
                    size="sm"
                  >
                    Add Contract
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={handleToggleEditing}
                      disabled={!currentUserQuery.data?.isNewContractUser}
                      size="sm"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={handleToggleMaterial}
                      disabled={!currentUserQuery.data?.isNewContractUser}
                      size="sm"
                    >
                      Materials
                    </Button>
                    <Button
                      onClick={handleSetDeleting(true)}
                      disabled={!currentUserQuery.data?.isNewContractUser}
                      size="sm"
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={handleSetConfirmNewNew(true)}
                      disabled={!currentUserQuery.data?.isNewContractUser}
                      size="sm"
                    >
                      New
                    </Button>
                  </>
                )
              ) : null}
            </div>
          </div>
          {contractQuery.data?.id === 0 ? (
            <div className="p-4">No contract information available.</div>
          ) : (
            <InfoTable data={data} loading={contractQuery.isPending} />
          )}
          {children}
        </div>
        <div className="w-full rounded-md border border-gray-300 lg:w-[470px]">
          <ContractDocuments
            contractID={contractQuery.data?.id}
            userID={userID}
            docsRefresh={docsRefresh}
          />
        </div>
      </div>
      <ConfirmDelete
        open={deleting}
        onClose={handleSetDeleting(false)}
        onConfirm={handleDelete}
        kind="Contract"
        name={`${contractQuery.data?.number}`}
      />
      <Confirm
        title="Confirm New Contract"
        open={confirmNew}
        onClose={handleSetConfirmNew(false)}
        onConfirm={() => handleNewContract()}
      >
        Are you sure you want to create a new contract? This will replace the old one (documents
        will be re-emailed).
      </Confirm>
      <Confirm
        title="Confirm New Contract"
        open={confirmNewNew}
        onClose={handleSetConfirmNewNew(false)}
        onConfirm={() => handleNewContractNew()}
      >
        Are you sure you want to create a new contract? This will replace the old one (documents
        will be re-emailed).
      </Confirm>
    </>
  );
};
