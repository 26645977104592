import { SpiffToolAdminAction, SQSEmail, Task, User } from '@kalos/kalos-rpc';
import { format, parseISO } from 'date-fns';
import { useCallback } from 'react';

import { useAuth } from '../../../../context/AuthContext';
import {
  useSpiffAdminActionCreateMutation,
  useSpiffAdminActionUpdateMutation,
  useTaskUpdateMutation,
} from '../../../../hooks/react-query/useTaskClientServiceQuery';
import { useUserQuery } from '../../../../hooks/react-query/useUserQuery';
import { EmailClientService, timestamp, UserClientService } from '../../../../tools/helpers';
import { type SpiffToolAdminActionFormSchema } from './SpiffToolAdminActionForm';

export const useCreateOrUpdateSpiffToolAdminAction = ({ parentTask }: { parentTask: Task }) => {
  const updateTaskMutation = useTaskUpdateMutation();

  const updateAdminTaskActionMutation = useSpiffAdminActionUpdateMutation();
  const createAdminTaskActionMutation = useSpiffAdminActionCreateMutation();
  const loggedUser = useAuth().user;

  const taskUserQuery = useUserQuery({
    filters: {
      id: parentTask.externalId,
    },
  });

  const createEmail = useCallback(
    async (data: Task, newAction: SpiffToolAdminAction) => {
      if (!taskUserQuery.isSuccess) return;
      console.log('hi, you sent an email homie');
      let emailStatus = 'Approved';
      const status = newAction.status;
      if (status === 2) {
        emailStatus = 'Declined';
      }
      if (status === 3) {
        emailStatus = 'Revoked';
      }
      const body = `<body>

      <table cellpadding="0" cellspacing="0" class="main-table" style="max-width:600px; margin:auto; border:1px solid #ddd; background-color:#f1f1f1;" align="center">
       <tr>
      <td height="12"></td>
    </tr>
    <tr>
      <td>
      </td>
    </tr>

      <tr>
        <td height="10" colspan="3"></td>
      </tr>
      <tr>
        <td width="2%"></td>
        <td width="96%">
          <table style="width:100%; margin:auto; background-color:#fff;" cellpadding="0" cellspacing="0">
            <tr>
              <td width="2%"></td>
              <td width="96%">
                <table style="width:100%;" cellpadding="0" cellspacing="0">
                  <tr>
                    <td height="12"></td>
                  </tr>
                  <a><img src='https://kalos-web-assets.s3.amazonaws.com/kalos-logo-new.png'	alt="email-logo" style="display:block; background-color: #711313"/></a>

                  <tr>
                    <td height="23"></td>
                  </tr>
                  <tr>
                    <td height="22"></td>
                  </tr>
                  <tr>
                    <td>
                      <h3 style="font-size:20px; color:#4d4d4d; font-family:Arial, Helvetica, sans-serif; margin:0;">
                        Hello ${taskUserQuery.data.firstname} ${taskUserQuery.data.lastname}
                      </h3>
                    </td>
                  </tr>
                  <tr>
                    <td height="14"></td>
                  </tr>
                  <tr>
                    <td>
                      <p style="margin:0; font-size:14px; color:#262626; line-height:20px; font-family:Arial, Helvetica, 'sans-serif';">
                        Your request for your Spiff has been <b>${emailStatus}</b>.<br/>Please see details below
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td height="10"></td>
                  </tr>
                  <tr>
                    <td colspan="3" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:14px; line-height:20px; font-weight:bold;">${
                      taskUserQuery.data.firstname
                    } ${taskUserQuery.data.lastname} : Spiff Request</td>
                   </tr>
                   <tr>
                   <td colspan="3" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:14px; line-height:20px; font-weight:bold;">Amount:$ ${
                     data.spiffAmount
                   }</td>

                  </tr>
                  <tr>
                    <td height="10"></td>
                  </tr>
                  <tr>
                    <td>
                      <table style="width:100%;" cellpadding="1" cellspacing="1">
                        <tr>
                          <th valign="top" width="21%" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:13px; line-height:20px;" align="left" width="28%">Decision Date</th>
                          <th valign="top" width="22%" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:13px; line-height:20px;" align="left" width="24%">Reviewed By</th>
                          ${data.spiffJobNumber}
                          <th valign="top" width="13%" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:13px; line-height:20px;" align="left">Status</th>
                         </tr>
                        <tr>
                          <td valign="top" style="font-fdamily:Arial, Helvetica, sans-serif; color:#000; font-size:12px; line-height:20px;"> ${format(
                            parseISO(newAction.decisionDate),
                            'yyyy-MM-dd',
                          )} </td>
                          <td valign="top" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:12px; line-height:20px;">${
                            newAction.reviewedBy
                          }</td>

                          <td valign="top" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:12px; line-height:20px;">${emailStatus}</td>
                          <td valign="top" style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:12px; line-height:20px;"></td>
                         </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="18"></td>
                  </tr>
                  <tr>

                  <tr style="font-family:Arial, Helvetica, sans-serif; color:#000; font-size:12px; line-height:20px;">
                  <td>
                  <h3>Reason</h3>
                  <p>${newAction.reason ?? 'N/A'}</p>
                  </td>
                  </tr>

                  </tr>
                    <tr>
                    <td height="18"></td>
                  </tr>
                </table>
              </td>
              <td width="2%"></td>
            </tr>
          </table>
        </td>
        <td width="2%"></td>
      </tr>
      <tr>
        <td height="13" colspan="3"></td>
      </tr>
      <tr>
        <td width="2%"></td>
        <td width="96%">
             <table style="width:100%; border-top:1px solid #d8d8d8;" cellpadding="0" cellspacing="0">
            <tr>
              <td height="10" colspan="2"></td>
            </tr>
            <tr>
              <td width="80%" align="right" valign="middle">
                <span style="font-size:10px; color:#595959; font-family:Arial, Helvetica, sans-serif; font-weight:300; line-height:13px;">
                  KALOS SERVICES INC. 236 HATTERAS AVE CLERMONT FL 34711 &nbsp; 352.243.7088 <br />
                  Copyright 2022 Kalos Service Inc. All Rights Reserved.
                </span>
              </td>
              <td width="20%" align="right">
              </td>
            </tr>
          </table>
        </td>
        <td width="2%"></td>
      </tr>
      <tr>
        <td height="30" colspan="3"></td>
      </tr>
    </table>
    </body>`;

      const email = SQSEmail.create();
      email.body = body;
      email.subject = `Spiff ${data.spiffJobNumber} Update`;
      email.to = taskUserQuery.data.email;
      if (email.to == '') {
        const taskUserReq = User.create();
        taskUserReq.id = data.externalId;
        const taskUserInfo = await UserClientService.Get(taskUserReq);
        email.to = taskUserInfo.email;
        console.log('we did not have an email, we had to fetch it', taskUserInfo.email);
      }
      try {
        await EmailClientService.SendSQSMail(email);
        console.log('we sent', email);
      } catch (e) {
        console.log('could not send email ', e);
      }
    },
    [taskUserQuery.data, taskUserQuery.isSuccess],
  );

  type CreateOrUpdateSpiffToolAdminActionArgs = {
    adminAction: SpiffToolAdminActionFormSchema;
  } & ({ type: 'Update'; originalId: SpiffToolAdminAction['id'] } | { type: 'Create' });

  return useCallback(
    async ({ adminAction, type, ...rest }: CreateOrUpdateSpiffToolAdminActionArgs) => {
      const newReviewedBy = loggedUser.firstname + ' ' + loggedUser.lastname;
      const timestampValue = timestamp(true, adminAction.decisionDate);
      const adminActionNew = SpiffToolAdminAction.create({
        id: type === 'Update' && 'originalId' in rest ? rest.originalId : 0,
        taskId: parentTask.id,
        reviewedBy: newReviewedBy,
        decisionDate: timestampValue,
        reason: adminAction.reason,
        status: adminAction.status,
        fieldMask: ['DecisionDate', 'Status', 'Reason'],
      });

      adminActionNew.reviewedBy = newReviewedBy;
      adminActionNew.decisionDate = timestampValue;
      adminActionNew.fieldMask.push('DecisionDate');

      if (adminActionNew.status === 0) {
        adminActionNew.status = 1;
        //because of the safe form object, we are not getting the default status, so in this instance 0===1
      }
      if (adminActionNew.status === 1) {
        adminActionNew.grantedDate = timestampValue;
        console.log('granted');
        adminActionNew.fieldMask.push('GrantedDate');
      }
      if (adminActionNew.status === 3) {
        console.log('revoked');
        adminActionNew.revokedDate = timestampValue;
        adminActionNew.fieldMask.push('RevokedDate');
      }
      if (adminActionNew.reason === null || adminActionNew.reason === '') {
        adminActionNew.reason = 'No Reason Given';
      }
      let createdActionId = 0;
      if (type === 'Create') {
        createdActionId = (await createAdminTaskActionMutation.mutateAsync(adminActionNew!)).id;
      } else {
        createdActionId = (await updateAdminTaskActionMutation.mutateAsync(adminActionNew)).id;
      }
      Promise.allSettled([createEmail(parentTask, adminActionNew)]);
      //this was used to update the record, we should replace this with an insert trigger
      const updateTask = Task.create({ fieldMask: ['AdminActionId'] });
      updateTask.id = parentTask.id;
      updateTask.adminActionId = createdActionId;
      updateTask.spiffToolCloseoutDate = timestamp();

      await updateTaskMutation.mutateAsync(updateTask);
    },
    [
      createAdminTaskActionMutation,
      createEmail,
      loggedUser.firstname,
      loggedUser.lastname,
      parentTask,
      updateAdminTaskActionMutation,
      updateTaskMutation,
    ],
  );
};
