import { ApiKey, ApiKeyClient } from '../ApiKey';
import { BaseClient } from '../BaseClient';
import { getDepartmentName, timestamp, unique } from '../Common';
import { Double, Empty, type Int32, IntArray, String$ } from '../compiled-protos/common';
import {
  PerDiem,
  PerDiemList,
  PerDiemReportRequest,
  PerDiemRow,
  SQLRequest,
  Trip,
  TripList,
} from '../compiled-protos/perdiem';
import { PerDiemServiceClient } from '../compiled-protos/perdiem.client';
import { TimesheetDepartment } from '../compiled-protos/timesheet_department';
import { NULL_TIME } from '../constants';
import { MapClient } from '../Maps';
import { TimesheetDepartmentClient } from '../TimesheetDepartment';
class PerDiemClient extends BaseClient {
  self: PerDiemServiceClient;

  MapClient: MapClient;
  constructor(host: string, userID?: number) {
    super(host, userID);
    this.MapClient = new MapClient(host);
    this.self = new PerDiemServiceClient(this.transport);
  }

  public async Create(req: PerDiem) {
    let res = PerDiem.create();
    try {
      res = await this.self.create(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async Get(req: PerDiem) {
    let res = PerDiem.create();
    try {
      res = await this.self.get(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async Update(req: PerDiem, returnMsg = false) {
    let res = PerDiem.create();
    try {
      res = await this.self.update(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async Delete(req: PerDiem) {
    let res = PerDiem.create();
    try {
      res = await this.self.delete(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async isPerDiemAdmin(userID: number) {
    try {
      const tsc = new TimesheetDepartmentClient(this.host);
      const req = TimesheetDepartment.create();
      req.managerId = userID;
      const res = await tsc.Get(req);
      if (res!.managerId === userID) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  public async CreateRow(req: PerDiemRow) {
    let res = PerDiemRow.create();
    try {
      res = await this.self.createRow(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async GetRow(req: PerDiemRow) {
    let res = PerDiemRow.create();
    try {
      res = await this.self.getRow(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async CreateTrip(req: Trip) {
    let res = Trip.create();
    try {
      res = await this.self.createTrip(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async UpdateTrip(req: Trip) {
    let res = Trip.create();
    try {
      res = await this.self.updateTrip(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async GetTrip(req: Trip) {
    let res = Trip.create();
    try {
      res = await this.self.getTrip(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async UpdateRow(req: PerDiemRow) {
    let res = PerDiemRow.create();
    try {
      res = await this.self.updateRow(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async DeleteTrip(req: Trip) {
    let res = Empty.create();
    try {
      res = await this.self.deleteTrip(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async DeleteRow(perDiemRowID: number) {
    const req = PerDiemRow.create();
    req.id = perDiemRowID;
    let res = Empty.create();
    try {
      res = await this.self.deleteRow(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async BatchGet(req: PerDiem) {
    let res = PerDiemList.create();
    try {
      res = await this.self.batchGet(req, this.getMetaDataWithoutCache()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async BatchGetPerDiemsByIds(arr: number[]) {
    const req = IntArray.create({ iDs: arr });
    let res = PerDiemList.create();
    try {
      res = await this.self.batchGetPerDiemsByIds(req, this.getMetaData())
        .response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async BatchGetTrips(req: Trip) {
    let res = TripList.create();
    try {
      res = await this.self.batchGetTrips(req, this.getMetaDataWithoutCache()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async GetTotalRowTripDistance(req: Int32) {
    let res = Double.create();
    try {
      res = await this.self.getTotalRowTripDistance(req, this.getMetaData())
        .response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async BatchDeleteTrips(req: Trip) {
    let res = Trip.create();
    try {
      res = await this.self.batchDeleteTrips(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async getPerDiemReportData(cfg: PerDiemReportConfig) {
    const req = PerDiemReportRequest.create();
    req.departmentId = cfg.departmentIDs;
    req.userId = cfg.userIDs;
    req.week = cfg.weeks;
    let res = PerDiemList.create();
    try {
      res = await this.self.getPerDiemReport(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public async updatePerDiemNeedsAudit(id: number) {
    const req = PerDiem.create();
    req.id = id;
    req.needsAuditing = false;
    req.fieldMask = ['NeedsAuditing'];
    await this.Update(req);
  }

  public async updatePerDiemPayrollProcessed(id: number) {
    const req = PerDiem.create();
    req.id = id;
    req.payrollProcessed = true;
    req.fieldMask = ['PayrollProcessed'];
    await this.Update(req);
  }

  public async updateTripPayrollProcessed(id: number) {
    const req = Trip.create();
    req.id = id;
    req.payrollProcessed = true;
    req.dateProcessed = timestamp();
    req.fieldMask = ['PayrollProcessed', 'DateProcessed'];
    await this.UpdateTrip(req);
  }

  public async updateTripApproved(id: number) {
    const req = Trip.create();
    req.id = id;
    req.approved = true;
    req.adminActionDate = timestamp();
    req.fieldMask = ['Approved', 'AdminActionDate'];
    await this.UpdateTrip(req);
  }
  public async updateTripDeny(id: number) {
    const req = Trip.create();
    req.id = id;
    req.fieldMask = ['AdminActionDate'];
    req.adminActionDate = timestamp();
    await this.UpdateTrip(req);
  }
  public async deletePerDiemById(id: number) {
    const req = PerDiem.create();
    req.id = id;
    await this.Delete(req);
  }
  public async updateTripReject(id: number) {
    const req = Trip.create();
    req.id = id;
    req.approved = false;
    req.adminActionDate = '0001-01-01 00:00:00';
    req.fieldMask = ['Approved', 'AdminActionDate'];
    await this.UpdateTrip(req);
  }
  public loadPerDiemByUserIdAndDateStarted = async (
    userId: number,
    dateStarted: string
  ) => {
    const req = PerDiem.create();
    req.userId = userId;
    req.withRows = true;
    req.isActive = true;
    req.pageNumber = 0;
    req.dateStarted = `${dateStarted}%`;
    return await this.BatchGet(req);
  };

  public loadPerDiemsByEventId = async (eventId: number) => {
    const req = PerDiem.create();
    req.withRows = true;
    req.isActive = true;
    req.pageNumber = 0;
    req.withoutLimit = true;
    const row = PerDiemRow.create();
    row.serviceCallId = eventId;
    req.referenceRow = row;
    return await this.BatchGet(req);
  };

  public loadPerDiemByUserIdAndDateStartedAudited = async (
    userId: number,
    dateEnded: string
  ) => {
    const req = PerDiem.create();
    req.userId = userId;
    req.withRows = true;
    req.isActive = true;
    req.approvedById = 0;
    req.notEquals = ['ApprovedById'];
    req.fieldMask = ['PayrollProcessed'];
    req.pageNumber = 0;
    req.dateRange = ['>=', '0001-01-01', '<', dateEnded];
    return await this.BatchGet(req);
  };

  public getTotalRowTripDistanceWithUserID = async (
    userID: number,
    rowID?: number
  ) => {
    const trip = Trip.create();
    trip.userId = userID;
    if (rowID) {
      trip.perDiemRowId = rowID;
    }
    const trips = await this.BatchGetTrips(trip);
    let totalDist = 0;
    trips.results.forEach((trip) => (totalDist += trip.distanceInMiles));

    return totalDist;
  };

  public getPerDiemsFromIds = async (ids: number[]) => {
    const list: PerDiem[] = [];
    for await (const id of ids) {
      const pd = PerDiem.create();
      pd.id = id;
      list.push(await this.Get(pd));
    }

    return list;
  };
  // Requested help on Saturday about SQLRequest - Throws an error after fixing imports at top of file - Fixed by adding req.data=data and req.requestType=type to top level scope of function
  public async getSQL(pd: PerDiem, type: 'Get' | 'BatchGet' | 'Update') {
    const req = SQLRequest.create();
    req.data = pd;
    req.requestType = type;
    let res = String$.create();
    try {
      res = await this.self.getDebugSQLOutput(req, this.getMetaData()).response;
    } catch (err) {
      console.error(err);
    }
    return res;
  }

  public loadPerDiemByUserIdsAndDateStarted = async (
    userIds: number[],
    dateStarted: string
  ) => {
    const response = await Promise.all(
      unique(userIds).map(async (userId) => ({
        userId,
        data: (
          await this.loadPerDiemByUserIdAndDateStarted(userId, dateStarted)
        ).results,
      }))
    );
    return response.reduce<Record<number, PerDiem[]>>(
      (aggr, { userId, data }) => ({ ...aggr, [userId]: data }),
      {}
    );
  };

  public getRowDatesFromPerDiemTripInfos = async (trips: TripInfo[]) => {
    const tripIds: number[] = [];
    for (const trip of trips) {
      // @ts-ignore
      tripIds.push(trip.perDiemRowId);
    }
    const res: { date: string; row_id: number }[] = [];
    for await (const id of tripIds) {
      try {
        const pd = PerDiem.create();
        pd.id = id;
        const pdr = await this.self.get(pd);
        const obj = {
          date: pdr.request.dateStarted,
          row_id: id,
        };
        if (!res.includes(obj)) res.push(obj);
      } catch (err) {
        console.error(
          'Error in promise for get row dates from per diem IDs (Verify Per Diem exists): ',
          err
        );
      }
    }

    return res;
  };

  // Needs review on line 416. Was unsure between this.perDiemRowId and this.perDiemRowID
  getRowDatesFromPerDiemTrips = async (trips: Trip[]) => {
    const tripIds: number[] = [];
    for (const trip of trips) {
      tripIds.push(trip.perDiemRowId);
    }
    const req: { date: string; row_id: number }[] = [];

    if (tripIds.length != 0) {
      const pds = await this.BatchGetPerDiemsByIds(tripIds);

      for (const perDiem of pds.results) {
        const obj = {
          date: perDiem.dateStarted,
          row_id: perDiem.id,
        };
        req.push(obj);
      }
    }
    return req;
  };

  getRowDatesFromPerDiemIds = async (ids: number[]) => {
    const req: { date: string; row_id: number }[] = [];
    for await (const id of ids) {
      try {
        const pd = PerDiem.create();
        pd.id = id;
        const pdr = await this.Get(pd);
        const obj = {
          date: pdr.dateStarted,
          row_id: id,
        };
        if (!req.includes(obj)) req.push(obj);
      } catch (err) {
        console.error(
          'Error in promise for get row dates from per diem IDs (Verify Per Diem exists): ',
          err
        );
      }
    }
    return req;
  };

  // Requesting review of function refactor
  loadPerDiemByDepartmentIdsAndDateStarted = async (
    departmentIds: number[],
    dateStarted: string
  ) => {
    const results = await Promise.all(
      departmentIds.map(async (departmentId) => {
        const req = PerDiem.create();
        req.departmentId = departmentId;
        req.withRows = true;
        req.isActive = true;
        req.pageNumber = 0;
        req.dateStarted = `${dateStarted}%`;
        return (await this.BatchGet(req)).results;
      })
    );
    return results
      .reduce((aggr, item) => [...aggr, ...item], [])
      .sort((a, b) => {
        if (getDepartmentName(a.department) < getDepartmentName(b.department))
          return -1;
        if (getDepartmentName(a.department) > getDepartmentName(b.department))
          return 1;
        return 0;
      });
  };

  loadPerDiemsForPayroll = async ({
    page,
    needsAuditing,
    needsProcessed,
    managerApproved,
    departmentId,
    userId,
    dateStarted,
    payrollToggle,
    orderBy,
    orderDir,
  }: {
    page: number;
    needsAuditing: boolean;
    needsProcessed: boolean;
    managerApproved?: boolean;
    departmentId?: number;
    userId?: number;
    dateStarted?: string;
    payrollToggle?: boolean;
    orderBy?: string;
    orderDir?: string;
  }) => {
    const req = PerDiem.create();
    req.withRows = true;
    req.pageNumber = page;
    req.isActive = true;
    req.notEquals = ['DateSubmitted'];
    req.dateSubmitted = NULL_TIME;
    if (departmentId) {
      req.departmentId = departmentId;
    }
    if (userId) {
      req.userId = userId;
    }

    if (orderBy && orderDir) {
      req.orderBy = orderBy;
      req.orderDir = orderDir;
    }

    if (dateStarted) {
      if (dateStarted != NULL_TIME && dateStarted != '') {
        //then we want the specific week
        req.dateStarted = `${dateStarted}%`;
      }
      //we want everything, due to the recent changes, a blank string, nor
      //undefined, we instead can just grab everything where the date started just is
      //greater than the smallest date.
      else {
        req.dateTarget = ['date_started'];
        req.dateRange = ['>', '0001-01-01'];
      }
    }
    if (managerApproved || needsProcessed || needsAuditing) {
      if (managerApproved && !payrollToggle) {
        //fetch unapproved perdiems for the department
        req.fieldMask = req.fieldMask.concat(['ApprovedById']);
        req.notEquals = req.notEquals.concat(['PayrollProcessed']);
        req.payrollProcessed = true;
      }
      if (managerApproved && payrollToggle) {
        //fetch already approved perdiems for the department
        req.approvedById = 0;
        req.notEquals = req.notEquals.concat(['ApproveById']);
      } else if (needsProcessed && !payrollToggle) {
        //fetch all peridems that are not currently processed by payroll
        req.notEquals = req.notEquals.concat(['ApprovedById']);
        req.fieldMask = req.fieldMask.concat(['PayrollProcessed']);
      } else if (needsProcessed && payrollToggle) {
        req.notEquals = req.notEquals.concat(['ApprovedById']);
        req.notEquals = req.notEquals.concat(['PayrollProcessed']);
      } else if (needsAuditing) {
        //fetch perdiems that have no been audited yet
        req.notEquals = req.notEquals.concat(['ApprovedById']);
        req.fieldMask = req.fieldMask.concat(['NeedsAuditing']);
        req.needsAuditing = true;
      }
    }

    return await this.BatchGet(req);
  };

  loadPerDiemsNeedsAuditing = async (
    page: number,
    needsAuditing: boolean,
    payrollProcessed: boolean,
    departmentId?: number,
    userId?: number,
    dateStarted?: string,
    approved?: boolean
  ) => {
    const req = PerDiem.create();
    req.fieldMask = [
      'NeedsAuditing',
      'PayrollProcessed',
      typeof approved === 'boolean' && !approved ? 'ApprovedById' : '',
    ];
    req.withRows = true;
    req.pageNumber = page;
    req.needsAuditing = needsAuditing;
    req.payrollProcessed = payrollProcessed;
    req.notEquals = ['DateSubmitted'];
    req.dateSubmitted = NULL_TIME;
    req.isActive = true;
    if (departmentId) {
      req.departmentId = departmentId;
    }
    if (userId) {
      req.userId = userId;
    }
    if (dateStarted) {
      req.dateStarted = `${dateStarted}%`;
    }
    if (typeof approved === 'boolean' && approved) {
      req.notEquals = ['ApprovedById'];
    }
    req.isActive = true;
    return await this.BatchGet(req);
  };

  loadPerDiemsReport = async (
    departmentIDs: number[],
    userIDs: number[],
    weeks: string[]
  ) => {
    const config: PerDiemReportConfig = {
      departmentIDs,
      userIDs,
      weeks,
    };
    return await this.getPerDiemReportData(config);
  };

  public upsertPerDiem = async (data: PerDiem) => {
    const id = data.id;
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    return await this[id != 0 ? 'Update' : 'Create'](data);
  };

  // Please review
  public loadGovPerDiemByZipCode = async (zipCode: number, year: number) => {
    const client = new ApiKeyClient(this.host);
    const req = ApiKey.create();
    req.textId = 'per_diem_key';
    const res = await client.Get(req);
    const endpoint = `${res!.apiEndpoint.replace(
      '{ZIP}',
      zipCode.toString()
    )}${year}?api_key=${res!.apiKey}`;
    const response = await (await fetch(endpoint)).json();
    if (response.rates.length === 0) return false;
    const {
      rates: [
        {
          rate: [
            {
              city,
              county,
              months: { month },
            },
          ],
          state,
        },
      ],
    } = response;
    return { state, city, county, month };
  };

  public loadGovPerDiemData = async ({ apiEndpoint, apiKey, zipCode, year, month }: {
    apiEndpoint: string,
    apiKey: string,
    zipCode: string,
    year: number,
    month: number
  }) => {
    try {
      const endpoint = `${apiEndpoint.replace(
        '{ZIP}',
        zipCode
      )}${year}?api_key=${apiKey}`;
      const {
        rates: [
          {
            rate: [{ meals, months }],
          },
        ],
      } = await (await fetch(endpoint)).json();
      return {
        [zipCode]: {
          meals: meals,
          lodging: months.month[month - 1].value,
        },
      };
    } catch (e) {
      return { [zipCode]: { meals: 0, lodging: 0 } };
    }
  };

  public encodeGovDataKey = (zipCode: string, year: number, month: number) => {
    return `${zipCode}-${year}-${month}`;
  }
  public decodeGovDataKey = (key: string) => {
    const [zipCode, year, month] = key.split('-');
    return { zipCode, year: +year, month: +month };
  }

  public loadGovPerDiemDataV2 = async ({ apiEndpoint, apiKey, zipCode, year, month }: {
    apiEndpoint: string,
    apiKey: string,
    zipCode: string,
    year: number,
    month: number
  }) => {
    const key = this.encodeGovDataKey(zipCode, year, month);
    try {
      const endpoint = `${apiEndpoint.replace(
        '{ZIP}',
        zipCode
      )}${year}?api_key=${apiKey}`;
      const {
        rates: [
          {
            rate: [{ meals, months }],
          },
        ],
      } = await (await fetch(endpoint)).json();
      return {
        [key]: {
          meals: meals,
          lodging: months.month[month - 1].value,
        },
      };
    } catch (e) {
      return { [key]: { meals: 0, lodging: 0 } };
    }
  };

  // Please review
  public loadPerDiemsLodging = async (perDiems: PerDiem[]) => {
    const zipCodesByYearMonth: {
      [key: number]: {
        [key: number]: string[];
      };
    } = {};
    perDiems.forEach((pd) =>
      pd.rows
        .filter((pd) => !pd.mealsOnly)
        .forEach((pd) => {
          const [y, m] = pd.dateString.split('-');
          const year = +y;
          const month = +m;
          if (!zipCodesByYearMonth[year]) {
            zipCodesByYearMonth[year] = {};
          }
          if (!zipCodesByYearMonth[year][month]) {
            zipCodesByYearMonth[year][month] = [];
          }
          if (!zipCodesByYearMonth[year][month].includes(pd.zipCode)) {
            zipCodesByYearMonth[year][month].push(pd.zipCode);
          }
        })
    );
    const zipCodesArr: {
      year: number;
      month: number;
      zipCodes: string[];
    }[] = [];
    Object.keys(zipCodesByYearMonth).forEach((year) =>
      Object.keys(zipCodesByYearMonth[+year]).forEach((month) => {
        zipCodesArr.push({
          year: +year,
          month: +month,
          zipCodes: zipCodesByYearMonth[+year][+month],
        });
      })
    );
    const govPerDiems = await Promise.all(
      zipCodesArr.map(async ({ year, month, zipCodes }) => ({
        year,
        month,
        data: await this.loadGovPerDiem(zipCodes, year, month),
      }))
    );
    const govPerDiemsByYearMonth: {
      [key: number]: {
        [key: number]: {
          [key: string]: {
            meals: number;
            lodging: number;
          };
        };
      };
    } = {};
    govPerDiems.forEach(({ year, month, data }) => {
      if (!govPerDiemsByYearMonth[year]) {
        govPerDiemsByYearMonth[year] = {};
      }
      govPerDiemsByYearMonth[year][month] = data;
    });
    return perDiems
      .reduce((aggr, pd) => [...aggr, ...pd.rows], [] as PerDiemRow[])
      .filter((pd) => !pd.mealsOnly)
      .reduce((aggr, pd) => {
        const [y, m] = pd.dateString.split('-');
        const year = +y;
        const month = +m;
        return {
          ...aggr,
          [pd.id]: govPerDiemsByYearMonth[year][month][pd.zipCode].lodging,
        };
      }, {});
  };

  public loadGovPerDiemV2 = async ({ zipCodes, year, month, apiEndpoint, apiKey }: {
    zipCodes: string[],
    year: number,
    month: number
    apiEndpoint: string,
    apiKey: string
  }) => {
    const result = await Promise.all(zipCodes.map(async zipCode => {
      const key = this.encodeGovDataKey(zipCode, year, month);
      const data = await this.loadGovPerDiemDataV2({ apiEndpoint, apiKey, zipCode, year, month });
      return data[key] ? { [key]: data[key] } : {};
    }));

    return result.reduce((acc, item) => ({ ...acc, ...item }), {});
  };

  public loadGovPerDiem = async (
    zipCodes: string[],
    year: number,
    month: number
  ) => {
    const client = new ApiKeyClient(this.host);
    const req = ApiKey.create();
    req.textId = 'per_diem_key';
    const res = await client.Get(req);
    const results = await Promise.all(
      unique(zipCodes).map((zipCode) =>
        this.loadGovPerDiemData({
          apiEndpoint: res!.apiEndpoint,
          apiKey: res!.apiKey,
          zipCode,
          year,
          month
        })
      )
    );
    return results.reduce((aggr, item) => ({ ...aggr, ...item }), {});
  };

  public tripAsObjectToTrip = (
    asObj: Trip,
    rowId?: number,
    userId?: number
  ): Trip => {
    const req = Trip.create();
    const originAddress: string = '';
    const destinationAddress: string = '';

    req.perDiemRowId = rowId ? rowId : asObj.perDiemRowId;
    req.userId = userId ? userId : asObj.userId;
    req.notes = asObj.notes;
    req.distanceInMiles = asObj.distanceInMiles;
    req.originAddress = originAddress;
    req.destinationAddress = destinationAddress;
    return req;
  };

  upsertTrip = async (data: Trip, rowId: number, userId: number) => {
    //let results: { distance: number; duration: number };

    try {
      //@ts-ignore
      const results: { distance: number; duration: number } =
        await this.MapClient.getTripDistance(
          data.originAddress,
          data.destinationAddress
        );
      data.distanceInMiles = results.distance;
      data.calculatedDurationInSeconds = results.duration;
    } catch (err) {
      console.error(
        `An error occurred while getting the trip distance: ${err}`
      );
    }
    data.userId = userId;
    data.perDiemRowId;

    try {
      return await this[data.id != 0 ? 'UpdateTrip' : 'CreateTrip'](data);
    } catch (err) {
      console.error('Error occurred trying to save trip: ' + err);
    }
    return undefined
  };

  getUpdateSubmitPerDiemRequest = (id: number) => {
    const req = PerDiem.create();
    req.id = id;
    req.dateSubmitted = timestamp();
    const fieldMask = ['DateSubmitted'];
    req.fieldMask = fieldMask;
    return req
  }

  submitPerDiemById = async (id: number) => {
    const req = this.getUpdateSubmitPerDiemRequest(id)
    return await this.Update(req);
  };

  getUpdateApprovePerDiemRequest = (id: number, approvedById: number) => {
    const req = PerDiem.create();
    req.id = id;
    req.dateApproved = timestamp();
    req.approvedById = approvedById;
    const fieldMask = ['DateApproved', 'ApprovedById'];
    req.fieldMask = fieldMask;
    return req
  }

  approvePerDiemById = async (id: number, approvedById: number) => {
    const req = this.getUpdateApprovePerDiemRequest(id, approvedById)
    return await this.Update(req);
  };

  /**
   * Returns a number representing the per diem row id of the current week (or the date provided if
   * one was provided), or undefined if there is an error.
   * @param date
   * @returns number | undefined
   */

  // Unsure on how to refactor
  getPerDiemRowIds = async (date?: Date) => {
    const dateToQuery = date != null ? date : new Date();
    let daysToGoBack = 0;
    // If the day is Monday - Friday, we don't have to worry about overflow stuff with the offset
    // (Since our days start on Saturday, not Sunday like JS, we have this weirdness)
    if (dateToQuery.getDay() <= 5) {
      daysToGoBack = dateToQuery.getDay() + 1;
    } else {
      daysToGoBack = dateToQuery.getDay() - 6;
    }
    let dateToQueryMonth = dateToQuery.getMonth() + 1;
    let dateToQueryYear = dateToQuery.getFullYear();
    let dateToQueryDay = dateToQuery.getDate() - daysToGoBack;
    // If the dateToQueryDay is less than 0, that means it occurred last year but this day
    // that is being checked is in the new year, so we act accordingly
    if (dateToQueryDay < 0) {
      dateToQueryMonth = 12;
      dateToQueryYear--;
      dateToQueryDay = 31 + dateToQueryDay;
    }

    // We find the last saturday that happened because that's the start of our weeks
    // and every Saturday per_diem_row is incremented
    const lastSaturday = `${dateToQueryYear}-${padWithZeroes(
      dateToQueryMonth
    )}-${padWithZeroes(dateToQueryDay)} 00:00:00`;

    let perDiemRes: PerDiemList = PerDiemList.create();
    try {
      const pd = PerDiem.create();
      pd.dateStarted = lastSaturday;
      perDiemRes = await this.BatchGet(pd);
    } catch (error) {
      console.error(
        'An error occurred while fetching the current Per-Diem period: ',
        error
      );
    }

    if (perDiemRes.totalCount == 0) {
      console.error('No per-diem was found for the given date.');
      return;
    }

    return perDiemRes;
  };

  upsertPerDiemRow = async (data: PerDiemRow) => {
    const id = data.id;
    if (id !== 0 && data.fieldMask.length === 0) {
      throw new Error(
        'Attempting to update entity without providing a field mask will result in a no op'
      );
    }
    return await this[id == 0 ? 'UpdateRow' : 'CreateRow'](data);
  };

  deletePerDiemRowById = async (id: number) => {
    await this.DeleteRow(id);
  };
}
/**
 *
 * @param num number which needs a zero in front if it is less than 10
 * @returns string of the number with 0 in front if it is less than 10, otherwise just
 * the number
 */
function padWithZeroes(num: number): string {
  return num < 10 ? '0' + num : `${num}`;
}
interface PerDiemReportConfig {
  weeks: string[];
  userIDs: number[];
  departmentIDs: number[];
}

type TripInfo = {
  id: number;
  distanceInMiles: number;
  originAddress: string;
  destinationAddress: string;
  perDiemRowId: number;
  fieldMaskList: string[];
  userId: number;
  notes: string;
  payrollProcessed: boolean;
  page: number;
  approved: boolean;
  distanceInDollars: string;
  weekOf: string;
  nameOfEmployee: string;
  departmentName: string;
};

export type { PerDiemReportConfig, TripInfo };
export { PerDiem, PerDiemList, PerDiemClient, PerDiemRow };