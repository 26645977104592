import { type Contract, type Event, type Invoice, type Property } from '@kalos/kalos-rpc';

export type State = {
  isLoaded: boolean;
  filteredProperties: Property[];
  propertiesSelected: Property[] | undefined;
  isValidating: boolean;
  invoiceData: Invoice;
  isSaving: boolean;
  error: string | undefined;
  fatalError: boolean; // Contract does not exist, etc.
  contractEvents: Event[]; // Corresponding event for the contract that must be kept in sync with updates
  eventPage: number; // Page for the current event to display
  initiatedSchema: string[];
  hasBeenChanged: boolean;
  isClosingWithoutSave: boolean; // warn the user that they haven't saved and data will be lost if they close
  isAddingNewPM: boolean;
  selectedProperty: Property;
  selectedEvent: Event;
  createdEvent: Event;
  isEditing: boolean;
  isDeleting: boolean;
  pendingSave: Contract;
  sendInvoiceToCustomer: boolean;
  loadingEvents: boolean;
  sendInvoice: boolean;
  pendingSend: boolean;
  save: boolean;
  savingEvent: boolean;
  contractErrors: ContractErrors;
};

export enum ACTIONS {
  SET_LOADED = 'setLoaded',
  SET_PROPERTIES_SELECTED = 'setPropertiesSelected',
  SET_FILTERED_PROPERTIES = 'setFilteredProperties',
  SET_VALIDATING = 'setValidating',
  SET_INVOICE_DATA = 'setInvoiceData',
  SET_SAVING = 'setSaving',
  SET_ERROR = 'setError',
  SET_FATAL_ERROR = 'setUnrecoverableError',
  SET_CONTRACT_EVENTS = 'setContractEvents',
  SET_EVENT_PAGE = 'setEventPage',
  SET_INITIATED_SCHEMA = 'setInitiatedSchema',
  SET_HAS_BEEN_CHANGED = 'setHasBeenChanged',
  SET_CLOSING_WITHOUT_SAVE = 'setClosingWithoutSave',
  SET_ADDING_NEW_PM = 'setAddingNewPM',
  SET_EDITING = 'setEditing',
  SET_DELETING = 'setDeleting',
  SET_CREATED_EVENT = 'setCreatedEvent',
  SET_PENDING_SAVE = 'setPendingSave',
  SET_LOADING_EVENTS = 'setLoadingEvents',
  SET_SEND_INVOICE = 'setSendInvoice',
  SET_SEND_INVOICE_TO_CUSTOMER = 'setSendInvoiceToCustomer',
  SET_PENDING_SEND = 'setPendingSend',
  SET_SAVE = 'setSave',
  SET_SAVING_EVENT = 'setSavingEvent',
  SET_CONTRACT_ERRORS = 'setContractErrors',
}

export enum FREQUENCIES {
  SEMIANNUAL = 'Semi-Annual',
  ANNUAL = 'Annual',
  MONTHLY = 'Monthly',
  BIMONTHLY = 'Bi-Monthly',
  QUARTERLY = 'Quarterly',
}

export interface ContractErrors {
  [key: string]: string;
}

export enum BILLING_OPTIONS {
  SITE = 'Site',
  GROUP = 'Group',
}

export type Action =
  | { type: ACTIONS.SET_LOADED; data: boolean }
  | {
      type: ACTIONS.SET_PROPERTIES_SELECTED;
      data: Property[] | undefined;
    }
  | {
      type: ACTIONS.SET_FILTERED_PROPERTIES;
      data: Property[];
    }
  | {
      type: ACTIONS.SET_VALIDATING;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_INVOICE_DATA;
      data: Invoice;
    }
  | {
      type: ACTIONS.SET_SAVING;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_ERROR;
      data: string | undefined;
    }
  | {
      type: ACTIONS.SET_FATAL_ERROR;
      data: true; // Should only be set to true, not set to false. Wouldn't be very "fatal" otherwise
    }
  | {
      type: ACTIONS.SET_CONTRACT_EVENTS;
      data: {
        contractEvents: Event[];
        eventTotal: number;
      };
    }
  | {
      type: ACTIONS.SET_EVENT_PAGE;
      data: number;
    }
  | {
      type: ACTIONS.SET_INITIATED_SCHEMA;
      data: string[];
    }
  | {
      type: ACTIONS.SET_HAS_BEEN_CHANGED;
      data: true; // We don't wanna set it back to false
    }
  | {
      type: ACTIONS.SET_CLOSING_WITHOUT_SAVE;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_ADDING_NEW_PM;
      data: {
        isAdding: boolean;
        selectedProperty: Property;
        selectedEvent: Event;
      };
    }
  | {
      type: ACTIONS.SET_EDITING;
      data: {
        isEditing: boolean;
        selectedEvent: Event;
      };
    }
  | {
      type: ACTIONS.SET_DELETING;
      data: {
        isDeleting: boolean;
        selectedEvent: Event;
      };
    }
  | {
      type: ACTIONS.SET_CREATED_EVENT;
      data: Event;
    }
  | {
      type: ACTIONS.SET_PENDING_SAVE;
      data: Contract;
    }
  | {
      type: ACTIONS.SET_LOADING_EVENTS;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_SEND_INVOICE;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_PENDING_SEND;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_SAVE;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_SEND_INVOICE_TO_CUSTOMER;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_SAVING_EVENT;
      data: boolean;
    }
  | {
      type: ACTIONS.SET_CONTRACT_ERRORS;
      data: ContractErrors;
    };

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADED: {
      return {
        ...state,
        isLoaded: action.data,
      };
    }
    case ACTIONS.SET_PROPERTIES_SELECTED: {
      return {
        ...state,
        propertiesSelected: action.data,
      };
    }
    case ACTIONS.SET_FILTERED_PROPERTIES: {
      return {
        ...state,
        filteredProperties: action.data,
      };
    }
    case ACTIONS.SET_VALIDATING: {
      return {
        ...state,
        isValidating: action.data,
      };
    }
    case ACTIONS.SET_INVOICE_DATA: {
      return {
        ...state,
        invoiceData: action.data,
      };
    }
    case ACTIONS.SET_SAVING: {
      return {
        ...state,
        isSaving: action.data,
      };
    }
    case ACTIONS.SET_ERROR: {
      return {
        ...state,
        error: action.data,
      };
    }
    case ACTIONS.SET_FATAL_ERROR: {
      return {
        ...state,
        fatalError: action.data,
      };
    }
    case ACTIONS.SET_CONTRACT_EVENTS: {
      return {
        ...state,
        contractEvents: action.data.contractEvents,
        contractEventTotal: action.data.eventTotal,
      };
    }
    case ACTIONS.SET_EVENT_PAGE: {
      return {
        ...state,
        eventPage: action.data,
      };
    }
    case ACTIONS.SET_INITIATED_SCHEMA: {
      return {
        ...state,
        initiatedSchema: action.data,
      };
    }
    case ACTIONS.SET_HAS_BEEN_CHANGED: {
      return {
        ...state,
        hasBeenChanged: action.data,
      };
    }
    case ACTIONS.SET_CLOSING_WITHOUT_SAVE: {
      console.log('set closing without save');
      return {
        ...state,
        isClosingWithoutSave: action.data,
      };
    }
    case ACTIONS.SET_ADDING_NEW_PM: {
      return {
        ...state,
        isAddingNewPM: action.data.isAdding,
        selectedProperty: action.data.selectedProperty,
        selectedEvent: action.data.selectedEvent,
      };
    }
    case ACTIONS.SET_EDITING: {
      return {
        ...state,
        isEditing: action.data.isEditing,
        selectedEvent: action.data.selectedEvent,
      };
    }
    case ACTIONS.SET_DELETING: {
      return {
        ...state,
        isDeleting: action.data.isDeleting,
        selectedEvent: action.data.selectedEvent,
      };
    }
    case ACTIONS.SET_CREATED_EVENT: {
      return {
        ...state,
        createdEvent: action.data,
      };
    }
    case ACTIONS.SET_PENDING_SAVE: {
      return {
        ...state,
        pendingSave: action.data,
      };
    }
    case ACTIONS.SET_LOADING_EVENTS: {
      return {
        ...state,
        loadingEvents: action.data,
      };
    }
    case ACTIONS.SET_SEND_INVOICE: {
      return {
        ...state,
        sendInvoice: action.data,
        save: true,
      };
    }
    case ACTIONS.SET_SEND_INVOICE_TO_CUSTOMER: {
      return {
        ...state,
        sendInvoiceToCustomer: action.data,
      };
    }
    case ACTIONS.SET_PENDING_SEND: {
      return {
        ...state,
        pendingSend: action.data,
      };
    }
    case ACTIONS.SET_SAVE: {
      return {
        ...state,
        save: action.data,
      };
    }
    case ACTIONS.SET_SAVING_EVENT: {
      return {
        ...state,
        savingEvent: action.data,
      };
    }
    case ACTIONS.SET_CONTRACT_ERRORS: {
      return {
        ...state,
        contractErrors: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
