import '../../../styles/spiffTool.css';

import { File, Reimbursement, ReimbursementDocument, type User } from '@kalos/kalos-rpc';
import {
  Button,
  DataTable,
  DataTableColumnHeader,
  DataTablePagination,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
  Form,
  toast,
} from '@kalos/ui';
import Alert from '@mui/material/Alert';
import { useQueryClient } from '@tanstack/react-query';
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { addDays, format } from 'date-fns';
import { debounce } from 'lodash';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';

import { getLoadingColumns } from '../../../components/utils';
import {
  FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME,
  NULL_TIME,
  OPTION_ALL,
  ROWS_PER_PAGE,
} from '../../../constants';
import { useAuth } from '../../../context/AuthContext';
import { queryKeys } from '../../../hooks/react-query/constants';
import {
  type ReimbursementFilter,
  useReimbursementCreateMutation,
  useReimbursementDeleteMutation,
  useReimbursementListQuery,
  useReimbursementUpdateMutation,
} from '../../../hooks/react-query/useReimbursementQuery';
import { useUserQuery } from '../../../hooks/react-query/useUserQuery';
import {
  FileClientService,
  formatDate,
  getMimeType,
  ReimbursementClientService,
  S3ClientService,
  usd,
} from '../../../tools/helpers';
import { Loader } from '../../Loader/main';
import { ReimbursementCreateForm } from './ReimbursementCreateForm';
import {
  getDefaultReimbursementSearchFormValues,
  ReimbursementSearchForm,
  type ReimbursementSearchFormSchema,
  useReimbursementSearchForm,
} from './ReimbursementSearchForm';
import { ReimbursementStatusLabel } from './ReimbursementStatusesLabel';
import { ReimbursementTableActions } from './ReimbursementTableActions';

export interface Props {
  filterProp?: Partial<ReimbursementSearchFormSchema>;
  reimbursementOwnerId: User['id'];
  onClose?: () => void;
}

const staticReimbursements = Array.from({ length: 3 }, (_, i) => Reimbursement.create());

const checkedDate = (date: string) => (date === NULL_TIME ? 'Not Completed' : formatDate(date));

const getReimbursementSearchFilter = ({
  page,
  search,
  ownerId,
}: {
  ownerId: User['id'];
  page: number;
  search: Partial<ReimbursementSearchFormSchema>;
}): ReimbursementFilter => {
  const { description, statusId, createdDate } = search;

  let dateRange: undefined | string[] = undefined;
  let dateTarget: undefined | string[] = undefined;

  if (createdDate && createdDate != OPTION_ALL && createdDate != '') {
    dateTarget = ['created_date', 'created_date'];
    dateRange = ['>=', createdDate, '<=', format(addDays(new Date(createdDate), 7), 'yyyy-MM-dd')];
  }

  return {
    description: description ? `%${description}%` : undefined,
    isActive: 1,
    orderDir: 'DESC',
    pageNumber: page,
    userId: ownerId,
    statusId: Number(statusId),
    dateRange,
    dateTarget,
  };
};

export const ReimbursementComponent: FC<Props> = ({
  onClose,
  filterProp = {},
  reimbursementOwnerId,
}) => {
  const loggedUserId = useAuth().user.id;

  const [page, setPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);

  const ownerUserQuery = useUserQuery({
    filters: {
      id: reimbursementOwnerId,
    },
  });

  const searchForm = useReimbursementSearchForm({
    defaultValues: useMemo(() => {
      return getDefaultReimbursementSearchFormValues({
        ...filterProp,
      });
    }, [filterProp]),
  });

  const [filter, setFilter] = useState<Partial<ReimbursementSearchFormSchema>>(
    searchForm.getValues(),
  );

  useEffect(() => {
    const debouncedUpdateSearch = debounce((data: Partial<ReimbursementSearchFormSchema>) => {
      setFilter((filter) => ({ ...filter, ...data }));
      setPage(0);
    }, FORM_AUTOMATIC_SEARCH_DEBOUNCE_TIME);

    const subscription = searchForm.watch(debouncedUpdateSearch);

    return () => subscription.unsubscribe();
  }, [searchForm]);

  const reimbursementFilter = useMemo<ReimbursementFilter>(() => {
    return getReimbursementSearchFilter({ ownerId: reimbursementOwnerId, page, search: filter });
  }, [filter, page, reimbursementOwnerId]);

  const loggedInUserQuery = useUserQuery({
    filters: {
      id: loggedUserId,
    },
    select(data) {
      const isManager = !!data.permissionGroups.find(
        (p) => p.name === 'Manager' && p.type === 'role',
      );
      const isPayroll = !!data.permissionGroups.find(
        (p) => p.name === 'Payroll' && p.type === 'role',
      );
      const hasPermission = isManager || isPayroll || loggedUserId == reimbursementOwnerId;
      return { ...data, hasPermission, isManager, isPayroll };
    },
  });

  const updateReimbursementMutation = useReimbursementUpdateMutation();
  const deleteReimbursementMutation = useReimbursementDeleteMutation();

  const hasAccessToViewData = loggedInUserQuery.data?.hasPermission;
  const reimbursementListQuery = useReimbursementListQuery({
    filter: reimbursementFilter,
    enabled: hasAccessToViewData,
  });

  useEffect(() => {
    if (reimbursementListQuery.isSuccess) {
      setTotalEntries(reimbursementListQuery.data?.totalCount || 0);
    }
  }, [reimbursementListQuery.data?.totalCount, reimbursementListQuery.isSuccess]);

  const tableColumns = useMemo<ColumnDef<Reimbursement>[]>(() => {
    return [
      {
        accessorKey: 'description',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Description" />;
        },
        meta: {
          className: 'w-48 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {props.row.original.description}
            </div>
          );
        },
      },
      {
        accessorKey: 'amount',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Amount" />;
        },
        meta: {
          className: 'w-24 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {usd(props.row.original.amount)}
            </div>
          );
        },
      },
      {
        accessorKey: 'createdDate',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Creation Date" />;
        },
        meta: {
          className: 'w-24 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {checkedDate(props.row.original.createdDate)}
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Status" />;
        },
        meta: {
          className: 'w-40 flex gap-1 flex-wrap ',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <ReimbursementStatusLabel id={props.row.original.statusId} />
            </div>
          );
        },
      },
      {
        accessorKey: 'reviewDate',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Admin Action Date" />;
        },
        meta: {
          className: 'w-24 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {checkedDate(props.row.original.reviewDate)}
            </div>
          );
        },
      },
      {
        accessorKey: 'processedDate',
        header(props) {
          return <DataTableColumnHeader column={props.column} title="Processed Date" />;
        },
        meta: {
          className: 'w-24 text-sm',
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              {checkedDate(props.row.original.processedDate)}
            </div>
          );
        },
      },
      {
        accessorKey: 'actions',
        meta: {
          className: 'w-36 flex gap-1 justify-center items-center flex-wrap text-sm',
        },
        header(props) {
          return null;
        },
        cell(props) {
          return (
            <div className={props.column.columnDef.meta?.className}>
              <ReimbursementTableActions
                reimbursement={props.row.original}
                isManager={loggedInUserQuery.data?.isManager ?? false}
                isPayroll={loggedInUserQuery.data?.isPayroll ?? false}
                isOwner={loggedUserId === props.row.original.userId}
              />
            </div>
          );
        },
      },
    ];
  }, [loggedInUserQuery.data?.isManager, loggedInUserQuery.data?.isPayroll, loggedUserId]);

  const loadingColumns = useMemo(() => getLoadingColumns(tableColumns), [tableColumns]);
  const loading =
    loggedInUserQuery.isPending ||
    ownerUserQuery.isPending ||
    reimbursementListQuery.isPending ||
    updateReimbursementMutation.isPending ||
    deleteReimbursementMutation.isPending;

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    data: loading ? staticReimbursements : reimbursementListQuery.data?.results ?? [],
    columns: loading ? loadingColumns : tableColumns,
    enableSorting: false,
  });

  const queryClient = useQueryClient();

  const createReimbursementMutation = useReimbursementCreateMutation();

  const [isSaving, setIsSaving] = useState(false);
  const handleCreateReimbursement = useCallback<
    React.ComponentProps<typeof ReimbursementCreateForm>['onSave']
  >(
    async ({ data }) => {
      try {
        const file = data.photo.at(0);
        if (!file) return;

        setIsSaving(true);

        const name = `${Math.floor(Date.now() / 1000)}-${file.name}`;

        const fileString = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string') resolve(result);
            reject();
          };
          reader.readAsDataURL(file);
        });
        //Upload to S3 reimbursement

        const uploadRes = await S3ClientService.uploadFileToS3Bucket(
          name,
          fileString,
          'kalos-reimbursements',
        );
        if (uploadRes === 'nok') throw new Error('Error uploading file');

        //If successful, create file record
        const fileRes = await FileClientService.Create(
          File.create({
            bucket: 'kalos-reimbursements',
            name: name,
            mimeType: getMimeType(name),
          }),
        );
        if (fileRes) {
          //if Successfull, create reimbursement record
          const createdReimbursement = await createReimbursementMutation.mutateAsync(
            Reimbursement.create({
              description: data.description,
              amount: data.amount,
              statusId: 2,
              userId: data.userId,
              creatorUserId: loggedUserId,
              jobNumber: data.jobNumber,
            }),
          );

          await ReimbursementClientService.CreateDocument(
            ReimbursementDocument.create({
              description: name,
              fileId: fileRes.id,
              reimbursementId: createdReimbursement.id,
            }),
          );
          queryClient.invalidateQueries({ queryKey: [queryKeys.reimbursement.root] });
          toast({
            variant: 'success',
            title: 'Reimbursement created successfully',
          });
          setIsCreateDialogOpen(false);
        }
      } catch (err) {
        toast({
          variant: 'destructive',
          title: 'Error during creation of reimbursement',
        });
      } finally {
        setIsSaving(false);
      }
    },
    [createReimbursementMutation, loggedUserId, queryClient],
  );

  const defaultCreateFormValues = useMemo<
    React.ComponentProps<typeof ReimbursementCreateForm>['defaultValues']
  >(
    () => ({
      description: 'Reimbursement Request For: ',
      amount: 0,
      jobNumber: 0,
      photo: [],
      userId: reimbursementOwnerId,
    }),
    [reimbursementOwnerId],
  );

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  if (loggedInUserQuery.isPending) return <Loader />;

  const pageCount = Math.ceil(totalEntries / ROWS_PER_PAGE);
  return loggedInUserQuery.isSuccess ? (
    <div className="flex flex-col gap-2 pt-6">
      <div className="flex flex-wrap justify-between gap-2 px-4">
        <div className="flex flex-wrap items-center gap-2">
          <h1 className="text-2xl font-medium">
            Reimbursables {ownerUserQuery.data?.firstname} {ownerUserQuery.data?.lastname}
          </h1>
          <DataTablePagination
            currentPage={page}
            setPage={setPage}
            pageCount={pageCount}
            className="max-w-max"
          />
        </div>
        <div className="flex gap-1">
          {onClose && <Button onClick={onClose}>Close</Button>}
          <Dialog onOpenChange={setIsCreateDialogOpen} open={isCreateDialogOpen}>
            <DialogTrigger asChild>
              <Button>Reimbursement Apply</Button>
            </DialogTrigger>

            <DialogContent className="@container max-h-svh max-w-2xl overflow-auto pb-2 md:h-auto md:max-h-[90vh]">
              <DialogTitle>Create Reimbursement</DialogTitle>
              <ReimbursementCreateForm
                onSave={handleCreateReimbursement}
                isLoading={isSaving}
                disabled={isSaving}
                disableTechnicianPicker
                defaultValues={defaultCreateFormValues}
              />
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <Form {...searchForm}>
        <div className="px-4">
          <ReimbursementSearchForm />
        </div>
      </Form>

      <div className="max-w-[100vw] overflow-auto">
        <DataTable table={table} />
      </div>
    </div>
  ) : (
    <Alert severity="error">You don&apos;t have permission to view this Log</Alert>
  );
};
